import { render, staticRenderFns } from "./RecordInfo.vue?vue&type=template&id=5c3587ac&scoped=true"
import script from "./RecordInfo.vue?vue&type=script&lang=js"
export * from "./RecordInfo.vue?vue&type=script&lang=js"
import style0 from "./RecordInfo.vue?vue&type=style&index=0&id=5c3587ac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3587ac",
  null
  
)

export default component.exports