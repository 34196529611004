<template>
  <div>
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
          <span v-if="index==levelList.length-1" class="no-redirect">{{ item.meta.title }}</span>
          <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
import pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: []
    }
  },
  watch: {
    $route(val) {
      if (val.meta) {
        let id = val.meta.id;
        id && (this.levelList = []);
        this.getBreadcrumb(id);
      }
    }
  },
  computed: {
    functionMap() {
      return this.$store.getters.userData.functionMap;
    }
  },
  created() {
    this.getBreadcrumb(this.$route.meta.id)
  },
  methods: {
    getBreadcrumb(id) {
      if (!id) {
        const { path, meta } = this.$route;
        this.levelList.splice(this.levelList.length - 1, 1, {
          path,
          meta,
        });
        return;
      }

      // only show routes with meta.title
      // let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      // this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)

      let current = this.functionMap[id];
      this.levelList.unshift(current);

      if (current && current.meta.parentId) {
        this.getBreadcrumb(current.meta.parentId)
      }
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      let path = '';
      if (item.meta && item.meta.redirect) {
        path = item.meta.redirect;
      }
      this.$router.replace(this.pathCompile(path))
    }
  }
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
