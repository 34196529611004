<template>
  <div class="merchant-info-container">
    <el-form
      label-width="170px"
      ref="form"
      :model="form"
      :disabled="disabled"
      :rules="rules"
    >
      <p class="subTitle">基本信息</p>
      <div
        class="clearfix"
      >
        <el-form-item label="合伙人编号：" class="inline-form-item">
          <el-input v-model="form.agentNo" disabled></el-input>
        </el-form-item>
        <el-form-item label="合伙人名称：" class="inline-form-item">
          <el-input v-model="form.agentName"></el-input>
        </el-form-item>
        <el-form-item label="负责销售：" class="inline-form-item">
          <el-select
            v-if="!onlyShow"
            v-model="form.salerId"
            clearable
            disabled
          >
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :value="item.id"
              :label="item.realName"
            ></el-option>
          </el-select>
          <el-tag type="info" v-else>
            {{ form.salerName }}
          </el-tag>
        </el-form-item>
        <el-form-item label="合伙人类型：" class="inline-form-item">
          <el-tag type="info">
            {{ $dictCode('AgentTypeEnum', form.agentType).desc }}
          </el-tag>
        </el-form-item>
        <el-form-item label="合伙人状态：" class="inline-form-item">
          <el-tag type="info">
            {{ $dictCode('AgentStatusEnum', form.agentStatus).desc }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="结算代扣规则："
          class="inline-form-item">
          <el-select
            clearable
            :disabled="disabled"
            v-model="form.selfDeclared"
          >
            <el-option
              v-for="item in $dict('SelfDeclaredEnum')"
              :key="item.code"
              :label="item.desc"
              :value="Number(item.code)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="代扣税比例："
          class="inline-form-item"
          prop="withholdingTaxRatio">
          <el-input
            clearable
            :disabled="disabled"
            :value="form.withholdingTaxRatio"
            @input="handleRateInput(form, 'withholdingTaxRatio', $event, 0, 100)"
          ></el-input>
        </el-form-item>
        <el-form-item label="邀请方：" class="inline-form-item">
          {{ form.inviterName }}
        </el-form-item>
        <el-form-item label="创建时间：" class="inline-form-item">
          {{ form.createTime }}
        </el-form-item>
        <el-form-item label="激活时间：" class="inline-form-item">
          {{ form.activeTime }}
        </el-form-item>
        <el-form-item label="负责人姓名：" class="inline-form-item">
          {{ form.contactName || form.agentName }}
        </el-form-item>
        <el-form-item label="负责人手机号码：" class="inline-form-item">
          {{ form.contactPhone }}
        </el-form-item>
        <el-form-item label="常用邮箱：" class="inline-form-item">
          {{ form.contactEmail }}
        </el-form-item>
      </div>
      <el-form-item label="补充说明：">
        <el-input type="textarea" v-model="form.remark"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      disabled
      :model="systemInfo"
      label-width="170px"
    >
      <p class="subTitle">系统信息</p>
      <div
        class="clearfix"
      >
        <el-form-item label="创建人：" class="inline-form-item">
          <el-input
            v-model="systemInfo.founder"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" class="inline-form-item">
          <el-input
            v-model="systemInfo.createDate"
          ></el-input>
        </el-form-item>
        <el-form-item label="成交状态：" class="inline-form-item">
          <el-tag type="info">
            {{ systemInfo.status == 0 ? '未成交' : systemInfo.status == 1 ? '成交' : ''}}
          </el-tag>
        </el-form-item>
        <el-form-item label="最后修改人：" class="inline-form-item">
          <el-input
            v-model="systemInfo.lastModifyOperator"
          ></el-input>
        </el-form-item>
        <el-form-item label="最后跟进时间：" class="inline-form-item">
          <el-input
            v-model="systemInfo.lastModifyDate"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'base-info',
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      systemInfo: {
        type: Object,
        default: () => ({})
      },
      staffList: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      onlyShow: {
        type: Boolean,
        default: false
      },
    },
    data() {
      const self = this;
      return {
        form: {}, // 组件内部表单备份
        rules: {
          withholdingTaxRatio: [{
            validator: (rule, value, cb) => {
              if (self.form.selfDeclared == 0) {
                cb()
              } else {
                if (value) {
                  cb()
                } else {
                  cb(new Error('请输入代扣税比例'))
                }
              }
            }, trigger: 'blur'
          }]
        }
      }
    },
    watch: {
      info: {
        handler(val) {
          for (let p in val) {
            this.$set(this.form, p, val[p])
          }
        }
      }
    },
    methods: {
      init() {
        for (let p in this.info) {
          this.$set(this.form, p, this.info[p])
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-tag {
    vertical-align: middle;
  }
</style>
