<template>
  <div class="merchant-info-container">
    <el-form
      ref="form"
      label-width="350px"
      class="block-form"
      :model="form"
      :disabled="disabled"
    >
      <div class="bankCard_info" v-if="form.bankAccount">
        <p class="subTitle">银行卡信息</p>
        <el-form-item
          label="银行卡号："
          prop="accountNo"
        >
          <el-input v-model="form.bankAccount.accountNo"></el-input>
        </el-form-item>
        <el-form-item
          label="联行号："
          prop="bankChannelNo"
        >
          <el-input
            v-model="form.bankAccount.bankChannelNo"
            prefix-icon="el-icon-search"
            @click.native="searchBankNumberInfo"
          >
          </el-input>
        </el-form-item>
        <el-form-item
            label="开户银行："
            prop="bankName"
          >
            <el-input
              v-model="form.bankAccount.bankName"
              disabled
            ></el-input>
          </el-form-item>
      </div>

      <!-- <div class="accounts_info" v-if="isMerchant">
        <p class="subTitle">账户信息</p>
        <div
          v-for="(item, index) in mainstayList"
          :key="index"
        >
          <el-form-item label="代征主体：">{{ item.mainstayName }} ({{item.mainstayNo}})</el-form-item>
          <el-form-item label="开通发放方式：" prop="accounts" ref="accounts">
            <template v-for="(i, key) in $dict('ChannelTypeEnum')">
              <el-checkbox
                v-model="channelSelect[item.mainstayNo][key]"
                :key="key"
                :true-label="1"
                :false-label="0"
                @change="onChannelTypeChange($event, item, i)"
              >
                {{ i.desc }}
              </el-checkbox>
            </template>
          </el-form-item>
          <el-form-item
            v-for="(account, num) in accounts[item.mainstayNo]"
            :key="num"
            :label="$dictCode('ChannelTypeEnum', account.channelType).desc + '通道'"
          >
            <el-select
              v-if="account"
              v-model="account['payChannelNo']"
              @change="handlePayChannelChange(account, $event)"
            >
              <el-option
                v-for="(i, key) in payChannel[account.channelType - 1]"
                :key="key"
                :value="i.payChannelNo"
                :label="i.payChannelName"
              ></el-option>
            </el-select>
            <p class="color-gray form-tip">请选择提供{{ $dictCode('ChannelTypeEnum', account.channelType).desc }}发放服务的第三方支付机构</p>
          </el-form-item>

        </div>
      </div> -->
    </el-form>

    <!--  表格弹窗  -->
    <bankNumberInfo
      ref="bankNumberInfo"
      @closeDialog="closeBankNumberInfoDialog"
    ></bankNumberInfo>

  </div>
</template>

<script>
  import BankNumberInfo from '@/components/BankNumberInfo';
  export default {
    components: {
      BankNumberInfo
    },
    props: {
      mchNo: {
        type: String,
        default: '',
      },
      info: {
        type: Object,
        default: () => ({})
      },
      accountQuoteInfo: {
        type: Array,
        default: () => ([])
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      channelList: {
        type: Array,
        default: () => [],
      },
      onlyShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      let self = this;
      return {
        form: {},
        channelSelect: {},
        rules: {
          accountNo: [
            { required: true, message: "请输入银行卡号", trigger: "blur" },
          ],
          bankChannelNo: [
            { required: true, message: "请输入联行号", trigger: "change" },
          ],
          bankName: [
            { required: true, message: "请输入开户银行", trigger: "blur" },
          ],
          accounts: [
            { required: true, message: '请选择开通的发放方式', trigger: 'blur'},
            { validator: (rule, val, cb) => {
                let result = true;
                for (let mch in self.form.accounts) {
                  let accountList = self.form.accounts[mch];
                  if (accountList.length == 0) {
                    result = false;
                  }
                  for (let i = 0; i < accountList.length; i++) {
                    if (!accountList[i].payChannelNo) {
                      result = false;
                      break;
                    }
                  }
                  if (!result) {
                    break;
                  }
                }
                if (!result) {
                  cb(new Error('请选择通道'))
                } else {
                  cb();
                }
              }, trigger: 'blur'}
          ]
        },
        bankChannel: [],
        alipayChannel: [],
        wxChannel: [],
        mainstayList: [],
        accounts: {}
      }
    },
    computed: {
      payChannel() {
        return [
          this.bankChannel,
          this.alipayChannel,
          this.wxChannel
        ]
      },
      isMerchant() {
        return this.mchNo.startsWith('M')
      },
    },
    watch: {
      accountQuoteInfo: {
        handler(val) {
          this.isInit = false;
          this.mainstayList = val;
          for (let i = 0; i < this.mainstayList.length; i++) {
            this.$set(this.channelSelect, this.mainstayList[i]['mainstayNo'], [0, 0])
          }
          this.isInit = true;
        },
        immediate: true,
      },
      info: {
        handler(val) {
          for (let p in val) {
            this.$set(this.form, p, val[p])
          }
          this.preHandle();
        }
      },
    },
    methods: {
      init() {
        for (let p in this.info) {
          this.$set(this.form, p, this.info[p])
        }
        this.preHandle()
      },
      preHandle() {
        if (!this.isInit) {
          return this.timer = setTimeout(this.preHandle, 100);
        }
        this.timer && clearTimeout(this.timer);
        this.getPayChannelList();
        this.form.employerAccountInfo && this.form.employerAccountInfo.forEach((item, index) => {
          let parsedVal = item;
          if (typeof item == 'string') {
            parsedVal = JSON.parse(item);
          }
          this.form.employerAccountInfo.splice(index, 1, parsedVal);
          this.$set(this.accounts, parsedVal.mainstayNo, []);

          let select = [0, 0];
          parsedVal.employerAccountInfo.forEach(i => {
            const accountInfo = {
              channelName: this.$dictCode('ChannelTypeEnum', i.channelType).desc,
              channelType: i.channelType,
              payChannelName: i.payChannelName,
              payChannelNo: i.payChannelNo,
            };
            this.handlePayChannelChange(accountInfo, accountInfo.payChannelNo);
            this.accounts[parsedVal.mainstayNo].push(accountInfo);
            select[i.channelType - 1] = 1;
          });
          this.$set(this.channelSelect, parsedVal.mainstayNo, select);

        });
      },
      closeBankNumberInfoDialog(item) {
        if (item) {
          this.$set(this.form.bankAccount, 'bankChannelNo', item.bankChannelNo)
          this.$set(this.form.bankAccount, 'bankName', item.bankName)
        }
      },
      searchBankNumberInfo() {
        this.$refs.bankNumberInfo.search();
        this.$refs.bankNumberInfo.isShow = true;
      },
      onChannelTypeChange(val, mch, type) {
        const channelType = Number(type.code);
        this.channelSelect[mch.mainstayNo].splice(channelType - 1, 1, val);
        if (!this.accounts[mch.mainstayNo]) {
          this.$set(this.accounts, mch.mainstayNo, []);
        }
        if (val == 1) {
          this.accounts[mch.mainstayNo].push({
            channelType,
            channelName: type.desc,
            payChannelName: '',
            payChannelNo: '',
          })
        } else {
          this.accounts[mch.mainstayNo] = this.accounts[mch.mainstayNo].filter(item => {
            return item.channelType != channelType
          })
        }
      },
      onPayChannelChange(val, account) {
        for (let i = 0; i < this.payChannel.length; i++) {
          if (this.payChannel[i].payChannelNo === val) {
            account.payChannelName = this.payChannel[i].payChannelName
            break;
          }
        }
      },
      getPayChannelList() {
        this.bankChannel = [];
        this.alipayChannel = [];
        this.channelList.forEach(item => {
          item.channelType && item.channelType.forEach(i => {
            if (i == 1) {
              this.bankChannel.push(item)
            } else if (i == 2) {
              this.alipayChannel.push(item)
            } else if (i == 3) {
              this.wxChannel.push(item)
            }
          })
        })
      },
      getFormData() {
        let form = JSON.parse(JSON.stringify(this.form));
        return {
          ...form,
          mchNo: this.mchNo,
          accounts: this.accounts
        }
      },
      handlePayChannelChange(account, val) {
        if (!val) {
          account.payChannelName = '';
        } else {
          let payChannel = this.payChannel[account.channelType - 1];
          for (let i = 0; i < payChannel.length; i++) {
            if (payChannel[i].payChannelNo == val) {
              account.payChannelName = payChannel[i].payChannelName;
            }
          }
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-form {
    width: 800px;
  }

</style>
