import router, { resetRouter } from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, removeToken } from '@/utils/loginToken' // get token from cookie
import request from '@/utils/request'
import { buildMenu, buildRoute, buildFuncMap } from "@/router"

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/retrievePwd'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // 登录信息与用户初始化信息（包含菜单，权限，路由，数据字典等）
  const token = getToken()
  let userData = store.getters.userData;
  if (token && to.path === '/login') {
    //如果是已经登录过,并且请求的是登录页面，则跳转到主页
    next({ path: '/' })

  } else if (token) {
    // 已登录且有用户数据
    if (userData) {
      next();
      return;
    } else {
      try {
        if ((userData = await getUserData(token))) {
          store.state.user.menus && initRightMenu(store.state.user.menus, to); // 初始化侧边栏子菜单
          next({ ...to, replace: true })
        }
      } catch (err) {
        console.log('error: >> ', err)
      }
      //如果获取数据失败
      if (!userData) {
        removeToken();
        resetRouter();
        next(false)
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      //不需要登录的地址（比如登录页面）
      next()
    } else {
      resetRouter();
      next(false)
      location.href = './login.html'
    }
  }

  NProgress.done();
})

router.afterEach(() => {
  NProgress.done()
})


async function getUserData(token) {
  let p1 = request.get('/user/info');
  let p2 = request.get('/dataDictionary/listAllDataDictionaryVO');
  const [{ data: { name, id, functions, type, realName } }, { data: dictionaryList }] = await Promise.all([p1, p2]);
  if (!dictionaryList || !functions) {
    //如果没有获取到数据字典或者用户功能列表,则不设置userData
    return;
  }
  // 通过权限构建用户菜单
  let menus = buildMenu(functions);
  // 通过菜单构建路由
  let routes = buildRoute(menus);
  // 通过路由构建功能映射表
  let map = buildFuncMap(routes[0].children);
  router.addRoutes(routes);
  let dictionary = dictionaryList.reduce((map, item) => {
    map[item.dataName] = item.dataInfo;
    return map;
  }, {});
  let permissionFlags = functions.map(f => f.permissionFlag);
  store.dispatch('GenerateRoutesAndMenus', { functions });
  return store.dispatch('setUserData', {
    name,
    realName,
    id,
    type,
    menus,
    routes,
    permissionFlags: new Set(permissionFlags),
    dictionary,
    functionMap: map
  })
}

function initRightMenu(menus, to) {
  if (store.state.user.rightMenu !== null || !menus.length) return;
  const currPath = to.path.split('/')[1];
  for (let i = 0; i < menus.length; i++) {
    const tempPath = menus[i].path.split('/')[1];
    if (tempPath === currPath) {
      store.dispatch('setRightMenu', menus[i]['children']);
      break;
    }
  }
}
