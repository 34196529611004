export default function bugfree() {
  console.log(` %c
                 ┌─┐       ┌─┐
              ┌──┘ ┴───────┘ ┴──┐
              │                 │
              │       ───       │
              │  ─┬┘       └┬─  │
              │                 │
              │       ─┴─       │
              │                 │
              └───┐         ┌───┘
                  │         │
                  │         │
                  │         │
                  │         └──────────────┐
                  │                        │
                  │                        ├─┐
                  │                        ┌─┘
                  │                        │
                  └─┐  ┐  ┌───────┬──┐  ┌──┘
                    │ ─┤ ─┤       │ ─┤ ─┤
                    └──┴──┘       └──┴──┘

             ~~~~~~~神兽保佑~~~~~~~永无bug~~~~~~~~~
    `, 'color: purple')
}
