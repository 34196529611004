<template>
  <right-pop
    :visible="visible"
    :with-header="false"
    :size="popSize"
    :append-to-body="true"
    @close="close"
  >
    <!-- 操作栏header -->
    <div class="merchant-pop-header text-right">
      <template v-if="isActive">
        <span class="color-gray" style="font-size: 14px;">如需完成更多操作，请点击此处最大化页面 <i class="el-icon-right el-animate"></i></span>
        <i class="el-icon-full-screen" @click="resize"></i>
      </template>
      <i class="el-icon-close" @click="close"></i>
    </div>

    <div class="merchant-info-box" v-loading="loading">
      <merchant-info-comp
        v-if="type == 1"
        :is-pop="true"
        :account-quote-info="accountQuoteInfo"
        :base-info="baseInfo"
        :cooperate-info="cooperateInfo"
        :main-info="mainInfo"
        :business-info="businessInfo"
        :account-info="accountInfo"
        :system-info="systemInfo"
        :agreement-info="agreementInfo"
        :quote-info="quoteInfo"
        :record-info="recordInfo"
        :history-info="historyInfo"
        :staffList="staffList"
        :workCategoryList="workCategoryList"
        :workCategoryOptions="workCategoryOptions"
        :industryList="industryList"
        :industryListOptions="industryListOptions"
        :show-arrow="false"
        :show-leader="false"
        :show-sale="false"
        :show-edit="false"
        :all-invoice-cate="allInvoiceCate"
        :load-history="getHandleList"
        @export-info="exportInfo"
        @get-export="getExportList"
        @edit-click="closeToEdit"
        @close-pop="close"
      >
        <template v-slot:btnMiddle>
          <el-button type="primary" v-if="baseInfo.mchStatus == 101" @click="onStatusChange" v-permission="'pms:merchantFlow:freeze'">解冻</el-button>
          <el-button type="danger" v-if="baseInfo.mchStatus == 100" @click="onStatusChange" v-permission="'pms:merchantFlow:freeze'">冻结</el-button>
        </template>
      </merchant-info-comp>
      <agent-info-comp
        v-if="type == 2"
        :is-pop="true"
        :base-info="baseInfo"
        :cooperate-info="cooperateInfo"
        :main-info="mainInfo"
        :bank-info="bankInfo"
        :record-info="recordInfo"
        :system-info="systemInfo"
        :staff-list="staffList"
        :show-arrow="false"
        :show-leader="false"
        :show-sale="false"
        :show-edit="false"
        @export-info="exportInfo"
        @get-export="getExportList"
      ></agent-info-comp>
    </div>

    <!--导出dialog-->
    <export-select
      ref="exportSelect"
      :dict="type == 1 ? 'ExportEmployerInfo' : 'agentListExport'"
      @confirm="submitExportForm"></export-select>
    <export-record ref="exportRecord"></export-record>

    <status-change-dialog
      v-if="type == 1 && baseInfo"
      :visible.sync="showStatusChange"
      :mchStatus="baseInfo.mchStatus"
      @change-status="changeStatus"
    ></status-change-dialog>


  </right-pop>
</template>

<script>
  import { exportMerchantInfo } from '@/api/merchant';
  import { exportAgent as exportAgentInfo } from '@/api/agent';
  import { changeStatus } from '@/api/flow';
  import { mapState } from 'vuex'
  import ExportSelect from '@/components/ExportSelect'
  import ExportRecord from '@/components/ExportRecord/index'
  import RightPop from '@/components/RightPop'
  import MerchantInfoComp from '@/components/MerchantInfoComp'
  import AgentInfoComp from '@/components/AgentInfoComp'
  import StatusChangeDialog from "@/views/merchant/merchantManagement/Component/StatusChangeDialog";

  export default {
    name: 'MerchantPop',
    props: {
      mchList: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      StatusChangeDialog,
      RightPop,
      MerchantInfoComp,
      AgentInfoComp,
      ExportRecord,
      ExportSelect,
    },
    data() {
      return {
        popSize: '70%',
        showStatusChange: false,
      }
    },
    computed: {
      ...mapState({
        loading: state => state.pop.loading,
        type: state => state.pop.type,
        baseInfo: state => state.pop.content.baseInfo,
        cooperateInfo: state => state.pop.content.cooperateInfo,
        mainInfo: state => state.pop.content.mainInfo,
        accountInfo: state => state.pop.content.accountInfo,
        businessInfo: state => state.pop.content.businessInfo,
        agreementInfo: state => state.pop.content.agreementInfo,
        systemInfo: state => state.pop.content.systemInfo,
        quoteInfo: state => state.pop.content.quoteInfo,
        recordInfo: state => state.pop.content.recordInfo,
        accountQuoteInfo: state => state.pop.content.mainstayList,
        bankInfo: state => state.pop.content.bankInfo,
        historyInfo: state => state.pop.content.historyInfo,
        staffList: state => state.pop.content.staffList,
        industryList: state => state.pop.content.industryList,
        industryListOptions: state => state.pop.content.industryListOptions,
        workCategoryOptions: state => state.pop.content.workCategoryOptions,
        workCategoryList: state => state.pop.content.workCategoryList,
        visible: state => state.pop.visible,
        allInvoiceCate: state => state.pop.allInvoiceCate,
      }),
      mchNo() {
        if (this.type == 1) {
          return this.baseInfo && this.baseInfo.mchNo
        } else {
          return ''
        }
      },
      agentNo() {
        if (this.type == 2) {
          return this.baseInfo && this.baseInfo.agentNo
        } else {
          return ''
        }
      },
      // showEdit() {
      //   return this.mchNo ? (this.baseInfo.authStatus == 100) : false;
      // },
      isActive() {
        if (this.type == 1) {
          if (!this.baseInfo) return false;
          if (this.baseInfo.authStatus == 103) {
            return false
          } else {
            return this.baseInfo.mchStatus == 100 || this.baseInfo.mchStatus == 102
          }
        } else if (this.type == 2) {
          return this.baseInfo && this.baseInfo.agentStatus == 100
        } else {
          return false
        }
      }
    },
    methods: {
      close() {
        this.$store.dispatch('togglePop', false);
      },
      closeToEdit() {
        let path = this.type == 1 ? `/merchant/merchantInfo/${this.mchNo}` : `/agent/agentInfo/${this.agentNo}`;
        this.close();
        this.$router.push({
          path,
          query: {
            actionType: 'EDIT'
          }
        })
      },
      resize() {
        // this.popSize = this.popSize == '70%' ? '100%' : '70%'
        this.closeToEdit()
      },
      exportInfo() {
        this.$refs.exportSelect.visible = true
      },
      async submitExportForm(form) {
        const paramMap = this.type == 1 ? {
            mchNo: this.mchNo
          } : {
          agentNo: this.agentNo
        }
        // 标准报表，传所有字段，自定义报表，将下标转换成数据
        let api = this.type == 1 ? exportMerchantInfo : exportAgentInfo;
        await api({
          ...form,
          paramMap
        });
        this.$message.success('导出成功，请到导出列表进行导出');
      },
      getExportList() {
        this.$refs.exportRecord.isShow = true;
        this.$refs.exportRecord.getExportRecord(this.type == 1 ? '1' : '39');
      },
      onStatusChange() {
        this.showStatusChange = true;
      },
      async changeStatus(remark) {
        const {mchNo, mchName, mchStatus, merchantType} = this.baseInfo;
        this.loading = true;
        await changeStatus({
          extObj: {
            merchantType,
            mchNo,
            mchName,
            handleType: mchStatus == 100 ? 0 : 1,
          },
          participant: {},
          condition: {
            referenceName: this.baseInfo && this.baseInfo.mchName,
            referenceNo: this.baseInfo && this.baseInfo.mchNo,
          },
          remark,
        }).finally(() => {
          this.loading = false;
        });
        this.$message.success('操作成功');
      },
      getHandleList(page) {
        this.$store.dispatch('getHistoryFlow', page)
      }
    },
  }
</script>

<style scoped lang="scss">
  .merchant-pop-header {
    margin: 8px;
    font-size: 16px;
  }
  .merchant-info-box {
    height: calc(100vh - 50px);
    overflow: auto;
  }

  @keyframes left-to-right {
    0% {
      transform: translateX(-4px);
    }
    100% {
      transform: translateX(4px);
    }
  }
  .el-animate {
    position: relative;
    padding: 0 3px;
    animation: left-to-right .7s linear infinite;
  }

</style>
