<template>
  <div class="merchant-info-container">
    <el-form
      ref="form"
      label-width="350px"
      :model="form"
      :rules="rules"
      :disabled="disabled"
      class="block-form"
    >
      <p class="subTitle">银行卡信息</p>

      <template v-if="agentType == 100">
        <el-form-item
          label="银行卡号："
          prop="accountNo">
          <el-input
            clearable
            v-model="form.accountNo"
            @blur="getBankCardBin">
            <el-button
              type="info"
              slot="append"
              @click="getBankCardBin">查询
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="开户银行：">
          <el-input
            disabled
            v-model="form.bankName"></el-input>
        </el-form-item>
        <el-form-item label="(备选)支付宝账户：">
          <el-input
            v-model="form.alipayAccount"
            :disabled="disabled"></el-input>
        </el-form-item>
      </template>

      <template v-if="agentType == 101">
        <el-form-item
          label="银行卡号："
          prop="accountNo">
          <el-input
            clearable
            v-model="form.accountNo">
          </el-input>
        </el-form-item>
        <el-form-item
          label="联行号："
          prop="bankChannelNo"
          ref="bankChannel"
        >
          <el-input
            v-model="form.bankChannelNo"
            prefix-icon="el-icon-search"
            @click.native="searchBankNumberInfo"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="开户银行：">
          <el-input
            disabled
            v-model="form.bankName"></el-input>
        </el-form-item>
      </template>
    </el-form>

    <!--  表格弹窗  -->
    <bankNumberInfo
      ref="bankNumberInfo"
      @closeDialog="closeBankNumberInfoDialog"
    ></bankNumberInfo>
  </div>
</template>

<script>
import BankNumberInfo from '@/components/BankNumberInfo';
import { getBankCardBin } from '@/api/common';

export default {
  name: 'bankInfo',
  components: {
    BankNumberInfo
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    previewHandle: Function,
  },
  data() {
    return {
      form: {
        "agentNo": "",
        "accountNo": "",
        "accountName": "",
        "bankName": "",
        "bankChannelNo": "",
      },
      rules: {
        accountNo: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ],
      },
    }
  },
  computed: {
    agentType() {
      return this.$attrs.agentType || 100
    }
  },
  watch: {
    info: {
      immediate: true,
      handler(val) {
        for (let p in val) {
          this.$set(this.form, p, val[p])
        }
      }
    },
  },
  methods: {
    searchBankNumberInfo() {
      this.$refs.bankNumberInfo.search();
      this.$refs.bankNumberInfo.isShow = true;
    },
    closeBankNumberInfoDialog(item) {
      if (item) {
        this.$set(this.form, 'bankChannelNo', item.bankChannelNo)
        this.$set(this.form, 'bankName', item.bankName)
      }
    },
    getFormData() {
      let formData = JSON.parse(JSON.stringify(this.form));
      return formData;
    },
    async getBankCardBin() {
      const { data } = await getBankCardBin({
        cardNo: this.form.accountNo
      });
      this.form.bankName = (data && data.bankName) || '';
    },
  },
}
</script>

<style
  scoped
  lang="scss">

</style>
