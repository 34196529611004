<template>
  <div class="merchant-info-container">
    <p class="subTitle">产品报价单</p>
    <div
      style="padding: 0 16px;"
      class="clearfix">
      <span
        class="float-l"
        style="line-height: 40px;">共 {{ form.length }} 条</span>
      <div class="float-r">
        <el-button
          v-if="!disabled"
          type="text"
          @click="goToQuote"
        >添加
        </el-button>
      </div>
    </div>

    <el-table
      :data="form"
      :span-method="spanMethod">
      <el-table-column
        label="产品名称"
        prop="productName">
      </el-table-column>
      <el-table-column
        label="邀请奖励计费方式"
        prop="calculateMode"
      >
        <template v-slot="{row}">
          {{ $dictCode('CalculateModeEnum', row.calculateMode).desc }}
        </template>
      </el-table-column>
      <el-table-column label="一级佣金">
        <el-table-column
          label="公式类型"
          prop="formulaType">
          <template v-slot="{row}">
            {{ $dictCode('FormulaEnum', row.formulaType).desc }}
          </template>
        </el-table-column>
        <el-table-column
          label="固定金额"
          prop="fixedFee">
        </el-table-column>
        <el-table-column
          label="费率"
          prop="feeRate">
        </el-table-column>
      </el-table-column>
      <el-table-column label="二级佣金">
        <el-table-column
          label="公式类型"
          prop="formulaType">
          <template v-slot="{row}">
            {{ $dictCode('FormulaEnum', row.secondFormulaType).desc }}
          </template>
        </el-table-column>
        <el-table-column
          label="固定金额"
          prop="secondFixedFee">
        </el-table-column>
        <el-table-column
          label="费率"
          prop="secondFeeRate">
        </el-table-column>
      </el-table-column>
      <el-table-column
        label="特殊计费规则"
      >
        <template v-slot="{row}">
          <template v-if="row.ruleType == 1">
            <div
              v-for="(item, index) in row.ruleParam"
              :key="index"
              style="color: 12px">
              <el-tooltip
                :content="getRuleParamContent(row, item)"
              >
                <span v-if="item.specialRuleType == 1">
                  供应商...
                </span>
                <span v-else>
                  {{ $dictCode('AgentSpecialRuleTypeEnum', item.specialRuleType).desc }}...
                </span>
              </el-tooltip>
            </div>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="分润比例">
        <template v-slot="{row}">
          {{ row.realProfitRatio }}%
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template v-slot="{row}">
          <el-tag :type="row.status == 100 ? '' : 'info'">
            {{ $dictCode('QuoteStatusEnum', row.status).desc }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        v-if="!disabled"
        prop="operate">
        <template v-slot="{row}">
          <el-button
            type="text"
            @click="editQuote(row)"
          >编辑
          </el-button>
          <el-button
            type="text"
            @click="delQuote(row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: [],
      editRow: {},
    }
  },
  computed: {
    agentType() {
      return this.$attrs.agentType || 100
    },
  },
  watch: {
    info: {
      immediate: true,
      handler(val) {
        this.preHandle()
      }
    }
  },
  methods: {
    goToQuote() {
      this.$emit('route-change', {
        path: '/quote/agentQuoteForm'
      })
    },
    editQuote(row) {
      this.$emit('route-change', {
        path: '/quote/agentQuoteForm',
        query: {
          actionType: 'EDIT',
        }
      }, {
        quoteInfo: [row],
      })
    },
    delQuote(row) {
      this.editRow = JSON.parse(JSON.stringify(row));
      this.$emit('del-quote');
    },
    getFormData() {
      const { agentNo, agentName, productNo, mainstayNo, ruleType } = this.editRow;
      return {
        agentNo,
        agentName,
        productNo,
        mainstayNo,
        ruleType,
        detail: this.editRow,
      }
    },
    getRuleParamContent(row, item) {
      return item.specialRuleType == 1 ? (row.mainstayName || '')
        : this.$dictCode('AgentSpecialRuleTypeEnum', item.specialRuleType).desc + this.$dictCode('CompareTypeEnum', item.compareType).desc + item.value
    },
    preHandle() {
      this.form = [];
      let lastIndex = 0;
      if (!this.info) return
      this.info.forEach((item, index) => {
        const copyItem = JSON.parse(JSON.stringify(item));
        item.quoteRateList = [];
        item.rowspan = 0;
        if (index == 0 || item.ruleType !== this.info[lastIndex].ruleType) {
          item.quoteRateList.push(copyItem);
          item.rowspan++;
          lastIndex = index;
        } else {
          // 供应商和产品作为分组依据
          if ((item.mainstayNo !== this.info[lastIndex].mainstayNo)
            || (item.productNo !== this.info[lastIndex].productNo)
          ) {
            lastIndex = index;
            item.rowspan++;
            item.quoteRateList.push(copyItem);
          } else {
            this.info[lastIndex].rowspan++;
            this.info[lastIndex].quoteRateList.push(copyItem);
          }
        }
      });
      this.form = this.info
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      let ret = {};
      if (columnIndex == 0 || column.property == 'operate') {
        ret = {
          rowspan: row.rowspan,
          colspan: 1,
        };
        return ret
      }
    }
  }
}
</script>

<style
  scoped
  lang="scss">

</style>
