<template>
  <div class="merchant-info-container">
    <el-form
      v-if="isMerchant"
      :disabled="disabled"
      :model="form"
      :rules="rules"
      label-width="400px"
      ref="form"
      class="block-form"
    >
      <p class="subTitle">
        岗位信息
        <el-button type="text" @click="addJob" v-if="!onlyShow">增加岗位</el-button>
      </p>
      <div v-if="form.position">
        <div class="job-box" v-for="(item, index) in form.position" :key="index">
          <el-divider v-if="index == 1"></el-divider>
          <el-form-item label="自由职业者的工作场所：" :prop="'position.' + index + '.workplaceCode'" :rules="rules.position['workplaceCode']">
            <el-select clearable v-model="item.workplaceCode" v-if="!onlyShow">
              <el-option v-for="item in $dict('WorkPlaceEnum')" :key="item.code" :value="item.code" :label="item.desc"></el-option>
            </el-select>
            <el-tag
              type="info"
              v-else>
              {{ $dictCode('WorkPlaceEnum', item.workplaceCode).desc }}
            </el-tag>
          </el-form-item>
          <el-form-item label="自由职业者服务类型：" :prop="'position[' + index + '].workCategoryCode'" :rules="rules.position['workCategoryCode']">
            <el-cascader
              v-if="!onlyShow"
              v-model="item.workCategoryCode"
              data-index="index"
              :options="workCategoryOptions"
              :props="{ expandTrigger: 'hover', 'emitPath': false, 'value': 'workCategoryCode', 'label': 'workCategoryName', 'leaf': 'workCategoryCode' }"
              @change="changeWorkCategory($event, index)">
              <div slot-scope="{data}">
                <el-tooltip :content="data.workCategoryName">
                  <span class="cascader-content-item">
                    {{ data.workCategoryName }}
                  </span>
                </el-tooltip>
              </div>
            </el-cascader>
            <el-tag type="info" v-else>{{ item.workCategoryName }}</el-tag>
          </el-form-item>
          <el-form-item label="发票类目：" :prop="'position[' + index + '].jsonEntity.invoiceCategoryList'" :rules="rules.position['invoiceCategoryList']">
            <template v-if="!onlyShow">
              <el-select
                class="text-hidden"
                v-model="item.jsonEntity.invoiceCategoryList"
                multiple
                clearable
                value-key="invoiceCategoryCode"
                filterable
              >
                <el-option
                  v-for="(cate) in item._invoiceCateList"
                  :key="cate.invoiceCategoryCode"
                  :label="cate.invoiceCategoryName"
                  :value="cate"
                ></el-option>
              </el-select>
              <span class="func-content" @click="changeCustomInvoice(item)">自定义选择</span>
            </template>
            <el-tag
              v-else
              type="info"
              v-for="(cate, key) in item.jsonEntity.invoiceCategoryList"
              :key="key"
            >
              {{ cate.invoiceCategoryName }}
            </el-tag>
            <p class="color-gray form-tip">平台根据贵司业务推荐开票类目，如您有其他选项需求请联系客户经理</p>
          </el-form-item>
          <el-form-item label="自由职业者服务描述：" :prop="'position[' + index + '].serviceDesc'" :rules="rules.position['serviceDesc']">
            <el-input
              type="textarea"
              v-model="item.serviceDesc"
              :autosize="{ minRows: 5, maxRows: 5}"
              placeholder="为使落地供应商匹配到合适的自由职业者，请按实际描述个人具体需要做什么"
            ></el-input>
          </el-form-item>
          <el-form-item label="自由职业者服务所得计算规则：" :prop="'position[' + index + '].chargeRuleDesc'" :rules="rules.position['chargeRuleDesc']">
            <el-input
              type="textarea"
              v-model="item.chargeRuleDesc"
              :autosize="{ minRows: 5, maxRows: 5}"
              placeholder="为吸引合适的自由职业者进行匹配，请按实际填写个人完成服务后钱怎么算；如规则的数据范围有差异，请列明确定不同规则的依据"></el-input>
          </el-form-item>
          <div class="job-divider">
            <el-divider v-if="index > 0"></el-divider>
            <el-button v-if="index > 0" type="text" @click="deleteJob(index)">删除</el-button>
          </div>
        </div>
      </div>

      <p class="subTitle">合作信息</p>
      <div class="cooperate-box">
        <el-form-item label="企业行业类别：" prop="industryTypeCode">
          <el-cascader
            v-model="form.industryTypeCode"
            :options="industryListOptions"
            :props="{ expandTrigger: 'hover', 'emitPath': false, 'value': 'industryTypeCode', 'label': 'industryTypeName'}"
            @change="selectIndustry"
          ></el-cascader>
          <div><p class="color-gray form-tip">请正确选择企业行业类别，若类目选择错误可能导致审核驳回</p></div>
        </el-form-item>
        <el-form-item label="预计用工人数：" prop="workerNum">
          <el-input v-model="form.workerNum"></el-input>
        </el-form-item>
        <el-form-item label="预期可实现C端签署率区间：" prop="signRateLevel">
          <el-select clearable v-model="form.signRateLevel">
            <el-option v-for="item in $dict('SignRateLevelEnum')" :key="item.code" :value="Number(item.code)" :label="item.desc"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自由职业者单人月经营所得9.7万以下百分比：" prop="workerMonthIncomeRate">
          <el-input @input="handleRateInput(form, 'workerMonthIncomeRate', $event, 0, 100)" :value="form.workerMonthIncomeRate"></el-input>
        </el-form-item>
        <el-form-item label="月流水预估：" prop="monthMoneySlip">
          <el-input v-model="form.monthMoneySlip">
            <template slot="append">万元</template>
          </el-input>
          <p class="color-gray form-tip">全月自由职业者经营所得预估</p>
        </el-form-item>
        <el-form-item label="是否可提供服务记录及经营所得计算明细：" prop="provideIncomeDetailType">
          <el-radio-group v-model="form.provideIncomeDetailType">
            <el-radio v-for="item in $dict('ProvideIncomeDetailTypeEnum')" :key="item.code" :label="Number(item.code)">{{ item.desc }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="公司网站：" prop="companyWebsite">
          <el-input v-model="form.companyWebsite"></el-input>
        </el-form-item>
        <el-form-item label="公司自有业务平台名称：" prop="bizPlatformName">
          <el-input v-model="form.bizPlatformName"></el-input>
          <p class="color-gray form-tip">如微信公众号名、小程序名、APP名等</p>
        </el-form-item>
        <el-form-item label="公司对外宣传资料：">
          <el-upload
            class="upload-demo"
            :action="baseUrl + '/file/upload'"
            :headers="uploadHeader"
            accept=".doc,.docx,.pdf,.ppt,.pptx,.txt,.wps,.bpm,.png,.jpg,.jpeg,.gif"
            :file-list="companyLeafletFileUrls"
            :before-upload="beforeFileUpload"
            :on-remove="handleRemove('companyLeafletFileUrls')"
            :on-success="handSuccess('companyLeafletFileUrls')"
          >
            <el-button type="primary" size="small">点击上传文件</el-button>
            <template v-slot:file="{ file }">
              <i class="el-icon-document" style="margin-right: 8px;"></i>
              <el-link target="_blank" :href="fileUrl + (file.response ? file.response.data : file.url)">
                {{ file.name }}
              </el-link>
              <i v-if="!disabled" class="el-icon-delete" style="margin-left: 100px" @click="handleFileRemove(file, 'companyLeafletFileUrls')"></i>
            </template>
            <div slot="tip" class="el-upload__tip">
              如产品服务宣传册，业务介绍PPT等<br>
              文件大小需小于10M，格式为word、pdf、ppt、txt、wps、bmp、png、jpeg、jpg或gif
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="补充信息：">
          <el-upload
            v-toggle:8="supplementFileUrls"
            class="upload-demo"
            :action="baseUrl + '/file/upload'"
            :headers="uploadHeader"
            list-type="picture-card"
            accept=".doc,.docx,.pdf,.ppt,.pptx,.txt,.wps,.bpm,.png,.jpg,.jpeg,.gif"
            :limit="8"
            :file-list="supplementFileUrls"
            :before-upload="beforeFileUpload"
            :on-remove="handleRemove('supplementFileUrls')"
            :on-success="handSuccess('supplementFileUrls')"
            :on-exceed="handleExceed"
            :on-preview="previewHandle"
          >
            <template v-slot:file="{file}">
              <div class="fileBg" v-if="!isImg(file.name)">
                <span class="file-name">{{ file.name || '补充信息文件' }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleFileRemove(file, 'supplementFileUrls')"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                   <span
                     class="el-upload-list__item-preview"
                     @click="previewHandle(file)"
                   >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                </span>
              </div>
            </template>

            <div slot="tip" class="el-upload__tip">
              其他可佐证业务场景真实性的资料、照片或截图，包括但不限于
              <span class="upload-tip__weight">公司自有业务平台（微信公众号关注数/小程序用户数/app下载量等）的截图、自由职业者用工场景照片等。</span>
              <br>
              必须为彩色图片（文档请截图后上传），最多8张，单张照片不超过6M。格式为word、pdf、ppt、txt、wps、bmp、png、jpeg、jpg或gif。
            </div>
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </div>
    </el-form>

    <el-form
      v-else
      :disabled="disabled"
      class="content-container"
      :model="form"
      label-width="400px"
      :rules="rules"
      ref="form">

      <div class="cooperation_info">
        <p class="subTitle">合作信息</p>
        <div>
          <el-form-item label="是否有委托代征协议：">
            <el-radio-group v-model="form.provideIncomeDetailType">
              <el-radio label="0">是</el-radio>
              <el-radio label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="委托代征协议：" v-show="form.provideIncomeDetailType == '0'">
            <el-upload
              v-toggle="entrustAgreementFileUrl"
              class="upload-demo"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".pdf,.bmp,.png,.jpeg,.jpg,.gif"
              :limit="1"
              :file-list="entrustAgreementFileUrl"
              :before-upload="beforeImgUpload"
              :on-preview="previewHandle"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'entrustAgreementFileUrl')"
              :on-remove="handleRemove('entrustAgreementFileUrl')">
              <div slot="file" slot-scope="{file}" class="fileBg" v-if="isPdf(file.name)">
                <span class="file-name">{{ file.name }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleFileRemove(file, 'entrustAgreementFileUrl')"
                    v-show="!disabled"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                  <span
                    class="el-upload-list__item-download"
                    @click="handlePreviewFile(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                </span>
              </div>
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">不超过2M，格式为pdf、bmp、png、jpeg、jpg或gif</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="B端协议模板：" prop="agreementTemplate2BFileUrl">
            <el-upload
              v-toggle="agreementTemplate2BFileUrl"
              class="upload-demo"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".doc,.docx"
              :limit="1"
              :file-list="agreementTemplate2BFileUrl"
              :before-upload="beforeWordUpload"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'agreementTemplate2BFileUrl')">
              <div slot="file" slot-scope="{file}" class="fileBg">
                <span class="file-name">{{ file.name }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleFileRemove(file, 'agreementTemplate2BFileUrl')"
                    v-show="!disabled"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                  <span
                    class="el-upload-list__item-download"
                    @click="handlePreviewFile(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                </span>
              </div>
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">支持扩展名：.doc .docx</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="C端协议模板：" prop="agreementTemplate2CFileUrl">
            <el-upload
              v-toggle="agreementTemplate2CFileUrl"
              class="upload-demo"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".doc,.docx"
              :limit="1"
              :file-list="agreementTemplate2CFileUrl"
              :before-upload="beforeWordUpload"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'agreementTemplate2CFileUrl')">
              <div slot="file" slot-scope="{file}" class="fileBg">
                <span class="file-name">{{ file.name }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-delete"
                    @click="handleFileRemove(file, 'agreementTemplate2CFileUrl')"
                    v-show="!disabled"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                  <span
                    class="el-upload-list__item-download"
                    @click="handlePreviewFile(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                </span>
              </div>
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">支持扩展名：.doc .docx</div>
            </el-upload>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { convert } from '@/utils'
  import { validateParams } from '@/utils/validate'

  export default {
    name: 'cooperateInfo',
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      onlyShow: { // 仅做展示，不可编辑
        type: Boolean,
        default: false,
      },
      previewHandle: Function,
      workCategoryList: {
        type: Array,
        default: () => []
      },
      workCategoryOptions: {
        type: Array,
        default: () => []
      },
      industryList: {
        type: Array,
        default: () => []
      },
      industryListOptions: {
        type: Array,
        default: () => []
      },
      allInvoiceCate: Array,
    },
    data() {
      return {
        form: {},
        rules: {
          position: {
            workplaceCode: [
              {required: true, message: '请选择自由职业者的工作场所', trigger: 'change',}
            ],
            workCategoryCode: [
              {required: true, message: '请选择自由职业者服务类型', trigger: 'change',}
            ],
            serviceDesc: [
              {required: true, message: '请输入自由职业者服务描述', trigger: 'blur',},
              {validator: validateParams({type: 'Length', max: '500', msg: '最多输入500个字符'}), trigger: 'blur'}
            ],
            chargeRuleDesc: [
              {required: true, message: '请输入自由职业者服务所得计算规则', trigger: 'blur',},
              {validator: validateParams({type: 'Length', max: '500', msg: '最多输入500个字符'}), trigger: 'blur'}
            ],
            invoiceCategoryList: [
              {required: true, message: '请选择发票类目', trigger: 'change'}
            ]
          },
          workerNum: [
            {required: true, message: '请输入预计用工人数', trigger: 'blur',},
            {validator: validateParams({type: 'Reg', pattern: /^\d{1,10}$/, msg: '预计用工人数最多可输入10个字'}), trigger: 'blur'}
          ],
          signRateLevel: [
            {required: true, message: '请选择预期可实现C端签署率区间', trigger: 'change',},
          ],
          workerMonthIncomeRate: [
            {required: true, message: '请输入自由职业者单人月经营所得9.7万以下百分比', trigger: 'blur',},
          ],
          monthMoneySlip: [
            {required: true, message: '请输入月流水预估', trigger: 'blur',},
            {validator: validateParams({type: 'Reg', pattern: /^\d{1,10}$/, msg: '月流水预估最多可输入10个字'}), trigger: 'blur'}
          ],
          provideIncomeDetailType: [
            {required: true, message: '请选择是否可提供服务记录及经营所得计算明细', trigger: 'change',},
          ],
          companyWebsite: [
            {validator: validateParams({type: 'Null', msg: '请输入正确的网址'}), trigger: 'blur'},
          ],
          bizPlatformName: [
            {validator: validateParams({type: 'Null', msg: '请输入中文'}), trigger: 'blur'},
          ],
          contactName: [
            {required: true, message: '请输入商户联系人姓名', trigger: 'blur'},
            {validator: validateParams({type: 'Chinese', msg: '请输入中文'}), trigger: 'blur'},
            {validator: validateParams({type: 'Length', max: '15', msg: '最多输入15个中文'}), trigger: 'blur'}
          ],
          contactPhone: [
            {required: true, message: '请输入商户联系人手机号', trigger: 'blur'},
            {validator: validateParams({type: 'Phone', msg: '请输入正确的手机号'}), trigger: 'blur'}
          ],
          agreementTemplate2BFileUrl: [{
            required: true, message: '请上传B端协议模板', trigger: 'change',
          }],
          agreementTemplate2CFileUrl: [{
            required: true, message: '请上传C端协议模板', trigger: 'change',
          }],
        },

        // 商户类型图片
        companyLeafletFileUrls: [],
        supplementFileUrls: [],
        // 供应商类型图片
        entrustAgreementFileUrl: [],
        agreementTemplate2BFileUrl: [],
        agreementTemplate2CFileUrl: [],
      }
    },
    computed: {
      isMerchant() {
        if (this.form.mchNo) {
          return this.form.mchNo.startsWith('M');
        }
        return false;
      },
      imgUrl() {
        let imgUrl = [];
        if (this.isMerchant) {
          imgUrl = ['companyLeafletFileUrls', 'supplementFileUrls']
        } else {
          imgUrl = ['entrustAgreementFileUrl', 'agreementTemplate2BFileUrl', 'agreementTemplate2CFileUrl']
        }
        return imgUrl
      }
    },
    watch: {
      info: {
        handler(val) {
          for (let p in val) {
            this.$set(this.form, p, val[p])
          }
          this.preHandle()
        }
      },
    },
    methods: {
      init() {
        for (let p in this.info) {
          this.$set(this.form, p, this.info[p])
        }
        this.preHandle()
      },
      changeWorkCategory(value, index, prehandle, init) {
        for (let item of this.workCategoryList) {
          if (item.workCategoryCode == value) {
            if (!init) {
              this.form.position[index].workCategoryName = item.workCategoryName;
              this.form.position[index].invoiceCategoryCode = item.invoiceCategoryCode;
              this.form.position[index].invoiceCategoryName = item.invoiceCategoryName;
              this.form.position[index].serviceDesc = item.workDesc;
              this.form.position[index].chargeRuleDesc = item.chargeRuleDesc;
            }
            const list = item.jsonEntity.invoiceCategoryList.map(cate => {
              return {
                invoiceCategoryCode: cate.invoiceCategoryCode,
                invoiceCategoryName: cate.invoiceCategoryName,
              }
            });
            if (!prehandle) {
              this.form.position[index].invoiceCategoryList = [];
            }
            if (prehandle) {
              this.$set(this.form.position[index], '_invoiceCateList', this.allInvoiceCate);
            } else {
              this.$set(this.form.position[index], '_invoiceCateList', list);
            }
            break;
          }
        }
      },
      preHandle() {
        if (!this.onlyShow && this.isMerchant) {
          this.form.position.forEach((item, index) => {
            this.changeWorkCategory(item.workCategoryCode, index, true, true);
          });
        }

        this.imgUrl.forEach((item) => {
          this[item] = [];
        });
        if (this.isMerchant) {
          if (this.form.companyLeafletFileUrls) {
            for (let item of this.form.companyLeafletFileUrls) {
              this.companyLeafletFileUrls.push({
                name: '文件',
                url: item
              })
            }
          }
          if (this.form.supplementFileUrls) {
            for (let item of this.form.supplementFileUrls) {
              let suffix = item.split('.')[1];
              this.supplementFileUrls.push({
                url: this.fileUrl + item,
                name: `补充信息文件.${suffix}`
              })
            }
          }
        } else {
          this.$set(this.form, 'provideIncomeDetailType', '1');
          if (this.form.entrustAgreementFileUrl) {
            if (this.isPdf(this.form.entrustAgreementFileUrl)) {
              this.entrustAgreementFileUrl.push({
                url: this.form.entrustAgreementFileUrl,
                name: '委托代征协议.pdf'
              })
            } else {
              this.entrustAgreementFileUrl.push({
                url: this.fileUrl + this.form.entrustAgreementFileUrl,
                name: '委托代征协议'
              })
            }
            this.form.provideIncomeDetailType = '0'
          }
          if (this.form.agreementTemplate2BFileUrl) {
            this.agreementTemplate2BFileUrl.push({
              url: this.form.agreementTemplate2BFileUrl,
              name: 'B端协议模板'
            })
          }
          if (this.form.agreementTemplate2CFileUrl) {
            this.agreementTemplate2CFileUrl.push({
              url: this.form.agreementTemplate2CFileUrl,
              name: 'C端协议模板'
            })
          }
        }
      },
      addJob() {
        this.form.position.push({
          workplaceCode: '',
          workCategoryCode: '',
          invoiceCategoryCode: '',
          serviceDesc: '',
          chargeRuleDesc: '',
          jsonEntity: {
            invoiceCategoryList: [],
          }
        })
      },
      deleteJob(index) {
        if (this.form.position.length == 1) {
          return this.$message.error("必须含有一个岗位信息")
        } else {
          this.form.position.splice(index, 1);
        }
      },
      selectIndustry(value, index) {
        for (let item of this.industryList) {
          if (item.industryTypeCode == value) {
            this.form.industryTypeName = item.industryTypeName;
          }
        }
      },
      beforeFileUpload(file) {
        return this.validateUploadFile({
          img: true,
          pdf: true,
          word: true,
          addition: ['ppt', 'txt', 'pptx'],
          size: 10
        })(file)
      },
      handleExceed() {
        this.$message.error('最多上传8张!')
      },
      handlePubFileExceed() {
        this.$message.error('最多上传1个文件!')
      },
      handSuccess(param) {
        return (response, file, fileList) => {
          this.form[param] = this[param] = fileList
        }
      },
      beforeImgUpload(file) {
        return this.validateUploadFile({
          pdf: true,
          img: true,
          size: 2,
        })(file)
      },
      beforeWordUpload(file) {
        return this.validateUploadFile({
          word: true,
          size: 6,
        })(file)
      },
      handleFileRemove(file, param) {
        this[param] = this[param].filter(item => {
          return item.uid !== file.uid;
        })
      },
      handleRemove(param) {
        return (file, fileList) => {
          this.form[param] = this[param] = fileList;
        }
      },
      handleSuccess(response, file, fileList, param) {
        this[param] = fileList;
        this.form[param] = fileList
      },
      getFormData() {
        let form = JSON.parse(JSON.stringify(this.form));
        this.imgUrl.forEach(param => {
          if (this.isMerchant) {
            form[param] = this[param].map(item => {
              return item.response ? item.response.data : item.url
            });
            form[param] && form[param].forEach((item, index) => {
              form[param][index] = item.replace(new RegExp(this.fileUrl, 'g'), '')
            })
          } else {
            form[param] = this[param][0]
              ? this[param][0].response
                ? this[param][0].response.data
                : this[param][0].url
              : '';
            form[param] = form[param].replace(new RegExp(this.fileUrl, 'g'), '')
          }
        });
        if (!this.isMerchant && form.provideIncomeDetailType == 1) {
          this.form.entrustAgreementFileUrl = '';
          delete form.entrustAgreementFileUrl;
        }
        if (this.isMerchant) {
          form.position.forEach(item => {
            item.invoiceCategoryList = item.jsonEntity.invoiceCategoryList;
            delete item.jsonEntity;
            delete item._invoiceCateList
          });
        }
        return form;
      },
      handlePreviewFile(file) {
        let url = file.response ? file.response.data : file.url;
        window.open(this.fileUrl + url)
      },
      changeCustomInvoice(item) {
        item._invoiceCateList = this.allInvoiceCate
      },
    },
  }
</script>

<style scoped lang="scss">
</style>
