/* 外层el-table使用时需要添加动态变化的key，否则会由于其 $el 没销毁，无法创建新的sortable 实例 */

import Sortable from 'sortablejs'

function buildSortVM(elm, data, handle) {
  elm.sortVM = new Sortable(elm,{
    animation: 150,
    handle,
    ghostClass: 'sortable-ghost',
    setData: function(dataTransfer) {
      // to avoid Firefox bug
      // Detail see : https://github.com/RubaXa/Sortable/issues/1012
      dataTransfer.setData('Text', '')
    },
    onEnd: evt => {
      const targetRow = data.splice(evt.oldIndex, 1)[0];
      data.splice(evt.newIndex, 0, targetRow);
    }
  })
}
export default {
  inserted(el, binding, vnode) {
    const { handle = '.el-icon-sort', data } = binding.value;
    let elm = vnode.elm.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
    buildSortVM(elm, data, handle)
  },
  componentUpdated(el, binding, vnode) {
    const { trigger, handle = '.el-icon-sort', data } = binding.value;
    let elm = vnode.elm.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
    if (trigger !== undefined && !trigger) {
      elm.sortVM = null;
    } else if (trigger === true) {
      buildSortVM(elm, data, handle)
    }
  },
  unbind(el, binding, vnode) {
  },
}