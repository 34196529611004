import request from '@/utils/request'

export function getHandleList(data) {
  return request({
    url: '/flow/handleList',
    method: 'post',
    data
  })
}

export function getSendList(data) {
  return request({
    url: '/flow/sendList',
    method: 'post',
    data
  })
}

export function getTodoList(data) {
  return request({
    url: '/flow/todoList',
    method: 'post',
    data
  })
}

export function getCarbonList(data) {
  return request({
    url: '/flow/carbonList',
    method: 'post',
    data
  })
}

// 操作历史
export function getDetail(params) {
  return request({
    url: '/flow/getDetail',
    method: 'get',
    params
  })
}

// 流程追踪图
export function getInstanceImage(params) {
  return request({
    url: '/flow/getInstanceImage',
    method: 'get',
    params
  })
}

// 流程审批提交
export function submitTask(data) {
  return request({
    url: '/flow/submitTask',
    method: 'post',
    data,
  })
}

// 流程信息
export function getFlowInfo(params) {
  return request({
    url: '/flow/getByCommonFlowId',
    method: 'get',
    params
  })
}

// 撤回流程
export function withDrawFlow(data) {
  return request({
    url: '/flow/withDraw',
    method: 'post',
    data
  })
}

// 修改业务参数
export function editBusinessData(data) {
  return request({
    url: '/flow/editBusinessVariable',
    method: 'post',
    data
  })
}

// 变更审批人
export function transferTask(data) {
  return request({
    url: '/flow/transferTask',
    method: 'post',
    data
  })
}

// 回复
export function reply(data) {
  return request({
    url: '/flow/reply',
    method: 'post',
    data
  })
}

// 更改商户负责人
export function changeLeader(data) {
  return request({
    url: '/merchantFlow/changePrincipal',
    method: 'post',
    data
  })
}

// 更改商户销售
export function changeSale(data) {
  return request({
    url: '/merchantFlow/changeSaler',
    method: 'post',
    data
  })
}

// 更改商户状态
export function changeStatus(data) {
  return request({
    url: '/merchantFlow/freeze',
    method: 'post',
    data,
  })
}

// 商户更改账户信息
export function changeAccount(data) {
  return request({
    url: '/merchantFlow/changeAccount',
    method: 'post',
    data
  })
}

// 流程修改信息
export function flowRecord(data) {
  return request({
    url: '/merchantFlow/record',
    method: 'post',
    data
  })
}

export function exportFlow({ type }, data) {
  return request({
    url: `/flow/exportWorkOrder/${type}`,
    method: 'post',
    data,
  })
}
export function exportFlowFile({ type }, data) {
  return request({
    url: `/flow/exportWorkOrderFile/${type}`,
    method: 'post',
    data
  })
}

export function deleteFlow(data) {
  return request({
    url: '/flow/deleteByCommonFlowId',
    method: 'post',
    data
  })
}
