var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-info-container"},[_c('p',{staticClass:"subTitle"},[_vm._v("协议")]),_c('el-table',{attrs:{"data":_vm.info}},[_c('el-table-column',{attrs:{"label":"协议编号","prop":"agreementNo","width":"120"}}),_c('el-table-column',{attrs:{"label":"协议名称","prop":"topic"}}),_c('el-table-column',{attrs:{"label":"签署方","prop":"signerName"}}),_c('el-table-column',{attrs:{"label":"发起日期","prop":"createTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return _c('p',{domProps:{"innerHTML":_vm._s(_vm.renderTime(row[column.property]))}})}}])}),_c('el-table-column',{attrs:{"label":"签署截止日期","prop":"deadline"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return _c('p',{domProps:{"innerHTML":_vm._s(_vm.renderTime(row[column.property]))}})}}])}),_c('el-table-column',{attrs:{"label":"签署完成日期","prop":"finishTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return _c('p',{domProps:{"innerHTML":_vm._s(_vm.renderTime(row[column.property]))}})}}])}),_c('el-table-column',{attrs:{"label":"协议到期日期","prop":"expireTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return _c('p',{domProps:{"innerHTML":_vm._s(_vm.renderTime(row[column.property]))}})}}])}),_c('el-table-column',{attrs:{"label":"协议状态","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$dictCode('AgreementStatusEnum', row.status).desc)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }