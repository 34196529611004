<template>
  <div class="merchant-info-container">
    <p class="subTitle">协议</p>
    <el-table
      :data="info"
    >
      <el-table-column
        label="协议编号"
        prop="agreementNo"
        width="120"
      ></el-table-column>
      <el-table-column
        label="协议名称"
        prop="topic"
      ></el-table-column>
      <el-table-column
        label="签署方"
        prop="signerName"
      >
      </el-table-column>
      <el-table-column
        label="发起日期"
        prop="createTime"
      >
        <p
          slot-scope="{row, column}"
          v-html="renderTime(row[column.property])"
        ></p>
      </el-table-column>
      <el-table-column
        label="签署截止日期"
        prop="deadline"
      >
        <p
          slot-scope="{row, column}"
          v-html="renderTime(row[column.property])"
        ></p>
      </el-table-column>
      <el-table-column
        label="签署完成日期"
        prop="finishTime"
      >
        <p
          slot-scope="{row, column}"
          v-html="renderTime(row[column.property])"
        ></p>
      </el-table-column>
      <el-table-column
        label="协议到期日期"
        prop="expireTime"
      >
        <p
          slot-scope="{row, column}"
          v-html="renderTime(row[column.property])"
        ></p>
      </el-table-column>
      <el-table-column
        label="协议状态"
        prop="status"
      >
        <template v-slot="{row}">
          {{ $dictCode('AgreementStatusEnum', row.status).desc }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'agreement-info',
  props: {
    info: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>

</style>
