import request from '@/utils/request.js';

export function getProvinceList(data) {
  return request({
    url: '/province/listAllProvince',
    method: 'get',
  })
}

export function getCityList(data) {
  return request({
    url: '/province/listCityByProvinceNo',
    method: 'get',
    params: data
  })
}

export function getTownList(data) {
  return request({
    url: '/province/listTownByCityNo',
    method: 'get',
    params: data
  })
}

export function getBankList(data) {
  return request({
    url: '/bankInfo/getBankInfo',
    method: 'get',
    params: data
  })
}

export function addBankInfo(data) {
  return request({
    url: '/bankInfo/add',
    method: 'post',
    data
  })
}

export function editBankIno(data) {
  return request({
    url: '/bankInfo/update',
    method: 'post',
    data
  })
}

export function delBankInfo(data) {
  return request({
    url: '/bankInfo/del',
    method: 'post',
    data
  })
}

export function getBankCardBin(params) {
  return request({
    url: '/bankInfo/getBankCardBin',
    method: 'get',
    params,
  })
}

export function getExportRecord(data) {
  return request({
    url: '/file/listExportRecord',
    method: 'get',
    params: data
  })
}

export function uploadFile(data) {
  return request({
    url: '/file/upload',
    method: 'post',
    data
  })
}

// 商户、供应商、合伙人系统信息
export function getSystemRecord(data) {
  return request({
    url: '/record/system',
    method: 'post',
    data
  })
}
