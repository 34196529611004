<template>
  <el-dialog
    :visible="visible"
    :before-close="close"
    append-to-body
    title="确认更改商户状态"
  >
    <p style="font-size: 16px;">是否确认{{ mchStatus == 100 ? '冻结' : '解冻' }}该商户？</p>
    <span>审批意见：</span>
    <el-input
      type="textarea"
      v-model="remark"
    ></el-input>
    <template v-slot:footer>
      <el-button type="primary" @click="changeStatus">确定</el-button>
      <el-button @click="close">取消</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      mchStatus: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        remark: '',
      }
    },
    methods: {
      close(done) {
        this.$emit('update:visible', false);
      },
      async changeStatus() {
        this.$emit('change-status', this.remark);
        this.close();
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
