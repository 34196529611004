import request from '@/utils/request.js';

// 获取申请记录
export function getInvoiceList(data) {
  return request({
    url: '/invoice/listPage',
    method: 'post',
    data,
  })
}

export function exportInvoiceList(data) {
  return request({
    url: '/invoice/exportInvoiceRecord',
    method: 'post',
    data
  })
}


// 更新发票状态
export function updateInvoiceStatus(data) {
  return request({
    url: '/invoice/updateStatus',
    method: 'post',
    data
  })
}

// 根据流水号获取发票申请记录详细信息
export function getByTrxNo(data) {
  return request({
    url: '/invoice/getByTrxNo',
    method: 'get',
    params: data
  })
}

// 获取所有发票类目
export function getAllInvoiceCategory() {
  return request({
    url: '/invoiceCategory/getAll',
    method: 'get',
  })
}

// 查询普通发票批次详情
export function listInvoiceDetail(data) {
  return request({
    url: '/invoice/invoiceRecordDetailPage',
    method: 'post',
    data
  })
}

// 普票更新状态
export function confirmInvoiceRecordDetail(data) {
  return request({
    url: '/invoice/confirmInvoiceRecordDetail',
    method: 'post',
    data,
  })
}


// 完税证明列表查询
export function getTaxCertList(data) {
  return request({
    url: '/taxCertificate/listPage',
    method: 'post',
    data
  })
}

// 删除完税证明
export function deleteCert(data) {
  return request({
    url: '/taxCertificate/delete',
    method: 'post',
    data
  })
}

// 上传完税证明
export function addTaxCert(data) {
  return request({
    url: '/taxCertificate/add',
    method: 'post',
    data
  })
}

//编辑完税证明
export function editTaxCert(data) {
  return request({
    url: '/taxCertificate/edit',
    method: 'post',
    data
  })
}

// 以下为代开票申请
// 获取交易时间段起始时间
export function getBeginDate(data) {
  return request({
    url: '/invoiceApply/getApplyTradeCompleteDayBegin',
    method: 'get',
    params: data,
  })
}

// 申请发票
export function applyInvoice(data) {
  return request({
    url: '/invoiceApply/applyInvoice',
    method: 'post',
    data,
  })
}

export function getInvoiceInfoWithMainstayNo(params) {
  return request({
    url: '/invoiceApply/getInvoiceInfoWithMainstayNo',
    method: 'get',
    params
  })
}

export function getIssueOrder(data) {
  return request({
    url: '/invoiceApply/listWaitIssueOrderPage',
    method: 'post',
    data,
  })
}

export function getMainstayListByProduct(params) {
  return request({
    url: '/invoiceApply/listOpenMainstayByEmployerNoAndProduct',
    method: 'get',
    params,
  })
}

// 查询待开票金额
export function getWaitIssueAmount(data) {
  return request({
    url: '/invoiceApply/getWaitIssueAmount',
    method: 'post',
    data
  })
}

export function applyItemInvoice(data) {
  return request({
    url: '/invoiceApply/applyItemInvoice',
    method: 'post',
    data,
  })
}

export function getOrderItemWaitIssueAmount(data) {
  return request({
    url: '/invoiceApply/getOrderItemWaitIssueAmount',
    method: 'post',
    data
  })
}


// 查询待开票批次订单
export function listWaitIssueOrderItemPage(data) {
  return request({
    url: '/invoiceApply/listWaitIssueOrderItemPage',
    method: 'post',
    data,
  })
}

export function sumWaitCkhInvoiceAmount(data) {
  return request({
    url: '/invoiceApply/sumWaitCkhInvoiceAmount',
    method: 'post',
    data
  })
}

// 完税证明批量下载
export function batchDownloadTaxCert(data) {
  return request({
    url: '/taxCertificate/batchDownLoad',
    method: 'post',
    data
  })
}

