<template>
  <div style="display: inline-block">
    <div v-if="obKey == 'authStatus'">
      {{ $dictCode('AuthStatusEnum', value).desc }}
    </div>
    <div v-else-if="obKey == 'mchStatus'">
      {{ $dictCode('MchStatusEnum', value).desc }}
    </div>
    <div
      v-else-if="isImage"
    >
      <el-image
       fit
       :src="imgUrl"
       :preview-src-list="[imgUrl]"
       style="width: 100px; height: 100px;"
      ></el-image>
    </div>
    <div v-else-if="obKey !== 'productQuote'">{{ value || alt }}</div>
  </div>
</template>

<script>
  export default {
    name: 'parse-value',
    props: {
      value: {
        type: [Number, String],
        default: ''
      },
      obKey: {
        type: String,
        default: '',
      },
      alt: {
        type: String,
        default: '',
      }
    },
    computed: {
      isImage() {
        return /(\.jpg|\.png|\.jpeg|\.bmp|\.gif)$/i.test(this.value);
      },
      imgUrl() {
        if (this.isImage) {
          return this.fileUrl + this.value;
        } else {
          return ''
        }
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
