<template>
  <div v-loading="loading">
    <!-- 商户名称等信息 -->
    <div style="background: #fff; padding: 16px; margin: 0 16px;">
      <div style="font-size: 20px">
        <el-tag type="info">
          {{ $dictCode('AgentTypeEnum', baseInfo.agentType).desc }}
        </el-tag>
        <span class="info-arrow" @click="switchMerchant(-1)" v-if="showArrow">
          <i class="el-icon-caret-left"></i>
        </span>
        <span class="base-name">{{ baseInfo.agentName }}</span>
        <span class="info-arrow" @click="switchMerchant(1)" v-if="showArrow">
          <i class="el-icon-caret-right"></i>
        </span>
      </div>
      <!-- 操作栏 -->
      <div style="margin-top: 16px;">
        <el-button
          v-if="showEdit"
          @click="editInfo"
        >
          编辑</el-button>
        <el-button
          v-if="showSale"
          @click="changeSale"
          v-permission="'agent:saler:edit'"
        >
          更换销售</el-button>
        <el-button
          v-if="showLeader"
          @click="changeLeader"
          v-permission="'agent:principal:edit'"
        >
          更换负责人</el-button>
        <slot name="btnMiddle"></slot>
        <el-button
          @click="onExport">
          导出</el-button>
        <el-button
          type="text"
          @click="getExportList">
          查看已导出列表</el-button>
      </div>
    </div>

    <!--tabs-->
    <div class="tabs-container">
      <el-tabs type="card" v-model="activeName" :before-leave="beforeLeaveTab">
        <el-tab-pane label="基本信息" name="baseInfo" v-if="canShowTab('merchantEmployer:base:view')">
          <base-info
            ref="baseInfo"
            :info="baseInfo"
            :preview-handle="onPreview"
            :staff-list="staffList"
            :system-info="systemInfo"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
          ></base-info>
        </el-tab-pane>
        <el-tab-pane label="产品报价单" name="cooperateInfo" v-if="canShowTab('merchantEmployer:coop:view')">
          <cooperate-info
            ref="cooperateInfo"
            :info="cooperateInfo"
            :preview-handle="onPreview"
            :disabled="isPop"
            :only-show="$attrs.onlyShow"
            :agentType="baseInfo.agentType"
            @route-change="onRouteChange"
            @del-quote="onDelQuote"
          ></cooperate-info>
        </el-tab-pane>
        <el-tab-pane label="主体信息" name="mainInfo" v-if="canShowTab('merchantEmployer:main:view', 'AGENT_MAININFO_EDIT')">
          <main-info
            ref="mainInfo"
            :info="mainInfo"
            :preview-handle="onPreview"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
            :agentType="baseInfo.agentType"
          ></main-info>
        </el-tab-pane>
        <el-tab-pane label="银行卡信息" name="bankInfo" v-if="canShowTab('agent:bankInfo:view')">
          <bank-info
            ref="bankInfo"
            :info="bankInfo"
            :preview-handle="onPreview"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
            :agentType="baseInfo.agentType"
          ></bank-info>
        </el-tab-pane>
        <el-tab-pane label="修改记录" name="recordInfo">
          <record-info
            ref="recordInfo"
            :info="recordInfo"
          ></record-info>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!--切换tab的弹出框-->
    <tab-change-dialog
      :visible.sync="visible"
      @confirm="onConfirmTabChange"
    ></tab-change-dialog>
  </div>
</template>

<script>
  import BaseInfo from './BaseInfo'
  import CooperateInfo from "./CooperateInfo";
  import MainInfo from './MainInfo'
  import TabChangeDialog from "./TabChangeDialog";
  import BankInfo from "./BankInfo";
  import RecordInfo from "./RecordInfo";

  export default {
    name: 'agent-info-comp',
    components: {
      RecordInfo,
      BankInfo,
      TabChangeDialog,
      CooperateInfo,
      BaseInfo,
      MainInfo,
    },
    props: {
      activeTab: {
        type: String,
        default: '',
      },
      disabled: { // 全盘禁用
        type: Boolean,
        default: false,
      },
      isPop: { // 是否为弹窗形式
        type: Boolean,
        default: false,
      },
      showEdit: {
        type: Boolean,
        default: true,
      },
      showSale: {
        type: Boolean,
        default: true,
      },
      showLeader: {
        type: Boolean,
        default: true,
      },
      showArrow: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      baseInfo: {
        type: Object,
        default: () => ({})
      },
      systemInfo: {
        type: Object,
        default: () => ({})
      },
      mainInfo: {
        type: Object,
        default: () => ({})
      },
      cooperateInfo: {
        type: Array,
        default: () => ([])
      },
      bankInfo: {
        type: Object,
        default: () => ({})
      },
      recordInfo: {
        type: Array,
        default: () => [],
      },
      staffList: {
        type: Array,
        default: () => []
      },
      delQuote: Function,
    },
    data() {
      return {
        activeName: this.activeTab || 'baseInfo',
        visible: false,
      }
    },
    computed: {
      formDisabled() {
        return this.disabled || this.isPop
      },
    },
    mounted() {
      if (this.isPop) {
        // hack 弹窗首次为渲染强制触发初始化
        this.init();
      }
    },
    methods: {
      init() {
        if (this.loading) {
          setTimeout(this.init, 100)
          return;
        }
        let infoList = this.$refs;
        for (let name in infoList) {
          if (infoList[name] && typeof infoList[name].init === 'function') {
            infoList[name].init();
          }
        }
      },
      canShowTab(permission, process = '') {
        let processId = this.$parent.processId;
        let processType = this.$parent.processType;
        if (!processId) {
          return this.hadPermission(permission)
        } else {
          return processType === process
        }
      },
      editInfo() {
        this.$emit('edit-click')
      },
      beforeLeaveTab(active, old) {
        if (this.formDisabled) { return true }
        this.nextActive = active; // 暂存点击的tab
        this.visible = true;
        return false
      },
      onExport() {
        this.$emit('export-info')
      },
      getExportList() {
        this.$emit('get-export')
      },
      onPreview(file) {
        this.$preview(file.response ? (this.fileUrl + file.response.data) : file.url)
      },
      switchMerchant(step) {
        this.$emit('switch-mch', step)
      },
      changeSale() {
        this.$emit('sale-change')
      },
      changeLeader() {
        this.$emit('leader-change')
      },
      changeTab(active) {
        this.activeName = active;
      },
      async onConfirmTabChange() {
        const form = this.$refs[this.activeName].$refs.form;
        if (form) {
          const valid = await form.validate().catch(e => e);
          if (!valid) return this.$message.error('请补充必填信息');
        }
        this.$emit('tab-change', {
          old: this.activeName,
          active: this.nextActive
        });
      },
      onRouteChange({ path, query = {} }, info = {}) {
        const keys = Object.keys(info);
        keys.forEach(key => {
          sessionStorage.setItem(key, JSON.stringify(info[key]));
        });
        if (path === '/quote/agentQuoteForm') {
          query = {
            ...query,
            agentNo: this.baseInfo.agentNo,
            agentName: this.baseInfo.agentName,
          }
        }
        this.$router.push({
          path,
          query
        })
      },
      onDelQuote() {
        if (this.delQuote && this.delQuote instanceof Function) {
          this.delQuote()
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .merchant-arrow {
    cursor: pointer;
  }

  .tabs-container {
    background: #F0F2F5;
    padding: 16px;
    .el-tabs {
      background: #fff;
      ::v-deep {
        .inline-form-item {
          float: left;
          width: 50%;
          height: 40px;
          .el-input {
            width: 90%;
          }
        }
        .block-form {
          .el-input {
            width: 300px;
          }
        }
        .el-textarea {
          width: 300px;
        }
      }
    }
  }
</style>
