<template>
  <el-dialog
    title="确认修改"
    :visible="visible"
    :before-close="close"
    append-to-body
  >
    <p>当前表单处于编辑状态，是否保存当前数据？</p>

    <template v-slot:footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'tab-change-dialog',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      confirm() {
        this.$emit('confirm');
        this.close();
      },
      close() {
        this.$emit('update:visible', false)
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
