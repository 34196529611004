<template>
  <div class="merchant-info-container">
    <el-form
      ref="form"
      label-width="350px"
      :model="form"
      :rules="rules"
      :disabled="disabled"
      class="block-form"
    >
      <!--企业信息-->
      <div class="company_info">
        <p class="subTitle">企业信息</p>
        <!--企业证件-->
        <el-form-item
          label="营业执照"
          prop="businessLicenseFileUrl"
        >
          <el-upload
            key="businessLicenseFileUrl"
            v-toggle="businessLicenseFileUrl"
            class="upload-demo"
            data-type="businessLicenseFileUrl"
            :action="baseUrl + '/file/upload'"
            :headers="uploadHeader"
            list-type="picture-card"
            accept=".bmp,.png,.gif,.jpg,.jpeg,.gif,.pdf"
            :limit="1"
            :file-list="businessLicenseFileUrl"
            :before-upload="validateUploadFile({img: true, pdf: true, size: 6})"
            :on-remove="(file, fileList) => handleRemove(file, fileList, 'businessLicenseFileUrl')"
            :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'businessLicenseFileUrl')"
            :on-preview="previewHandle"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <template v-slot:file="{file}">
              <div class="fileBg" v-if="isPdf(file.name)">
                <span class="file-name">{{ file.name }}</span>
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleFileRemove(file, 'businessLicenseFileUrl')"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                  <span class="el-upload-list__item-download">
                    <i class="el-icon-download" @click="openFile(file)"></i>
                  </span>
                </span>
              </div>
            </template>
            <div
              slot="tip"
              class="el-upload__tip"
            >请上传彩色原件或加盖公司公章的复印件，小于6M，文件格式为pdf、bmp、png、jpeg、jpg或gif。
              <el-popover
                trigger="click"
                width="300">
                <el-button type="text" slot="reference">
                  查看示例
                </el-button>
                <div>
                  <img
                    class="example-image"
                    src="@/assets/example-1.png"
                  >
                </div>
              </el-popover>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="统一社会信用代码："
          prop="taxNo"
        >
          <el-input v-model="form.taxNo"></el-input>
          <p class="color-gray form-tip">请输入营业执照上18位统一社会信用代码</p>
        </el-form-item>
        <el-form-item
          label="注册地址："
          prop="registerAddrDetail"
        >
          <address-select
            :province.sync="form.registerAddrProvince"
            :city.sync="form.registerAddrCity"
            :town.sync="form.registerAddrTown"
            :default-address="defaultAddress"
          ></address-select>
          <el-input style="margin-top: 8px;" v-model="form.registerAddrDetail"></el-input>
        </el-form-item>
        <el-form-item
          label="经营范围："
          prop="managementScope"
        >
          <el-input
            type="textarea"
            :rows="4"
            v-model="form.managementScope">
          </el-input>
          <p class="color-gray form-tip">
            与企业工商营业执照上一致
          </p>
        </el-form-item>
        <el-form-item
          label="注册资本（万）："
          prop="registerAmount"
        >
          <el-input v-model="form.registerAmount"></el-input>
        </el-form-item>
        <el-form-item
          label="营业期限："
          prop="managementValidityDateType"
        >
          <el-radio-group v-model="form.managementValidityDateType">
            <el-radio
              v-for="item in $dict('ValidityDateTypeEnum')"
              :key="item.code"
              :label="Number(item.code)"
            >{{ item.desc }}
            </el-radio>
          </el-radio-group>
          <div>
            <date-picker
              v-if="$dictCode('ValidityDateTypeEnum',form.managementValidityDateType).desc == '区间有效'"
              type="date"
              picker="separate"
              :is-show-fast-time="false"
              :use-option="false"
              :start-time.sync="form['managementTermBegin']"
              :end-time.sync="form['managementTermEnd']"
            ></date-picker>
          </div>

          <div
            v-if="$dictCode('ValidityDateTypeEnum',form.managementValidityDateType).desc == '长期有效'"
          >
            <el-date-picker
              type="date"
              v-model="form.managementTermBegin"
              placeholder="选择起始时间"
              :value-format="'yyyy-MM-dd'"
            ></el-date-picker>
          </div>
        </el-form-item>

        <p class="subTitle">法定代表人信息</p>
        <el-form-item
          label="证件类型："
          prop="certificateType"
        >
          <el-select
            clearable
            v-model="form.certificateType"
          >
            <el-option
              v-for="item in $dict('CertificateTypeEnum')"
              :key="item.code"
              :value="Number(item.code)"
              :label="item.desc"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="证件照："
          prop="idFileType"
        >
          <el-radio-group v-model="form.idFileType">
            <el-radio label="100">彩色原件</el-radio>
            <el-radio label="101">复印件</el-radio>
          </el-radio-group>
          <div v-show="form.idFileType == '100'">
            <p>
              人像面
              <el-popover
                width="300"
                trigger="click">
                <img
                  class="example-image"
                  src="@/assets/example-2.png"
                >
                <el-button slot="reference" type="text">查看示例</el-button>
              </el-popover>
            </p>
            <el-upload
              v-toggle="idCardHeadFileUrl"
              key="idCardHeadFileUrl"
              class="upload-demo"
              data-type="idCardHeadFileUrl"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".png,.gif,.jpg,.bmp"
              :limit="1"
              :file-list="idCardHeadFileUrl"
              :before-upload="beforeAvatarUpload"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'idCardHeadFileUrl')"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'idCardHeadFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p>
              国徽面
              <el-popover
                width="300"
                trigger="click">
                <img
                  class="example-image"
                  src="@/assets/example-3.png"
                >
                <el-button slot="reference" type="text">查看示例</el-button>
              </el-popover>
            </p>
            <el-upload
              v-toggle="idCardEmblemFileUrl"
              key="idCardEmblemFileUrl"
              class="upload-demo"
              data-type="idCardEmblemFileUrl"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".png,.gif,.jpg,.bmp"
              :limit="1"
              :file-list="idCardEmblemFileUrl"
              :before-upload="beforeAvatarUpload"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'idCardEmblemFileUrl')"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'idCardEmblemFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div v-show="form.idFileType == '101'">
            <p>加盖公司公章的法人身份证复印件
              <el-popover
                width="300"
                trigger="click">
                <img
                  class="example-image"
                  src="@/assets/idCardCopyFileExample.png"
                >
                <el-button slot="reference" type="text">查看示例</el-button>
              </el-popover>
            </p>
            <el-upload
              v-toggle="idCardCopyFileUrl"
              key="idCardCopyFileUrl"
              class="upload-demo"
              data-type="idCardCopyFileUrl"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".png,.gif,.jpg,bmp"
              :limit="1"
              :file-list="idCardCopyFileUrl"
              :before-upload="beforeAvatarUpload"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'idCardCopyFileUrl')"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'idCardCopyFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item
          label="法定代表人姓名："
          prop="legalPersonName"
        >
          <el-input v-model="form.legalPersonName"></el-input>
        </el-form-item>
        <el-form-item
          label="证件号码："
          prop="certificateNumber"
          :rules="certificateRules"
        >
          <el-input v-model="form.certificateNumber"></el-input>
        </el-form-item>
        <el-form-item
          label="证件有效期："
          prop="certificateValidityDateType"
        >
          <el-radio-group v-model="form.certificateValidityDateType">
            <el-radio
              v-for="item in $dict('ValidityDateTypeEnum')"
              :key="item.code"
              :label="Number(item.code)"
            >{{ item.desc }}
            </el-radio>
          </el-radio-group>
          <div>
            <date-picker
              v-if="$dictCode('ValidityDateTypeEnum',form.certificateValidityDateType).desc == '区间有效'"
              style="display: block;"
              type="date"
              picker="separate"
              :is-show-fast-time="false"
              :timeRange="certificateTimeRange"
              :use-option="false"
              :start-time.sync="form.certificateTermBegin"
              :end-time.sync="form.certificateTermEnd"
            ></date-picker>
          </div>

          <div v-if="$dictCode('ValidityDateTypeEnum',form.certificateValidityDateType).desc == '长期有效'">
            <el-date-picker
              v-model="form.certificateTermBegin"
              type="date"
              placeholder="请选择起始时间"
              :value-format="'yyyy-MM-dd'"
            ></el-date-picker>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { validateParams } from '@/utils/validate';

  export default {
    name: 'mainInfo',
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      previewHandle: Function,
    },
    watch: {
      info: {
        handler(val) {
          for (let p in val) {
            this.$set(this.form, p, val[p])
          }
          this.preHandle()
        }
      }
    },
    data() {
      const _self = this;
      return {
        imgUrl: ['businessLicenseFileUrl', 'idCardHeadFileUrl', 'idCardEmblemFileUrl', 'idCardCopyFileUrl'],
        form: {},
        rules: {
          taxNo: [
            {required: true, message: '请输入统一社会信用代码或纳税人识别号'},
            {validator: validateParams({type: 'Reg', pattern: /^[a-zA-Z0-9]{1,20}$/, msg: '统一社会信用代码最多可输入20个字'}), trigger: 'blur'}
          ],
          registerAddrDetail: [
            {required: true, message: '请选择注册地址', trigger: 'blur'},
          ],
          registerAmount: [
            {required: true, message: "请输入注册资本", trigger: "blur"},
            {validator: validateParams({type: 'Number', msg: '请输入正确的金额'}), trigger: 'blur'},
            {validator: validateParams({type: 'Length', max: '20', msg: '注册资本最多可输入20个字'}), trigger: 'blur'},
          ],
          managementScope: [
            {required: true, message: "请输入经营范围", trigger: "blur"},
            {validator: validateParams({type: 'Length', max: '500', msg: '经营范围最多可输入500个字'}), trigger: 'blur'},
          ],
          managementValidityDateType: [
            {required: true, message: "请选择营业期限", trigger: "change"},
          ],

          businessLicenseFileUrl: [
            {required: true, message: "请上传营业执照", trigger: "change"},
            {validator: validateParams({type: 'Length', min: 1})}
          ],
          certificateType: [
            {required: true, message: "请选择证件类型", trigger: "change"},
          ],
          idFileType: [
            {required: true, message: "请上传证件照", trigger: "change"},
            {
              validator: function (rule, value, callback) {
                if (value == '100') {
                  if (_self.form.idCardHeadFileUrl.length == 0 && _self.form.idCardEmblemFileUrl.length == 0) {
                    callback(new Error('请上传证件照'))
                  } else {
                    callback()
                  }
                } else if (value == '101') {
                  if (_self.form.idCardCopyFileUrl.length == 0) {
                    callback(new Error('请上传证件照'))
                  } else {
                    callback()
                  }
                }
              }, trigger: 'blur'
            }
          ],
          legalPersonName: [
            {required: true, message: "请输入法定代表人姓名", trigger: "blur"},
            {validator: validateParams({type: 'Chinese', msg: '请输入正确的姓名'}), trigger: 'blur'},
            {validator: validateParams({type: 'Length', max: '30', msg: '姓名最多可输入15个字'}), trigger: 'blur'},
          ],
          certificateValidityDateType: [
            {required: true, message: "请选择证件有效期", trigger: "change"},
          ],
        },
        businessLicenseFileUrl: [],
        idCardHeadFileUrl: [],
        idCardEmblemFileUrl: [],
        idCardCopyFileUrl: [],
      }
    },
    computed: {
      defaultAddress() {
        if (this.form.registerAddrProvince) {
          return this.form.registerAddrProvince + '/' + this.form.registerAddrCity + '/' + this.form.registerAddrTown
        } else {
          return ''
        }
      },
      certificateRules() {
        if (this.$dictCode('CertificateTypeEnum', this.form.certificateType).desc == '身份证') {
          return [
            {required: true, message: "请输入证件号码", trigger: "blur"},
            {validator: validateParams({type: 'IdCard', msg: '请输入正确的证件号码'}), trigger: 'blur'},
          ]
        } else {
          return [
            {required: true, message: "请输入证件号码", trigger: "blur"},
            {validator: validateParams({type: 'Length', msg: '请输入正确的证件号码', min: 1}), trigger: 'blur'},
          ]
        }
      },
      certificateTimeRange() {
        if (this.form.certificateTermBegin && this.form.certificateTermEnd) {
          return [this.form.certificateTermBegin, this.form.certificateTermEnd];
        } else {
          return [];
        }
      },
    },
    methods: {
      init() {
        for (let p in this.info) {
          this.$set(this.form, p, this.info[p])
        }
        this.preHandle()
      },
      preHandle() {
        if (this.form.businessLicenseFileUrl) {
          this.form.businessLicenseFileUrl = this.businessLicenseFileUrl = [{
            url: this.fileUrl + this.form.businessLicenseFileUrl,
            name: this.isPdf(this.form.businessLicenseFileUrl) ? '营业执照.pdf' : ''
          }]
        }
        if (this.form.idCardHeadFileUrl) {
          this.form.idCardHeadFileUrl = this.idCardHeadFileUrl = [{url: this.fileUrl + this.form.idCardHeadFileUrl}];
        }
        if (this.form.idCardEmblemFileUrl) {
          this.form.idCardEmblemFileUrl = this.idCardEmblemFileUrl = [{url: this.fileUrl + this.form.idCardEmblemFileUrl}];
        }
        if (this.form.idCardCopyFileUrl) {
          this.form.idCardCopyFileUrl = this.idCardCopyFileUrl = [{url: this.fileUrl + this.form.idCardCopyFileUrl}];
        }
        this.$set(this.form, 'idFileType', this.form.idCardCopyFileUrl ? '101' : '100');
      },
      beforeAvatarUpload(file) {
        return this.validateUploadFile({
          img: true,
          size: 6
        })(file)
      },
      handleRemove(file, fileList, param) {
        this[param] = this.form[param] = fileList;
      },
      handleSuccess(response, file, fileList, param) {
        this[param] = this.form[param] = fileList;
      },
      getFormData() {
        let form = JSON.parse(JSON.stringify(this.form));
        this.imgUrl.forEach(param => {
          form[param] = this[param][0]
            ? this[param][0].response
              ? this[param][0].response.data
              : this[param][0].url
            : '';
          form[param] = form[param].replace(new RegExp(this.fileUrl, 'g'), '')
        });
        if (form.idFileType == 100) {
          this.form.idCardCopyFileUrl = '';
          form.idCardCopyFileUrl = '';
        } else {
          this.form.idCardHeadFileUrl = this.form.idCardEmblemFileUrl = '';
          form.idCardHeadFileUrl = form.idCardEmblemFileUrl = '';
        }
        delete form.idFileType;
        return form;
      },
      handleFileRemove(file, param) {
        this.form[param] = this.form[param].filter(item => {
          return item.uid !== file.uid;
        });
        this[param] = this.form[param]
      },
      openFile(file) {
        let url = file.response ? (this.fileUrl + file.response.data) : file.url;
        window.open(url);
      },
    },
  }
</script>

<style scoped lang="scss">

</style>
