<template>
  <div class="merchant-info-container">
    <el-form
      ref="form"
      label-width="350px"
      :model="form"
      :rules="rules"
      class="block-form"
      :disabled="disabled"
    >
      <!--联系人信息-->
      <div class="company_info">
        <p class="subTitle">联系人信息</p>
        <div>
          <el-form-item
            label="负责人姓名："
            prop="contactName"
          >
            <el-input
              v-model="form.contactName"
              disabled
              clearable
            ></el-input>
            <p class="color-gray form-tip">
              请填写公司运营负责人
            </p>
          </el-form-item>
          <el-form-item
            label="负责人手机号："
            prop="contactPhone"
          >
            <el-input
              clearable
              v-model="form.contactPhone"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="客服电话："
            prop="servicePhone"
          >
            <el-input
              clearable
              v-model="form.servicePhone"
            ></el-input>
            <p class="color-gray form-tip">
              请注意填写格式，举例（座机：0660-12345678；手机：13502580000；400电话：4008880000
            </p>
          </el-form-item>
        </div>
      </div>
      <!--经营信息-->
      <div class="bankCard_info">
        <p class="subTitle">经营信息</p>
        <div>
          <el-form-item
            label=" 企业简称："
            prop="shortName"
          >
            <el-input v-model="form.shortName"></el-input>
          </el-form-item>
          <el-form-item
            label="实际经营地址："
            prop="managementAddrDetail"
          >
            <address-select
              :province.sync="form.managementAddrProvince"
              :city.sync="form.managementAddrCity"
              :town.sync="form.managementAddrTown"
              :default-address="defaultAddress"
            ></address-select>
            <el-input
              style="margin-top: 8px"
              clearable
              v-model="form.managementAddrDetail"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="门头照片"
            prop="doorPhotoFileUrl"
          >
            <el-upload
              v-toggle="doorPhotoFileUrl"
              class="upload-demo"
              data-type="doorPhotoFileUrl"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".png,.gif,.jpg,bmp"
              :limit="1"
              :file-list="doorPhotoFileUrl"
              :before-upload="beforeAvatarUpload"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'doorPhotoFileUrl')"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'doorPhotoFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div
                slot="tip"
                class="el-upload__tip"
              >最多1张，单张照片不超过6M。
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="办公内景照片"
            prop="workIndoorFileUrl"
          >
            <el-upload
              v-toggle="workIndoorFileUrl"
              data-type="workIndoorFileUrl"
              class="upload-demo"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".png,.gif,.jpg,bmp"
              :limit="1"
              :file-list="workIndoorFileUrl"
              :before-upload="beforeAvatarUpload"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'workIndoorFileUrl')"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'workIndoorFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div
                slot="tip"
                class="el-upload__tip"
              >最多1张，单张照片不超过6M。
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="前台照片"
            prop="receptionFileUrl"
          >
            <el-upload
              v-toggle="receptionFileUrl"
              data-type="receptionFileUrl"
              class="upload-demo"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              list-type="picture-card"
              accept=".png,.gif,.jpg,bmp"
              :limit="1"
              :file-list="receptionFileUrl"
              :before-upload="beforeAvatarUpload"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'receptionFileUrl')"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'receptionFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div
                slot="tip"
                class="el-upload__tip"
              >最多1张，单张照片不超过6M。
              </div>
            </el-upload>
          </el-form-item>
        </div>
      </div>

      <div
        class="invoice_info"
        v-if="form.invoice"
      >
        <p class="subTitle">开票信息</p>
        <el-form-item
          label="纳税人类型："
          prop="invoice.taxPayerType"
        >
          <el-radio-group v-model="form.taxPayerType">
            <el-radio
              v-for="(item, index) in $dict('TaxPayerTypeEnum')"
              :key="index"
              :label="Number(item.code)"
            >
              {{ item.desc }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="开票地址："
          prop="invoiceAddress"
        >
          <el-input
            type="textarea"
            :rows="4"
            clearable
            v-model="form.invoiceAddress"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话："
          prop="invoicePhone"
        >
          <el-input
            clearable
            v-model="form.invoicePhone"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开票银行："
          prop="invoiceBankName"
        >
          <el-input
            clearable
            v-model="form.invoiceBankName"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="开票账号："
          prop="invoiceAccountNo"
        >
          <el-input
            clearable
            v-model="form.invoiceAccountNo"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="默认开票类目：" prop="defaultInvoiceCategoryCode" v-if="isMerchant">
          <el-select
            class="big-input"
            v-model="form.defaultInvoiceCategoryCode"
            clearable
            @change="handleInvoiceChange">
            <el-option
            v-for="(item, key) in form.invoiceCategoryList"
            :key="key"
            :label="item.invoiceCategoryName"
            :value="item.invoiceCategoryCode"
            ></el-option>
          </el-select>
        </el-form-item> -->
      </div>
      <!-- 企业主要人员 -->
      <div class="personnels_info">
        <p class="subTitle">
          企业主要人员
          <el-button
            type="text"
            @click="addPeople"
          >增加</el-button>
        </p>
        <el-table :data="form.personnels">
          <el-table-column
            label="姓名"
            prop="name"
          >
            <template v-slot="{row, $index, column}">
              <div v-if="$index == 0">
                {{ row[column.property] }}
              </div>
              <div v-else>
                <el-input
                  v-model="row[column.property]"
                  clearable
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="身份证号码"
            prop="idCardNumber"
          >
            <template v-slot="{row, $index, column}">
              <div v-if="$index == 0">
                {{ row[column.property] }}
              </div>
              <div v-else>
                <el-input
                  v-model="row[column.property]"
                  clearable
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="职务"
            prop="position"
          >
            <template v-slot="{row, $index, column}">
              <div v-if="$index == 0">
                法人
              </div>
              <div v-else>
                <el-input
                  v-model="row[column.property]"
                  clearable
                ></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="50">
            <template v-slot="{$index}">
              <el-button
                type="text"
                @click="deletePeople($index)"
                style="color: #f00;"
                v-if="$index > 0"
                :disabled="disabled"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-form>
  </div>
</template>

<script>
  import { validateParams } from '@/utils/validate';

  export default {
    name: 'manageInfo',
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      previewHandle: Function,
    },
    watch: {
      info: {
        handler(val) {
          for (let p in val) {
            this.$set(this.form, p, val[p])
          }
          this.preHandle()
        }
      }
    },
    data() {
      return {
        imgUrl: ['doorPhotoFileUrl', 'workIndoorFileUrl', 'receptionFileUrl'],
        doorPhotoFileUrl: [], // 门头照
        workIndoorFileUrl: [],
        receptionFileUrl: [],

        form: {},
        rules: {
          contactName: [
            { required: true, message: "请输入负责人姓名", trigger: "blur" },
            { validator: validateParams({ type: 'Chinese', msg: '请输入正确的姓名' }), trigger: 'blur' },
            { validator: validateParams({ type: 'Length', max: '15', msg: '姓名最多可输入15个字' }), trigger: 'blur' },
          ],
          contactPhone: [
            { required: true, message: "请输入负责人手机号码", trigger: "blur" },
            { validator: validateParams({ type: 'Phone', msg: '请输入正确的手机号码' }), trigger: 'blur' },
          ],
          contactEmail: [
            { required: true, message: "请输入负责人常用邮箱", trigger: "blur" },
            { validator: validateParams({ type: 'Email', msg: '请输入正确的邮箱' }), trigger: 'blur' },
          ],
          servicePhone: [
            { required: true, message: "请输入客服电话", trigger: "blur" },
            { validator: validateParams({ type: 'Reg', pattern: /[0-9-]+/, msg: '请输入正确的客服电话' }), trigger: 'blur' },
          ],

          shortName: [
            { required: true, message: "请输入企业简称", trigger: "blur" },
            { validator: validateParams({ type: 'Chinese', msg: '请输入正确的企业简称' }), trigger: 'blur' },
            { validator: validateParams({ type: 'Length', max: '15', msg: '企业简称最多可输入15个字' }), trigger: 'blur' },
          ],
          managementAddrDetail: [
            { required: true, message: "请选择实际经营地址", trigger: 'blur' },
          ],
          doorPhotoFileUrl: [
            { required: true, message: '请上传公司照片' },
            { validator: validateParams({ type: 'Length', min: 1 }) }
          ],
          workIndoorFileUrl: [
            { required: true, message: '请上传公司照片' },
            { validator: validateParams({ type: 'Length', min: 1 }) }
          ],
          receptionFileUrl: [
            { required: true, message: '请上传公司照片' },
            { validator: validateParams({ type: 'Length', min: 1 }) }
          ],
          taxPayerType: [
            { required: true, message: '请选择纳税人类型', trigger: 'blur' },
          ],
          registerAddrInfo: [
            { required: true, message: '请输入单位注册地址及电话', trigger: 'blur' },
          ],
          defaultInvoiceCategoryCode: [
            { required: true, message: '请选择默认开票类目', trigger: 'change' },
          ],
          invoiceAddress: [
            { required: true, message: '请输入开票地址', trigger: 'blur' },
          ],
          invoiceBankName: [
            { required: true, message: '请输入开票银行', trigger: 'blur' },
          ],
          invoiceAccountNo: [
            { required: true, message: '请输入开票银行账户', trigger: 'blur' },
          ],
          invoicePhone: [
            { required: true, message: '请选择开票联系电话', trigger: 'blur' },
          ],
        },
      }
    },
    computed: {
      defaultAddress() {
        if (this.form.managementAddrProvince) {
          return this.form.managementAddrProvince + '/' + this.form.managementAddrCity + '/' + this.form.managementAddrTown
        } else {
          return ''
        }
      },
      isMerchant() {
        if (this.form.mchNo) {
          return this.form.mchNo.startsWith('M');
        }
        return false
      }
    },
    methods: {
      init() {
        for (let p in this.info) {
          this.$set(this.form, p, this.info[p])
        }
        this.preHandle()
      },
      preHandle() {
        this.doorPhotoFileUrl = this.form.doorPhotoFileUrl ? [{ url: this.fileUrl + this.form.doorPhotoFileUrl }] : [];
        this.workIndoorFileUrl = this.form.workIndoorFileUrl ? [{ url: this.fileUrl + this.form.workIndoorFileUrl }] : [];
        this.receptionFileUrl = this.form.receptionFileUrl ? [{ url: this.fileUrl + this.form.receptionFileUrl }] : [];

        this.form.doorPhotoFileUrl = this.doorPhotoFileUrl;
        this.form.workIndoorFileUrl = this.workIndoorFileUrl;
        this.form.receptionFileUrl = this.receptionFileUrl;

        // 开票地址和电话分离
        if (this.form.invoice) {
          this.$set(this.form, 'taxPayerType', this.form.invoice.taxPayerType);
          this.$set(this.form, 'invoiceAddress', this.form.invoice.registerAddrInfo.split(' ')[0]);
          this.$set(this.form, 'invoicePhone', this.form.invoice.registerAddrInfo.split(' ')[1]);
          this.$set(this.form, 'invoiceBankName', this.form.invoice.bankName);
          this.$set(this.form, 'invoiceAccountNo', this.form.invoice.accountNo);
          this.$set(this.form, 'defaultInvoiceCategoryName', this.form.invoice.defaultInvoiceCategoryName);
          this.$set(this.form, 'defaultInvoiceCategoryCode', this.form.invoice.defaultInvoiceCategoryCode);
        }
      },
      beforeAvatarUpload(file) {
        return this.validateUploadFile({
          img: true,
          size: 6
        })(file)
      },
      handleRemove(file, fileList, param) {
        this[param] = this.form[param] = fileList;
      },
      handleSuccess(response, file, fileList, param) {
        this[param] = this.form[param] = fileList;
      },
      handleInvoiceChange(val) {
        for (let i = 0; i < this.form.invoiceCategoryList.length; i++) {
          if (val == this.form.invoiceCategoryList[i].invoiceCategoryCode) {
            this.form.defaultInvoiceCategoryName = this.form.invoiceCategoryList[i].invoiceCategoryName;
            break;
          }
        }
      },
      getFormData() {
        let form = JSON.parse(JSON.stringify(this.form));
        this.imgUrl.forEach(param => {
          form[param] = this[param][0]
            ? this[param][0].response
              ? this[param][0].response.data
              : this[param][0].url
            : '';
          form[param] = form[param].replace(new RegExp(this.fileUrl, 'g'), '')
        });
        return form;
      },
      // 增加董监高
      addPeople() {
        this.form.personnels.push({
          name: '',
          idCardNumber: '',
          position: '',
          isLegal: false,
        })
      },
      // 删除董监高
      deletePeople(index) {
        this.form.personnels.splice(index, 1)
      },
    },
  }
</script>

<style scoped lang="scss">
  .el-form {
    width: 1000px;
  }
</style>
