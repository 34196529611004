import request from '@/utils/request.js';

/* 发票类目分页查询接口 */
export function listInvoiceCategoryPage(data) {
  return request({
    url: '/invoiceCategory/listPage',
    method: 'post',
    data
  })
}

/* 发票类目添加接口 */
export function addInvoiceCategory(data) {
  return request({
    url: '/invoiceCategory/save',
    method: 'post',
    data
  })
}

/* 发票类目修改接口 */
export function editInvoiceCategory(data) {
  return request({
    url: '/invoiceCategory/update',
    method: 'post',
    data
  })
}

/* 发票类目删除接口 */
export function deleteInvoiceCategory(data) {
  return request({
    url: '/invoiceCategory/delete',
    method: 'post',
    params: data
  })
}

/* 岗位类目查询接口 */
export function listAllWorkCategory() {
  return request({
    url: '/workCategory/listAll',
    method: 'get',
  })
}

/* 岗位类目保存接口 */
export function addWorkCategory(data) {
  return request({
    url: '/workCategory/save',
    method: 'post',
    data
  })
}

/* 岗位类目编辑接口 */
export function editWorkCategory(data) {
  return request({
    url: '/workCategory/update',
    method: 'post',
    data
  })
}

/* 岗位类目删除接口 */
export function deleteWorkCategory(data) {
  return request({
    url: '/workCategory/delete',
    method: 'get',
    params: data
  })
}

/* 行业类型查询接口 */
export function listAllIndustryType() {
  return request({
    url: '/industryType/listAll',
    method: 'get',
  })
}

/* 行业类型保存接口 */
export function addIndustryType(data) {
  return request({
    url: '/industryType/save',
    method: 'post',
    data
  })
}

/* 行业类型编辑接口 */
export function editIndustryType(data) {
  return request({
    url: '/industryType/update',
    method: 'post',
    data
  })
}

/* 行业类型删除接口 */
export function deleteIndustryType(data) {
  return request({
    url: '/industryType/delete',
    method: 'get',
    params: data
  })
}


/* 个税计算公式 */
export function getTaxList() {
  return request({
    url: '/personalIncomeTax/listAll',
    method: 'get'
  })
}

