import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
import { pathNameMap } from './pathToName'
import { flatten } from '@/utils'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/test',
    component: () => import('@/views/test'),
  },
  {
    path: '/retrievePwd',
    name: 'retrievePwd',
    component: () => import(/* webpackChunkName: "constant-group"*/ '@/views/common/retrievePwd'),
    meta: {
      title: '更改密码',
      noCache: true,
    }
  },
  {
    path: '/accountManage',
    name: 'accountManage',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/accountManage'),
    meta: {
      title: '账户管理',
      noCache: true,
    }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/404'),
    name: '404',
    hidden: true,
    meta: {
      title: '404',
      noCache: true,
    }
  },
  // {
  //   path: '/bpmn',
  //   component: () => import(/* webpackChunkName: "bpmn-group" */ '@/views/bpmn/index'),
  //   name: 'bpmn',
  //   meta: {
  //     title: '流程设计器',
  //     noCache: true,
  //   }
  // },
  {
    path: '/business/settingSignTemplate',
    name: 'settingSignTemplate',
    component: () => import(/* webpackChunkName: "drag-group" */ '@/views/business/agreementManagement/signTemplate/SignTemplateSetting')
  },
  {
    path: '/merchant/dragAgreement',
    component: () => import(/* webpackChunkName: "drag-group" */ '@/views/merchant/merchantManagement/dragAgreement'),
    hidden: true,
    meta: {
      noCache: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    meta: {
      noCache: true,
    },
    children: [{
      path: '/redirect/:path*',
      component: () => import(/* webpackChunkName: "constant-group" */ '@/views/common/Redirect'),
      name: 'redirect',
      meta: {
        noCache: true,
      }
    },
      {
        path: '/merchant/merchantManagement/createMerchant',
        component: () => import(/* webpackChunkName: "merchant-form-group" */ '@/views/merchant/merchantManagement/createMerchant'),
        hidden: true,
        name: 'createMerchant',
        meta: {
          title: '创建商户',
          noCache: true,
        }
      },
      {
        path: '/merchant/merchantManagement/createSupplier',
        component: () => import(/* webpackChunkName: "merchant-form-group" */ '@/views/merchant/merchantManagement/createSupplier'),
        hidden: true,
        name: 'createSupplier',
        meta: {
          title: '创建供应商',
          noCache: true,
        }
      },
      {
        path: '/system/organizationManagement/staffForm',
        component: () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/organizationManagement/staffForm'),
        hidden: true,
        name: 'staffForm',
        meta: {
          title: '员工编辑',
          noCache: true,
        },
      },
      {
        path: '/system/organizationManagement/departmentManagement',
        component: () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/organizationManagement/departmentManagement'),
        hidden: true,
        name: 'departmentManagement',
        meta: {
          title: '部门管理',
          noCache: true,
        },
      },
      {
        path: '/waitingHandle/detailProcess',
        // component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/detailProcess'),
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/flowDetail'),
        hidden: true,
        name: 'flowDetail',
        meta: {
          title: '流程详情',
          noCache: true,
        },
      },
      {
        path: '/business/mainstayCostForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/mainstayCostForm'),
        hidden: true,
        name: 'mainstayCostForm',
        meta: {
          noCache: true,
        },
      },
      {
        path: '/business/merchantProductCostForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/merchantProductCostForm'),
        hidden: true,
        name: 'merchantProductCostForm',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/merchantActiveProduct',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/merchantActiveProduct'),
        hidden: true,
        name: 'merchantActiveProduct',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/agentActiveProduct',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/agentActiveProduct'),
        hidden: true,
        name: 'agentActiveProduct',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/merchant/merchantInfo/:mchNo',
        component: () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/merchantManagement/merchantInfo'),
        hidden: true,
        name: 'merchantInfo',
        meta: {
          title: '商户信息',
          noCache: true,
        }
      },
      {
        path: '/merchant/merchantAuth',
        component: () => import(/* webpackChunkName: "merchant-form-group" */ '@/views/merchant/merchantManagement/merchantAuth'),
        hidden: true,
        name: 'merchantAuth',
        meta: {
          title: '主体认证',
          noCache: true,
        }
      },
      {
        path: '/merchant/startAgreement',
        component: () => import(/* webpackChunkName: "merchant-form-group" */ '@/views/merchant/merchantManagement/startAgreement'),
        hidden: true,
        name: 'startAgreement',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/merchant/finishAgreement',
        component: () => import(/* webpackChunkName: "merchant-form-group" */ '@/views/merchant/merchantManagement/finishAgreement'),
        hidden: true,
        name: 'finishAgreement',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/merchant/chooseAgreementTemplate',
        component: () => import(/* webpackChunkName: "merchant-form-group" */ '@/views/merchant/merchantManagement/chooseAgreementTemplate'),
        hidden: true,
        name: 'chooseAgreementTemplate',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/quote/mchQuoteForm',
        component: () => import(/* webpackChunkName: "quote-group" */ '@/views/quote/mchQuoteForm'),
        hidden: true,
      },
      {
        path: '/quote/agentQuoteForm',
        component: () => import(/* webpackChunkName: "quote-group" */ '@/views/quote/agentQuoteForm'),
        hidden: true,
      },
      {
        path: '/business/agreementTemplateForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/agreementManagement/agreementTemplate/agreementTemplateForm'),
        hidden: true,
        name: 'agreementTemplateForm',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/sale/saleCostForm',
        component: () => import(/* webpackChunkName: "sale-group" */ '@/views/sale/productCostManagement/saleCostManagementForm'),
        hidden: true,
        name: 'saleCostForm',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/mainstayPayAccountForm',
        name: 'mainstayPayAccountForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/mainstayPayAccountForm'),
        hidden: true,
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/merchantPayAccountForm',
        name: 'merchantPayAccountForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/merchantPayAccountForm'),
        hidden: true,
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/agentCostForm',
        name: 'agentCostForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/agentCostForm'),
        hidden: true,
        meta: {
          noCache: true,
        }
      },
      {
        path: '/riskControl/ruleForm',
        name: 'riskControlForm',
        component: () => import(/* webpackChunkName: "risk-group" */ '@/views/riskControl/ruleForm'),
        hidden: true,
        meta: {
          noCache: true,
        }
      },
      {
        path: '/finance/issueInvoice/infoForm',
        component: () => import(/* webpackChunkName: "finance-group" */ "@/views/finance/issueInvoice/infoForm"),
        name: '',
        meta: {
          noCache: true,
        }
      },
      {
        path: '/finance/issueInvoice/issueDetail',
        name: 'invoiceDetail',
        component: () => import(/* webpackChunkName: "finance-group" */ "@/views/finance/issueInvoice/issueDetail"),
        meta: {
          noCache: true,
        }
      },
      {
        path: '/agent/createGuide',
        name: 'agentCreateGuide',
        component: () => import(/* webpackChunkName: "agent-group" */ "@/views/agent/createGuide"),
        meta: {
          noCache: true,
          title: '合伙人类型选择',
        }
      },
      {
        path: '/agent/createPerson',
        name: 'agentCreatePerson',
        component: () => import(/* webpackChunkName: "agent-group" */ "@/views/agent/createPerson"),
        meta: {
          noCache: true,
          title: '个人合伙人'
        }
      },
      {
        path: '/agent/createCompany',
        name: 'agentCreateCompany',
        component: () => import(/* webpackChunkName: "agent-group" */ "@/views/agent/createCompany"),
        meta: {
          noCache: true,
          title: '非个人合伙人'
        }
      },
      {
        path: '/agent/businessRelation',
        name: 'agentBusinessRelation',
        component: () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/businessRelation'),
        meta: {
          noCache: true,
        }
      },
      {
        path: '/agent/agentInfo/:agentNo',
        name: 'agentInfo',
        component: () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/agentInfo'),
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/merchantPayAccountCheck',
        name: 'merchantPayAccountCheck',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/merchantPayAccountCheck'),
        meta: {
          noCache: true,
        }
      },
      {
        path: '/system/process/processForm',
        name: 'processForm',
        component: () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/process/processForm'),
        meta: {
          noCache: true,
        }
      },
      {
        path: '/business/mainstayBalance',
        name: 'mainstayBalance',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/mainstayBalance')
      },
      {
        path: '/business/signTemplateForm',
        name: 'signTemplateForm',
        component: () => import(/* webpackChunkName: "business-group" */ '@/views/business/agreementManagement/signTemplate/signTemplateForm'),
        meta: {
          title: '电子签约模板'
        }
      },
      {
        path: '/riskControl/people/form',
        name: 'riskControlPeopleForm',
        component: () => import(/* webpackChunkName: "riskControl-group" */ '@/views/riskControl/people/form'),
        meta: {
          title: '风控名单管理'
        }
      },
      {
        path: '/waitingHandle/worklist/detail',
        name: 'waitingHandleWorklistForm',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/detail'),
        meta: {
          title: '待办工单'
        }
      },
      {
        path: '/waitingHandle/worklist/create',
        name: 'waitingHandleWorklistCreate',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/create'),
        meta: {
          title: '创建工单'
        }
      },
      {
        path: '/waitingHandle/wordlist/generate',
        name: 'waitingHandleWordlistGenerate',
        component: () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/generate'),
        meta: {
          title: '生成工单'
        }
      },
      {
        path: '/data/preview/saler',
        name: 'dataPreviewSaler',
        component: () => import(/* webpackChunkName: "data-group" */ '@/views/data/preview/saler'),
        meta: {
          title: '销售数据'
        }
      },
      {
        path: '/notify/form',
        name: 'notifyForm',
        component: () => import(/* webpackChunkName: "notify-group" */ '@/views/notify/form'),
        meta: {
          title: '通知'
        }
      },
      {
        path: '/data/previewSelf/month',
        name: 'dataPreviewSelfMonth',
        component: () => import(/* webpackChunkName: "data-group" */ '@/views/data/preview/month'),
        meta: {
          title: '数据概览'
        }
      },
      {
        path: '/job/task/detail',
        component: () => import(/* webpackChunkName: "job-group" */ '@/views/job/task/detail')
      },
      {
        path: '/proxyorder/supply/form',
        component: () => import(/* webpackChunkName: "proxyorder-group" */ '@/views/proxyorder/supply/form'),
        meta: {
          title: '供应商编辑'
        }
      },
      {
        path: '/proxyorder/supply/taxquote/:id',
        component: () => import(/* webpackChunkName: "proxyorder-group" */ '@/views/proxyorder/supply/taxquote'),
        meta: {
          title: '报价管理'
        }
      },
      {
        path: '/proxyorder/order/detail',
        component: () => import(/* webpackChunkName: "proxyorder-group" */ '@/views/proxyorder/order/detail')
      },
      {
        path: '/finance/proxyInvoice/zxh',
        component: () => import(/* webpackChunkName: "invoice-group" */ '@/views/finance/proxyInvoice/issueInvoice_zxh'),
      }, {
        path: '/finance/proxyInvoice/ckh',
        component: () => import(/* webpackChunkName: "invoice-group" */ '@/views/finance/proxyInvoice/issueInvoice_ckh'),
      },
    ]
  },
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes,
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router


let routesInfo = {
  'config:dictionary:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/dataDictionary/DataDictionaryList'),
  'timer:schedule:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/timer/ScheduleJobList'),
  'config:globallock:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/lock/GlobalLockList'),

  /* 商户 */
  'sale:merchant:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/merchantManagement/merchant'),
  'recommend:merchant:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/recommendManagement/index'),
  'merchantManagement:agreement:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/merchantManagement/agreement'),
  'employer:operator:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/userManagement/operatorList'),
  'employer:staff:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/userManagement/staffList'),
  'employer:function:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/portalManagement/employerFunctionList'),
  'employer:role:view': () => import(/* webpackChunkName: "merchant-group" */ '@/views/merchant/roleManagement/presetRoleList'),

  /* 供应商 */
  'supplier:operator:view': () => import(/* webpackChunkName: "supplier-group" */ '@/views/supplier/userManagement/operatorList'),
  'supplier:staff:view': () => import(/* webpackChunkName: "supplier-group" */ '@/views/supplier/userManagement/staffList'),
  'supplier:function:view': () => import(/* webpackChunkName: "supplier-group" */ '@/views/supplier/portalManagement/employerFunctionList'),
  'supplier:role:view': () => import(/* webpackChunkName: "supplier-group" */ '@/views/supplier/roleManagement/presetRoleList'),

  /* 系统管理 */
  'pms:operator:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/organizationManagement/staffManagement'),
  'pms:department:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/organizationManagement/departmentManagement'),
  'pms:role:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/permissionManagement/roleManagement'),
  'pms:function:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/permissionManagement/functionManagement'),
  'pms:operateLog:view': () => import(/* webpackChunkName: "system-group" */ "@/views/systemManagement/operateLogList"),
  'pms:notify:mq:view': () => import(/* webpackChunkName: "system-group" */ "@/views/systemManagement/notify/mqNotify"),
  'pms:process:view': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/process/processManage'),
  'pms:file:post': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/filePost/list'),
  'pms:func:blacklist': () => import(/* webpackChunkName: "system-group" */ '@/views/systemManagement/blackList/list'),
  /*待办*/
  'waitingHandle:myProcess:view': () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/flow'),
  'waitingHandle:worklist:view': () => import(/* webpackChunkName: "process-group" */ '@/views/waitingHandle/workform/list'),

  /* 业务 */
  'businessManage:invoiceCategory:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/businessManage/invoiceCategory/InvoiceCategoryList'),
  'businessManage:workCategory:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/businessManage/workCategory/WorkCategoryList'),
  'businessManage:industryType:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/businessManage/industryType/IndustryTypeList'),
  'business:agreement:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/agreementManagement/agreementTemplate/agreementTemplate'),
  'fee:product:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/productManagement'),
  'fee:vendor:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/mainstayManagement'),
  'fee:vendorFeeManager:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/mainstayCost'),
  'fee:mchProdManager:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/merchantProductManagement'),
  'fee:mchFeeManager:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/merchantProductCostManagement'),
  'business:levy:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/levyManagement/levyTable'),
  'business:payChannel:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/payChannel'),
  'mainstay:payAccount:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/mainstayPayAccount'),
  'merchant:payAccount:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/merchantPayAccount'),
  'channel:list:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/payManagement/channelRecord'),
  'fee:agentProduct:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/agentProduct'),
  'fee:agentFeeManager:list': () => import(/* webpackChunkName: "business-group" */ '@/views/business/productManagement/agentCost'),
  'business:sign:view': () => import(/* webpackChunkName: "business-group" */ '@/views/business/agreementManagement/signTemplate'),
  /* 销售 */
  'sales:salesCostRule:view': () => import(/* webpackChunkName: "sale-group" */ '@/views/sale/productCostManagement/saleCostManagement'),

  /* 风控 */
  'riskcontrol:rule': () => import(/* webpackChunkName: "risk-group" */ '@/views/riskControl/ruleManagement'),
  'riskcontrol:pending:view': () => import(/* webpackChunkName: "risk-group" */ '@/views/riskControl/orderProcess/hang'),
  'riskcontrol:resolve:view': () => import(/* webpackChunkName: "risk-group" */ '@/views/riskControl/orderProcess/resolve'),
  'riskControl:people:view': () => import(/* webpackChunkName: "risk-group" */ '@/views/riskControl/people/list'),

  /* 交易中心 */
  'trade:order:roster:view': () => import(/* webpackChunkName: "trade-group" */ '@/views/trade/order/roster'),
  'trade:order:detail:view': () => import(/* webpackChunkName: "trade-group" */ '@/views/trade/order/detail'),
  'trade:order:record:view': () => import(/* webpackChunkName: "trade-group" */ '@/views/trade/order/record'),
  'trade:order:invoice:view': () => import(/* webpackChunkName: "trade-group" */ '@/views/trade/order/invoice'),
  "trade:order:receipt": () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/order/receipt"),
  'account:charge:list': () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/account/chargeRecord"),
  'account:withdraw:list': () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/account/withdrawRecord"),
  'account:wechat:list': () => import(/* webpackChunkName: "trade-group" */ "@/views/trade/account/wechatRecord"),
  'account:wechat:adjust': () => import(/* wechatChunkName: "trade-group" */ "@/views/trade/account/wechatAdjust"),
  'trade:outer:roster': () => import(/* webpackChunkName: "trade-group" */ '@/views/trade/order/roster'),
  'trade:outer:detail': () => import(/* webpackChunkName: "trade-group" */ '@/views/trade/order/detail'),

  /* 计费订单 */
  'fee:merchantFeeOrder:list': () => import(/* webpackChunkName: "fee-group" */ '@/views/trade/feeOrder/merchantFeeOrder'),
  'fee:vendorFeeOrder:list': () => import(/* webpackChunkName: "fee-group" */ '@/views/trade/feeOrder/vendorFeeOrder'),
  'fee:salesFeeOrder:list': () => import(/* webpackChunkName: "fee-group" */ '@/views/trade/feeOrder/salesFeeOrder'),
  'fee:agentFeeOrder:list': () => import(/* webpackChunkName: "fee-group" */ '@/views/trade/feeOrder/agentFeeOrder.vue'),

  /* 财务 */
  'account:invoice:view': () => import(/* webpackChunkName: "finance-group" */ '@/views/finance/accountInvoice/AccountInvoiceList'),
  'account:invoice:processPending:view': () => import(/* webpackChunkName: "finance-group" */ '@/views/finance/accountInvoice/AccountInvoiceProcessPendingList'),
  'account:invoice:processDetail:view': () => import(/* webpackChunkName: "finance-group" */ '@/views/finance/accountInvoice/AccountInvoiceProcessDetailList'),
  'account:invoice:result:view': () => import(/* webpackChunkName: "finance-group" */ '@/views/finance/accountInvoice/AccountInvoiceProcessResultList'),

  /* 开票 */
  'invoice:view': () => import(/* webpackChunkName: "invoice-group" */ '@/views/finance/issueInvoice/issueRecord'),
  'invoice:taxprofile': () => import(/* webpackChunkName: "invoice-group" */ '@/views/finance/tax/taxProfile.vue'),
  'invoice:proxy:issue': () => import(/* webpackChunkName: "invoice-group" */ '@/views/finance/proxyInvoice/searchList'),

  /* 结算 */
  'agent:settle:view': () => import(/* webpackChunkName: "finance-group" */ '@/views/finance/settleManage/agentSettle'),

  /* 电子签约 */
  'sign:signRecord:view': () => import(/* webpackChunkName: "sign-group" */ '@/views/sign/signInfo'),

  /* 合伙人 */
  'agent:list:view': () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/list'),
  'agent:operator:view': () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/userManagement/operatorList'),
  'agent:staff:view': () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/userManagement/staffList'),
  'agent:function:view': () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/portalManagement/employerFunctionList'),
  'agent:statistic:view': () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/statistic'),
  'agent:role:view': () => import(/* webpackChunkName: "agent-group" */ '@/views/agent/roleManagement/presetRoleList'),

  /* 数据 */
  'data:company:view': () => import(/* webpackChunkName: "data-group" */ '@/views/data/company/list'),
  'data:person:view': () => import(/* webpackChunkName: "data-group" */ '@/views/data/person/list'),
  'data:preview:month': () => import(/* webpackChunkName: "data-group" */ '@/views/data/preview/month'),
  'data:core:view': () => import(/* webpackChunkName: "data-group" */ '@/views/data/operate/core'),
  'data:main:view': () => import(/* webpackChunkName: "data-group" */ '@/views/data/operate/main'),

  /* 消息 */
  'notify:list': () => import(/* webpackChunkName: "notify-group" */ '@/views/notify/list'),
  //银行管理
  'bankinfo:view': () => import(/* webpackChunkName: "notify-group" */ '@/views/systemManagement/bankinfo/bankInfoManager'),
  'userInfo:view': () => import(/* webpackChunkName: "notify-group" */ '@/views/systemManagement/userinfo/userInfoManager'),
  /* 任务 */
  'job:task': () => import(/* webpackChunkName: "job-group" */ '@/views/job/task/list'),

  /**账单管理 */
  'bill:view': () => import(/* webpackChunkName: "bill-group" */ '@/views/bill/index'),

  /* 代开发票 */
  'proxyorder:supply': () => import(/* webpackChunkName: "proxyorder-group" */ '@/views/proxyorder/supply/list'),
  'proxyorder:order': () => import(/* webpackChunkName: "proxyorder-group" */ '@/views/proxyorder/order/list'),

  /* oem */
  'merchant:oem': () => import(/* webpackChunkName: "oem-group" */ '@/views/oem/list.vue'),
};

function buildMenuTree(pid, pidGroup, resultArr) {
  let group = pidGroup[pid];
  if (!group) {
    return;
  }
  group.forEach((p) => {
    let menu = {};
    menu.meta = {
      id: p.id,
      title: p.name,
      number: p.number,
      permissionFlag: p.permissionFlag,
      parentId: p.parentId
    }
    menu.path = p.url;
    if (pidGroup[p.id]) {
      menu.children = [];
      buildMenuTree(p.id, pidGroup, menu.children);
    }
    resultArr.push(menu)
  })
}

function sortByNumber(array) {
  if (array.length == 0) return;
  array.sort((a, b) => {
    if (a.meta.number < b.meta.number) {
      return -1
    } else {
      return 1
    }
  })
  array.forEach(item => {
    if (item.children) {
      sortByNumber(item.children);
    }
  })
}

export const buildMenu = function (userFunctions) {
  let pidGroup = {};
  userFunctions.filter(f => f.functionType === 1).forEach(f => {
    if (!pidGroup[f.parentId]) {
      pidGroup[f.parentId] = [f]
    } else {
      pidGroup[f.parentId].push(f)
    }
  });

  let resultArr = [];
  buildMenuTree(0, pidGroup, resultArr);
  sortByNumber(resultArr);
  setRedirect(resultArr);
  return resultArr;
};

function setRedirect(menu) {
  menu.forEach(m => {
    if (m.children) {
      m.meta.redirect = getFinalUrl(m)
      setRedirect(m.children)
    }
  })
}

function getFinalUrl(menu) {
  let child = menu.children[0];
  if (child && child.children) {
    return getFinalUrl(child);
  } else {
    return child.path || '/'
  }
}


export const buildRoute = function (userMenu) {
  let menuRoute = {
    path: '/',
    component: Layout,
    children: [],
    meta: {
      title: '首页'
    }
  };
  let menuList = [];
  flatten(userMenu, menuList);
  menuList.forEach(f => {
    const fRoute = {
      component: routesInfo[f.meta.permissionFlag],
      path: f.path,
      name: pathNameMap[f.meta.permissionFlag],
      meta: f.meta,
    };
    menuRoute.children.push(fRoute);
  });
  /* XXX: 解决权限页面刷新，vuex数据刷新跳404页面的问题。原因：路由动态加载没有完成，若404存在，则直接跳404，因此404页面需要动态加载 */
  let ret = [menuRoute, {
    path: '/*',
    redirect: '/404',
    hidden: true,
  }]
  return ret;
};


// export const buildRoute = function (userFunctions) {
//   let menuRoute = {
//     path: '/',
//     component: Layout,
//     children: [],
//     meta: {
//       title: '首页'
//     }
//   };
//   userFunctions.filter(f => f.functionType === 1).forEach(f => {
//     const fRoute = {
//       component: routesInfo[f.permissionFlag],
//       path: f.url,
//       name: pathNameMap[f.permissionFlag],
//       meta: {
//         id: f.id,
//         title: f.name,
//         permissionFlag: f.permissionFlag,
//       }
//     };
//     menuRoute.children.push(fRoute);
//   });
//   return [menuRoute];
// };

export function buildFuncMap(route) {
  let map = {};
  route.forEach(f => {
    if (map[f.meta.id] === undefined) {
      map[f.meta.id] = f
    }
  });
  return map
}
