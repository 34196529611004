<template>
  <div class="merchant-info-container">
    <el-table :data="info.data">
      <el-table-column
        label="工单编号"
        width="120"
        prop="workOrderNo"
      >
        <template v-slot="{row}">
          <el-button
            type="text"
            @click="goFlow(row)"
          >{{row.workOrderNo}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        label="工单标题"
        prop="flowTopicName"
      ></el-table-column>
      <el-table-column
        label="创建时间"
        width="120"
        prop="createTime"
      >
        <template v-slot="{row}">
          <div v-html="renderTime(row.createTime)"></div>
        </template>
      </el-table-column>
      <el-table-column
        label="截止时间"
        width="120"
        prop="endDate"
      >
        <template v-slot="{row}">
          <div v-html="renderTime(row.endDate)"></div>
        </template>
      </el-table-column>
      <el-table-column label="工单状态"> <template v-slot="{row}">
          <el-tag v-if="row.status == 101">
            {{ $dictCode('FlowStatus', row.status).desc }}
          </el-tag>
          <el-tag
            v-if="row.status == 100"
            type="success"
          >
            {{ $dictCode('FlowStatus', row.status).desc }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="发起人"
        prop="initiatorName"
      ></el-table-column>
    </el-table>
    <div class="text-right">
      <el-pagination
        @size-change="onPageChange(true)"
        @current-change="onPageChange()"
        :current-page.sync="page.current"
        :page-size.sync="page.size"
        :page-sizes="[10, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      info: {
        type: Object,
        default: () => ({}),
      },
      isPop: {
        type: Boolean,
        default: false,
      },
      closePop: Function,
    },
    data() {
      return {
        page: {
          size: 10,
          current: 1,
          total: 0,
        }
      }
    },
    watch: {
      info: {
        handler(val) {
          this.page.total = val.totalRecord
        },
        immediate: true,
      }

    },
    methods: {
      goFlow({ commonFlowId, taskId, status }) {
        if (this.isPop) {
          this.closePop();
        }
        this.$emit('route-change', {
          path: '/waitingHandle/worklist/detail',
          query: {
            commonFlowId,
            taskId,
            actionType: status == 100 ? 'VIEW' : undefined,
          },
          newTab: true,
        })
      },
      onPageChange(init) {
        if (init) {
          this.page.current = 1;
        }
        this.$emit('page-change', this.page);
      }
    },
  }
</script>

<style>
</style>