<template>
  <div class="warning-container" :style="blockStyle">
    <i class="el-icon-warning"></i>
    <div class="warning-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backColor: {
      type: String,
      default: '#fdf6ec'
    },
    color: {
      type: String,
      default: '#FBB84E'
    }
  },
  computed: {
    blockStyle() {
      return {
        backgroundColor: this.backColor,
        color: this.color,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .warning-container {
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 4px;
    font-size: 12px;
    text-align: left;
    @extend .clearfix;

    .el-icon-warning {
      float: left;
    }
    .warning-content {
      margin-left: 20px;
      line-height: 1;

      * {
        line-height: 1;
      }

      .warning-title {
        font-weight: bold;
      }
    }
  }
</style>
