import width from './width'

const install = (Vue) => {
  if (install.installed) return;
  install.installed = true;
  Vue.directive('width', width)
}

width.install = install

export default width;
