const state = {
  userData: undefined, // userData.type 1：管理员, 2: 普通用户
  leftMenu: null,
  rightMenu: null,
  menus: null,
};

const mutations = {
  SET_USER_DATA: (state, userData) => {
    state.userData = userData
  },
  SET_MENUS: (state, data) => {
    state.menus = data
  },
  SET_LEFTMENU: (state, data) => {
    state.leftMenu = data
  },
  SET_RIGHTMENU: (state, data) => {
    state.rightMenu = data
  },
}

const actions = {
  async setUserData({commit}, userData) {
    commit('SET_USER_DATA', userData);
    userData && commit('SET_MENUS', userData.menus);
    return(userData)
  },
  async setLeftMenu({commit}, data) {
    commit('SET_LEFTMENU', data)
    return(data)
  },
  async setRightMenu({commit}, data) {
    commit('SET_RIGHTMENU', data)
    return(data)
  },
}

export default {
  state,
  mutations,
  actions
}
