<template>
  <el-dialog
    :visible.sync="isShow"
    title="导出列表"
    width="800px"
    append-to-body
  >
    <el-button
      type="primary"
      @click="onRefresh">刷新列表
    </el-button>
    <el-table :data="recordList">
      <el-table-column
        label="创建时间"
        prop="createTime"
        width="100"></el-table-column>
      <el-table-column
        label="标题"
        prop="fileName"></el-table-column>
      <el-table-column
        label="文件列表"
        prop="fileNo"></el-table-column>
      <el-table-column
        label="操作人"
        prop="operatorLoginName"></el-table-column>
      <el-table-column
        label="状态"
        prop="exportStatus">
        <template slot-scope="scope">
          {{ $dictCode('ExportStatusEnum', scope.row.exportStatus).desc }}
        </template>
      </el-table-column>
      <el-table-column
        label="描述"
        prop="errDesc"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="$dictCode('ExportStatusEnum', scope.row.exportStatus).desc === '成功'"
            @click="exportFile(scope.row)">导出
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getExportRecord } from '../../api/common'

export default {
  name: 'index',
  data() {
    return {
      isShow: false,
      recordList: [],
      reportType: 0,
    }
  },
  methods: {
    open(type, data = {}) {
      this.isShow = true;
      this.getExportRecord(type, data)
    },
    async getExportRecord(reportType, form = {}) {
      this.reportType = reportType;
      const { data } = await getExportRecord({
        reportType,
        ...form
      });
      this.recordList = data;
    },
    exportFile(data) {
      let urls = data.fileUrl.split(',').filter(Boolean)
      urls.forEach(async (url, index) => {
        await this.sleep(index * 200)
        this.downloadByLink(this.fileUrl + url)
      })
    },
    onRefresh() {
      this.getExportRecord(this.reportType)
    }
  }
}
</script>

<style scoped>

</style>
