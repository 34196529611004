<template>
  <el-upload
    v-toggle:[limit]="uploadFileList"
    v-bind="$attrs"
    class="upload-demo"
    :class="{'text-center': drag}"
    ref="uploader"
    :action="remoteAction"
    :headers="uploadHeader"
    :list-type="uploadListType"
    :accept="totalAccept"
    :limit="maxLimit"
    :file-list="uploadFileList"
    :drag="drag"
    :before-upload="beforeUpload"
    :on-remove="(file, fileList) => onRemove(fileList)"
    :on-success="(response, file, fileList) => onSuccess(file, fileList)"
    :on-preview="handlePreview">
    <template
      v-if="!drag"
      v-slot:file="{ file }"
    >
      <div
        class="fileBg"
        v-if="listType === 'picture-card' && !isImageFile(file)"
      >
        <span class="file-name">{{ file.name || name }}</span> <span class="el-upload-list__item-actions">
           <span
             v-if="showPreview"
             class="el-upload-list__item-preview"
             @click="handlePreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handleFileRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </template>
    <slot
      v-if="showTip"
      slot="tip"
      name="tip">
      <div class="file-tip-content">
        支持扩展名：{{ totalAcceptFormat }}。
        <slot name="tip-append"></slot>
      </div>
    </slot>
    <slot
      name="trigger"
      slot="trigger"
    >
      <template v-if="drag">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </template>
      <i
        v-else-if="listType === 'picture-card'"
        class="el-icon-plus avatar-uploader-icon"> </i>
      <el-button
        v-else
        type="primary"
        size="mini">点击上传
      </el-button>
    </slot>
  </el-upload>
</template>

<script>
export default {
  beforeUpdate() {
    // debugger
  },
  name: 'FileUpload',
  props: {
    listType: {
      type: String,
      default: 'picture-card'
    },
    max: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Object,
      default: () => ({})
    },
    url: {
      type: String,
      default: '',
    },
    urls: {
      type: Array,
      default: () => []
    },
    accept: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '文件'
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
    drag: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: ''
    },
    showTip: {
      type: Boolean,
      default: true,
    },
    multiUpload: {
      // 标识外部组件用urls还是url
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      uploadFileList: [],
    }
  },
  computed: {
    validateOption() {
      return Object.assign({}, {
        img: true,
        size: 6,
      }, this.options)
    },
    totalAccept() {
      let accept = ''
      if (this.validateOption.img) {
        accept += ',.jpg,.jpeg,.png'
      }
      if (this.validateOption.pdf) {
        accept += ',.pdf'
      }
      if (this.validateOption.word) {
        accept += ',.doc,.docx'
      }
      if (this.validateOption.excel) {
        accept += ',.xls,.xlsx'
      }
      accept = this.accept + accept
      return accept
    },
    totalAcceptFormat() {
      return this.totalAccept.split(',').filter(i => !!i).join(' ')
    },
    maxLimit() {
      // 不传max，默认限制只有1个文件上传
      return this.max || 1
    },
    limit() {
      // 默认是0，不控制按钮显示
      return this.max === undefined ? 0 : this.max
    },
    isMulti() {
      // 标识能否上传多个
      return this.max !== 0 && (this.max > 1 || !this.max)
    },
    remoteAction() {
      return this.baseUrl + (this.action || '/file/upload')
    },
    uploadListType() {
      if (this.drag) {
        return null
      } else {
        return this.listType
      }
    }
  },
  watch: {
    url: {
      immediate: true,
      handler(val) {
        if (this.maxLimit === 1) {
          if (val) {
            this.handleUrlsChange([
              { url: this.fileUrl + val, name: this.name }
            ])
          } else {
            this.uploadFileList = []
          }
        }
      },
    },

    urls: {
      immediate: true,
      handler(val) {
        if (this.maxLimit > 1 || this.multiUpload) {
          if (!val || val.length === 0) {
            this.uploadFileList = []
            return
          }
          const urls = val.map(item => ({ url: this.fileUrl + item, name: this.name }))
          this.handleUrlsChange(urls)
        }
      }
    },
  },
  methods: {
    beforeUpload() {
      this.$emit('beforeUpload')
      return this.validateUploadFile(this.validateOption)
    },
    onSuccess(file, fileList) {
      this.uploadFileList = fileList;
      if (this.isMulti) {
        let urls = fileList.map(item => {
          if (item.response) {
            return item.response.data
          } else {
            return item.url
          }
        })
        this.$emit('update:urls', urls)
        this.$emit('change', urls)
      } else {
        this.$emit('update:url', file.response.data)
        this.$emit('change', file.response.data)
      }
      this.$emit('update:name', file.name)
      this.$emit('success')
    },
    onRemove(fileList) {
      this.uploadFileList = fileList;
      if (this.isMulti) {
        let urls = fileList.map(item => {
          if (item.response) {
            return item.response.data
          } else {
            return item.url
          }
        })
        this.$emit('update:urls', urls)
        this.$emit('change', urls)
      } else {
        this.$emit('update:url', '')
        this.$emit('change', '')
      }
      this.$emit('remove')
    },
    isImageFile(file) {
      if (file.response && file.response.data) {
        return this.isImg(file.response.data)
      } else {
        return this.isImg(file.url) || this.isImg(file.name)
      }
    },
    handlePreview(file) {
      let url = file.response ? this.fileUrl + file.response.data : file.url;
      if (this.isImg(url)) {
        this.$preview(url)
      } else {
        this.downloadFile(url, this.name);
      }
    },
    handleFileRemove(file) {
      this.uploadFileList = this.uploadFileList.filter(item => {
        return item.uid !== file.uid
      })
      this.onRemove(this.uploadFileList)
    },
    clearFiles() {
      this.$refs.uploader.clearFiles()
    },
    handleUrlsChange(urls) {
      let ret = []
      for (let i = 0; i < urls.length; i++) {
        let index = this.uploadFileList.findIndex(item => {
          if (item.response) {
            return (this.fileUrl + item.response.data) === urls[i].url
          } else {
            return (item.url === urls[i].url)
          }
        })
        if (index > -1) {
          // 寻找原队列中相同图片，避免重复渲染
          ret[i] = this.uploadFileList[index]
        } else {
          ret[i] = urls[i]
        }
      }
      this.uploadFileList = urls
    }
  },
}
</script>

<style
  scoped
  lang="scss">
.file-tip-content {
  font-size: 12px;
  color: gray;
  line-height: 1.8;
}
</style>
