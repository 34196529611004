module.exports = {

  title: '仕加科技 | 运营后台',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false,
  // 一级菜单宽度
  sidebarWidth: 100,
  // 二级菜单宽度
  submenuWidth: 150,
}
