import request from '@/utils/request.js';

// 获取全部合伙人
export function getAllAgentSimple(data) {
  return request({
    url: '/agent/listNotRetreatAgentSimple',
    method: 'post',
    data,
  })
}

// 创建合伙人（个人）
export function createAgentPerson(data) {
  return request({
    url: '/agent/createAgentPerson',
    method: 'post',
    data,
  })
}

// 创建合伙人（公司）
export function createAgentCompany(data) {
  return request({
    url: '/agent/createAgentCompany',
    method: 'post',
    data
  })
}

// 合伙人列表
export function getAgentList(data) {
  return request({
    url: '/agent/listAgentPage',
    method: 'post',
    data
  })
}

export function exportAgent(data) {
  return request({
    url: '/agent/exportAgent',
    method: 'post',
    data
  })
}

// 商户关系
export function getBusinessRelation(data) {
  return request({
    url: '/agent/listAgentMerchantRelationPage',
    method: 'post',
    data
  })
}

// 设置销售（审批）
export function setSeller(data) {
  return request({
    url: '/agent/setSeller',
    method: 'post',
    data
  })
}

// 设置合伙人（审批）
export function setInviter(data) {
  return request({
    url: '/agent/setInviter',
    method: 'post',
    data
  })
}

// 设置负责人（审批）
export function setPrincipal(data) {
  return request({
    url: '/agent/setPrincipal',
    method: 'post',
    data
  })
}

// 批量设置销售
export function batchSetSeller(data) {
  return request({
    url: '/agent/batchSetSeller',
    method: 'post',
    data
  })
}

// 批量设置邀请人
export function batchSetInviter(data) {
  return request({
    url: '/agent/batchSetInviter',
    method: 'post',
    data,
  })
}

// 获取详情
// export function getAgentDetail(params) {
//   return request({
//     url: '/agent/getAgentDetail',
//     method: 'get',
//     params
//   })
// }
// 多接口获取详情
export function getBaseInfo(data) {
  return request({
    url: '/agent/baseInfo',
    method: 'post',
    data,
  })
}

export function getCooperateInfo(data) {
  return request({
    url: '/agent/cooperation',
    method: 'post',
    data,
  })
}

export function getBankInfo(data) {
  return request({
    url: '/agent/agentBankInfo',
    method: 'post',
    data,
  })
}

export function getMainInfo(data) {
  return request({
    url: '/agent/mainInfo',
    method: 'post',
    data,
  })
}

export function editBaseInfo(data) {
  return request({
    url: '/agent/editBaseInfo',
    method: 'post',
    data
  })
}

export function editBankInfo(data) {
  return request({
    url: '/agent/editAgentBankInfo',
    method: 'post',
    data
  })
}

export function editMainInfo(data) {
  return request({
    url: '/agent/editMainInfo',
    method: 'post',
    data
  })
}


// 修改信息
export function updateCompany(data) {
  return request({
    url: '/agent/updateAgentDetailCompany',
    method: 'post',
    data,
  })
}

export function updatePerson(data) {
  return request({
    url: '/agent/updateAgentDetailCompanyPerson',
    method: 'post',
    data
  })
}

// 更改状态
export function changeStatus(data) {
  return request({
    url: '/agent/changeStatus',
    method: 'post',
    data,
  })
}

// 导出
export function exportInfo(data) {
  return request({
    url: '/agent/exportAgentDetail',
    method: 'post',
    data
  })
}

// 分页查询操作员
export function listOperatorPage(data) {
  return request({
    url: '/agentOperator/listPage',
    method: 'post',
    data
  })
}

// 分页查询员工
export function listStaffPage(data) {
  return request({
    url: '/agentStaff/listPage',
    method: 'post',
    data
  })
}

// 查询所有供应商后台功能
export function listFunction() {
  return request({
    url: '/agentFunction/listAll',
    method: 'get'
  });
}

// 新增后台功能
export function createFunction(data) {
  return request({
    url: '/agentFunction/add',
    method: 'post',
    data
  })
}

// 修改后台功能
export function editFunction(data) {
  return request({
    url: '/agentFunction/edit',
    method: 'post',
    data
  })
}

// 删除用工企业后台功能
export function deleteFunction(data) {
  return request({
    url: '/agentFunction/delete',
    method: 'get',
    params: data
  })
}

// 合伙人产品查询
export function getAgentProduction(data) {
  return request({
    url: '/agentProductRelation/list',
    method: 'post',
    data,
  })
}

// 合伙人产品状态更新
export function updateStatus(data) {
  return request({
    url: '/agentProductRelation/updateStatus',
    method: 'get',
    params: data,
  })
}

// 计费规则列表
export function getAgentRule(data) {
  return request({
    url: '/agentFeeRule/list',
    method: 'post',
    data
  })
}

// 添加合伙人产品
export function addProduct(data) {
  return request({
    url: '/agentProductRelation/add',
    method: 'post',
    data
  })
}

// 删除合伙人计费规则
export function deleteFeeRule({ id }) {
  return request({
    url: `/agentFeeRule/delete/${ id }`,
    method: 'post'
  })
}

// 增加计费规则
export function addFeeRule(data) {
  return request({
    url: '/agentFeeRule/add',
    method: 'post',
    data
  })
}

// 编辑计费规则
export function editFeeRule(data) {
  return request({
    url: '/agentFeeRule/edit',
    method: 'post',
    data,
  })
}

// 合伙人计费订单列表
export function agentFeeOrderList(data) {
  return request({
    url: '/agentFeeOrder/listPage',
    method: 'post',
    data
  })
}
export function agentStatistics(data) {
  return request({
    url: '/agentFeeOrder/agentStatistics',
    data,
    method: 'post'
  })
}

// 导出合伙人计费订单
export function exportList(data) {
  return request({
    url: '/agentFeeOrder/exportAgentFeeOrder',
    method: 'post',
    data
  })
}

// 导出合伙人订单汇总
export function exportAgentStatistics(data) {
  return request({
    url: '/agentFeeOrder/exportAgentStatistics',
    method: 'post',
    data
  })
}

// 分佣统计
export function getAgentFeeSum(data) {
  return request({
    url: '/agentFeeSum/listPage',
    method: 'post',
    data,
  })
}

// 合伙人计费订单统计
export function getAgentOrderFeeSum(data) {
  return request({
    url: '/agentFeeOrder/sumAgentFeeOrder',
    method: 'post',
    data
  })
}

// 合伙人月账单
export function getMonthBill(data) {
  return request({
    url: '/agentMonthBill/listPage',
    method: 'post',
    data
  })
}

// 月账单状态更新
export function updateMonthBill(data) {
  return request({
    url: '/agentMonthBill/check',
    method: 'post',
    data,
  })
}

// 导出月账单
export function exportBillList(data) {
  return request({
    url: '/agentMonthBill/exportOrderItem',
    method: 'post',
    data
  })
}


// 获取合伙人预置角色
export function getPresetRole(data) {
  return request({
    url: '/pmsAgentRole/listPage',
    method: 'post',
    data
  })
}

// 删除合伙人预置角色
export function deletePresetRole(data) {
  return request({
    url: '/pmsAgentRole/delete',
    method: 'post',
    data,
  })
}

// 编辑合伙人预置角色
export function editPresetRole(data) {
  return request({
    url: '/pmsAgentRole/edit',
    method: 'post',
    data
  })
}

// 创建合伙人预置角色
export function addPresetRole(data) {
  return request({
    url: '/pmsAgentRole/add',
    method: 'post',
    data
  })
}

// 查询合伙人角色权限
export function getRoleFunction(params) {
  return request({
    url: '/pmsAgentRole/listRoleFunction',
    method: 'get',
    params
  })
}

// 分配合伙人角色功能
export function assignPermission(data) {
  return request({
    url: '/pmsAgentRole/assignFunction',
    method: 'post',
    data,
  })
}


// 功能导入
export function importFunc(data) {
  return request({
    url: '/agentFunction/import',
    method: 'post',
    data,
  })
}

// 功能导出
export function exportFunc() {
  return request({
    url: '/agentFunction/export',
    method: 'get'
  })
}

// 配置报价单
export function editQuote(data) {
  return request({
    url: '/agent/editQuote',
    method: 'post',
    data,
  })
}

// 删除报价单
export function delQuote(data) {
  return request({
    url: '/agent/delQuote',
    method: 'post',
    data,
  })
}

export function getActiveAgents() {
  return request({
    url: '/agent/listActiveAgent',
    method: 'get'
  })
}

export function updateRealProfitRatio(id, value) {
  return request({
    url: 'agentFeeRule/updateRealProfitRatio?id=' + id + "&realProfitRatioValue=" + value,
    method: 'post'
  })
}
