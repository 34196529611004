// 用于将el-upload的 + 按钮切换隐藏
// TODO 改写成 类Class，传入切换元素class

function toggle(el, className, length, limit = 1) {
  let node = el.querySelector(className);
  node.style.display = length >= limit ? 'none' : 'inline-block';
}

export default {
  inserted(el, binding) {
    const { value, arg } = binding;
    if (!value || arg === 0) return;
    const className = '.el-upload';
    toggle(el, className, value.length, arg);
  },
  componentUpdated(el, binding) {
    const { value, arg } = binding;
    if (!value || arg === 0) return;
    const className = '.el-upload';
    toggle(el, className, value.length, arg);
  },
}
