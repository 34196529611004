var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-info-container"},[_c('p',{staticClass:"subTitle"},[_vm._v("产品报价单")]),_c('div',{staticClass:"clearfix",staticStyle:{"padding":"0 16px"}},[_c('span',{staticClass:"float-l",staticStyle:{"line-height":"40px"}},[_vm._v("共 "+_vm._s(_vm.form.length)+" 条")]),_c('div',{staticClass:"float-r"},[(!_vm.disabled)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.goToQuote}},[_vm._v("添加 ")]):_vm._e()],1)]),_c('el-table',{attrs:{"data":_vm.form,"span-method":_vm.spanMethod}},[_c('el-table-column',{attrs:{"label":"产品名称","prop":"productName"}}),_c('el-table-column',{attrs:{"label":"邀请奖励计费方式","prop":"calculateMode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$dictCode('CalculateModeEnum', row.calculateMode).desc)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"一级佣金"}},[_c('el-table-column',{attrs:{"label":"公式类型","prop":"formulaType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$dictCode('FormulaEnum', row.formulaType).desc)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"固定金额","prop":"fixedFee"}}),_c('el-table-column',{attrs:{"label":"费率","prop":"feeRate"}})],1),_c('el-table-column',{attrs:{"label":"二级佣金"}},[_c('el-table-column',{attrs:{"label":"公式类型","prop":"formulaType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$dictCode('FormulaEnum', row.secondFormulaType).desc)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"固定金额","prop":"secondFixedFee"}}),_c('el-table-column',{attrs:{"label":"费率","prop":"secondFeeRate"}})],1),_c('el-table-column',{attrs:{"label":"特殊计费规则"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.ruleType == 1)?_vm._l((row.ruleParam),function(item,index){return _c('div',{key:index,staticStyle:{"color":"12px"}},[_c('el-tooltip',{attrs:{"content":_vm.getRuleParamContent(row, item)}},[(item.specialRuleType == 1)?_c('span',[_vm._v(" 供应商... ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$dictCode('AgentSpecialRuleTypeEnum', item.specialRuleType).desc)+"... ")])])],1)}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"分润比例"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.realProfitRatio)+"% ")]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.status == 100 ? '' : 'info'}},[_vm._v(" "+_vm._s(_vm.$dictCode('QuoteStatusEnum', row.status).desc)+" ")])]}}])}),(!_vm.disabled)?_c('el-table-column',{attrs:{"label":"操作","prop":"operate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editQuote(row)}}},[_vm._v("编辑 ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.delQuote(row)}}},[_vm._v("删除 ")])]}}],null,false,1534046304)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }