const setWidth = (el, val) => {
  el.style.width = val
}
const setMaxWidth = (el, val) => {
  el.style.maxWidth = val
}
export default {
  inserted(el, binding) {
    let max = 0, width = 0;
    if (typeof binding.value === 'object') {
      max = binding.value.max || 0
      width = binding.value.default || 0
    } else {
      width = binding.value || 0
    }
    if (!isNaN(width)) {
      width = width + 'px'
    }
    setWidth(el, width)
    if (max) {
      setMaxWidth(el, max)
    }
  }
}
