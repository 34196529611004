<template>
  <div class="merchant-info-container">
    <el-form
      ref="form"
      label-width="350px"
      :model="form"
      :rules="rules"
      :disabled="disabled"
      class="block-form"
    >
      <div>
        <p class="subTitle">个人证件</p>
        <el-form-item label="身份证号码：" prop="certificateNumber" :rules="certificateRules" maxlength="30">
          <el-input clearable v-model="form.certificateNumber"></el-input>
        </el-form-item>
        <el-form-item label="证件类型：" prop="certificateType">
          <el-select
            clearable
            v-model="form.certificateType"
          >
            <el-option
              v-for="item in $dict('CertificateTypeEnum')"
              :key="item.code"
              :value="Number(item.code)"
              :label="item.desc"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="证件照：" prop="idFileType" ref="idFile">
          <el-radio-group v-model="form.idFileType">
            <el-radio label="100">彩色原件</el-radio>
            <el-radio label="101">复印件</el-radio>
          </el-radio-group>

          <div v-show="form.idFileType == '100'">
            <p>
              人像面
              <el-popover
                width="300"
                trigger="click"
              >
                <img
                  class="example-image"
                  src="@/assets/example-2.png"
                >
                <el-button
                  slot="reference"
                  type="text"
                >查看示例
                </el-button>
              </el-popover>
            </p>
            <el-upload
              v-toggle="idCardHeadFileUrl"
              key="idCardHeadFileUrl"
              class="upload-demo"
              list-type="picture-card"
              accept=".png,.gif,.jpg,.bmp"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              :limit="1"
              :file-list="idCardHeadFileUrl"
              :before-upload="beforeFileUpload"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'idCardHeadFileUrl', 'idFile')"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'idCardHeadFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p>
              国徽面
              <el-popover
                width="300"
                trigger="click"
              >
                <img
                  class="example-image"
                  src="@/assets/example-3.png"
                >
                <el-button
                  slot="reference"
                  type="text"
                >查看示例
                </el-button>
              </el-popover>
            </p>
            <el-upload
              v-toggle="idCardEmblemFileUrl"
              key="idCardEmblemFileUrl"
              class="upload-demo"
              list-type="picture-card"
              accept=".png,.gif,.jpg,.bmp"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              :limit="1"
              :file-list="idCardEmblemFileUrl"
              :before-upload="beforeFileUpload"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'idCardEmblemFileUrl', 'idFile')"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'idCardEmblemFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>

          <div v-show="form.idFileType == '101'">
            <p>
              <el-popover
                width="300"
                trigger="click"
              >
                <img
                  class="example-image"
                  src="@/assets/idCardCopyFileExample.png"
                >
                <el-button
                  slot="reference"
                  type="text"
                >查看示例
                </el-button>
              </el-popover>
            </p>
            <el-upload
              v-toggle="idCardCopyFileUrl"
              key="idCardCopyFileUrl"
              class="upload-demo"
              :action="baseUrl + '/file/upload'"
              :headers="uploadHeader"
              :limit="1"
              list-type="picture-card"
              accept=".png,.gif,.jpg,.bmp"
              :file-list="idCardCopyFileUrl"
              :before-upload="beforeFileUpload"
              :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'idCardCopyFileUrl', 'idFile')"
              :on-remove="(file, fileList) => handleRemove(file, fileList, 'idCardCopyFileUrl')"
              :on-preview="previewHandle"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>

        <el-form-item label="证件有效期：" prop="certificateValidityDateType">
          <el-radio-group v-model="form.certificateValidityDateType">
            <el-radio
              v-for="item in $dict('ValidityDateTypeEnum')"
              :key="item.code"
              :label="Number(item.code)"
            >{{ item.desc }}
            </el-radio>
          </el-radio-group>
          <date-picker
            style="display: block;"
            v-if="form.certificateValidityDateType == 1"
            picker="separate"
            type="date"
            :is-show-fast-time="false"
            :use-option="false"
            :start-time.sync="form.certificateTermBegin"
            :end-time.sync="form.certificateTermEnd"
          ></date-picker>
          <div v-if="form.certificateValidityDateType == 2">
            <el-date-picker
              type="date"
              placeholder="选择起始日期"
              v-model="form.certificateTermBegin"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
        </el-form-item>
      </div>

      <div v-if="agentType == 101">
        <el-form-item label="企业简称：" prop="shortName">
          <el-input
            clearable
            v-model="form.shortName"
            :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码：" prop="taxNo">
          <el-input
            clearable
            v-model="form.taxNo"
            :maxlength="18"></el-input>
          <p class="color-gray form-tip">请输入营业执照上18位统一社会信用代码</p>
        </el-form-item>
        <el-form-item label="注册地址" prop="registerAddrProvince">
          <address-select
            :province.sync="form.registerAddrProvince"
            :city.sync="form.registerAddrCity"
            :town.sync="form.registerAddrTown"
            :default-address="defaultAddress"
          ></address-select>
          <el-input clearable style="margin-top: 8px" v-model="form.registerAddrDetail" :maxlength="50"></el-input>
          <p class="color-gray form-tip">与营业执照登记住所一致</p>
        </el-form-item>
        <el-form-item label="经营范围" prop="managementScope">
          <el-input type="textarea" v-model="form.managementScope" :rows="6" :maxlength="500"></el-input>
          <p class="color-gray form-tip">与企业工商营业执照上一致</p>
        </el-form-item>

        <p class="subTitle">企业证件</p>
        <el-form-item label="营业执照" prop="businessLicenseFileUrl" ref="businessLicenseFileUrl">
          <el-upload
            v-toggle="businessLicenseFileUrl"
            key="businessLicenseFileUrl"
            class="upload-demo"
            list-type="picture-card"
            accept=".png,.gif,.jpg,.bmp"
            :action="baseUrl + '/file/upload'"
            :headers="uploadHeader"
            :limit="1"
            :file-list="businessLicenseFileUrl"
            :before-upload="beforeFileUpload"
            :on-success="(response, file, fileList) => handleSuccess(response, file, fileList, 'businessLicenseFileUrl', 'businessLicenseFileUrl')"
            :on-remove="(file, fileList) => handleRemove(file, fileList, 'businessLicenseFileUrl')"
            :on-preview="previewHandle"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div
              slot="tip"
              class="el-upload__tip"
            >请上传彩色原件或加盖公司公章的复印件，小于6M，文件格式为bmp、png、jpeg、jpg或gif。
              <el-popover
                trigger="click"
                width="300">
                <el-button type="text" slot="reference">
                  查看示例
                </el-button>
                <div>
                  <img
                    class="example-image"
                    src="@/assets/example-1.png"
                  >
                </div>
              </el-popover>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item label="营业期限" prop="managementValidityDateType">
          <el-radio-group v-model="form.managementValidityDateType">
            <el-radio
              v-for="item in $dict('ValidityDateTypeEnum')"
              :key="item.code"
              :label="Number(item.code)"
            >{{ item.desc }}
            </el-radio>
          </el-radio-group>
          <div v-if="form.managementValidityDateType == 1">
            <date-picker
              style="display: block;"
              picker="separate"
              type="date"
              :is-show-fast-time="false"
              :use-option="false"
              :start-time.sync="form.managementTermBegin"
              :end-time.sync="form.managementTermEnd"
            ></date-picker>
          </div>
          <div v-if="form.managementValidityDateType == 2">
            <el-date-picker
              type="date"
              placeholder="选择起始日期"
              v-model="form.managementTermBegin"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { validateParams } from '@/utils/validate';

  export default {
    name: 'mainInfo',
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      previewHandle: Function,

    },
    watch: {
      info: {
        handler(val) {
          for (let p in val) {
            this.$set(this.form, p, val[p])
          }
          this.preHandle()
        }
      }
    },
    data() {
      const self = this;
      return {
        form: {},
        rules: {
          shortName: [
            {required: true, message: '请输入简称', trigger: 'blur'}
          ],
          taxNo: [
            {required: true, message: '请输入统一社会信用代码', trigger: 'blur'},
          ],
          registerAddrProvince: [
            {required: true, message: '请选择注册地址', trigger: 'blur'}
          ],
          managementValidityDateType: [
            {required: true, message: "请选择营业期限", trigger: "change"},
            {
              validator: function (rule, value, callback) {
                let result = true;
                if (value == 1) {
                  if (!self.form.managementTermBegin || !self.form.managementTermEnd) {
                    result = false;
                  }
                } else if (value == 1) {
                  if (!self.form.managementTermBegin) {
                    result = false;
                  }
                }
                if (result == false) {
                  callback(new Error('请选择营业期限'))
                } else {
                  callback()
                }
              }
              , trigger: 'blur'
            }
          ],
          managementScope: [
            {required: true, message: '请输入经营范围', trigger: 'blur'}
          ],
          businessLicenseFileUrl: [
            {
              required: true, validator: function (rule, value, callback) {
                if (self.businessLicenseFileUrl.length == 0) {
                  callback(new Error('请上传营业执照'));
                  return;
                }
                callback();
              }
            }
          ],
        },

        businessLicenseFileUrl: [],
        idCardHeadFileUrl: [],
        idCardEmblemFileUrl: [],
        idCardCopyFileUrl: [],

        imgUrl: ['businessLicenseFileUrl', 'idCardHeadFileUrl', 'idCardEmblemFileUrl', 'idCardCopyFileUrl']
      }
    },
    computed: {
      defaultAddress() {
        if (this.form.registerAddrProvince) {
          return this.form.registerAddrProvince + '/' + this.form.registerAddrCity + '/' + this.form.registerAddrTown
        } else {
          return ''
        }
      },
      certificateRules() {
        if (this.$dictCode('CertificateTypeEnum', this.form.certificateType).desc == '身份证') {
          return [
            {required: true, message: "请输入证件号码", trigger: "blur"},
            {validator: validateParams({type: 'IdCard', msg: '请输入正确的证件号码'}), trigger: 'blur'},
          ]
        } else {
          return [
            {required: true, message: "请输入证件号码", trigger: "blur"},
            {validator: validateParams({type: 'Length', msg: '请输入正确的证件号码', min: 1}), trigger: 'blur'},
          ]
        }
      },
      agentType() {
        return this.$attrs.agentType || 100
      }
    },
    methods: {
      init() {
        for (let p in this.info) {
          this.$set(this.form, p, this.info[p])
        }
        this.preHandle()
      },
      preHandle() {
        this.$set(this.form, 'idFileType', this.form.idCardCopyFileUrl ? '101' : '100');

        this.imgUrl.forEach(item => {
          if (this.form[item]) {
            this[item] = [
              {url: this.fileUrl + this.form[item]}
            ]
          }
        })
      },
      beforeFileUpload(file) {
        return this.validateUploadFile({
          img: true,
          size: 6
        })(file)
      },
      handleRemove(file, fileList, param) {
        this[param] = this.form[param] = fileList;
      },
      handleSuccess(response, file, fileList, param, ref) {
        this[param] = this.form[param] = fileList;
        if (ref) {
          this.$refs[ref].clearValidate();
        }
      },
      getFormData() {
        let form = JSON.parse(JSON.stringify(this.form));
        this.imgUrl.forEach(param => {
          form[param] = this[param][0]
            ? this[param][0].response
              ? this[param][0].response.data
              : this[param][0].url
            : '';
          form[param] = form[param].replace(new RegExp(this.fileUrl, 'g'), '')
        });
        if (form.idFileType == 100) {
          form.idCardCopyFileUrl = '';
          this.form.idCardCopyFileUrl = '';
        } else {
          form.idCardHeadFileUrl = form.idCardEmblemFileUrl = '';
          this.form.idCardHeadFileUrl = form.idCardEmblemFileUrl = '';
        }
        delete form.idFileType;
        return form;
      },
      handleFileRemove(file, param) {
        this.form[param] = this.form[param].filter(item => {
          return item.uid !== file.uid;
        });
        this[param] = this.form[param]
      },
    },
  }
</script>

<style scoped lang="scss">

</style>
