import request from '@/utils/request.js';

/* 员工接口 */
export function getStaffList(data) {
  return request({
    url: '/pmsOperator/listPmsOperator',
    method: 'post',
    data
  })
}

// 递归遍历部门下所有员工
export function getStaffListByDepartment(data) {
  return request({
    url: '/pmsOperator/listByDepartmentIdRecursive',
    method: 'get',
    params: data
  })
}

// 仅查询当前部门下的员工
export function getStaffListByOnlyDepartment(data) {
  return request({
    url: '/pmsOperator/listByDepartmentId',
    method: 'get',
    params: data
  })
}

/** 创建员工 */
export function createStaff(data) {
  return request({
    url: '/pmsOperator/addPmsOperator',
    method: 'post',
    data
  })
}

/** 更新员工 */
export function editStaff(data) {
  return request({
    url: '/pmsOperator/editPmsOperator',
    method: 'post',
    data
  })
}

/** 删除员工 */
export function deleteStaff(data) {
  return request({
    url: '/pmsOperator/deletePmsOperator',
    method: 'get',
    params: data
  })
}

/** 修改员工状态 */
export function changeStaffStatus(data) {
  return request({
    url: "/pmsOperator/changePmsOperatorStatus",
    method: "get",
    params: data
  })
}

/** 查询单个员工信息 */
export function getSingleStaff(data) {
  return request({
    url: "/pmsOperator/getPmsOperatorById",
    method: "get",
    params: data
  })
}

/** 查询未分配部门的员工 */
export function getNoDepartmentStaff() {
  return request({
    url: "/pmsOperator/listWithoutDepartment",
    method: "get"
  })
}

/** 重置员工密码 */
export function resetStaffPwd(data) {
  return request({
    url: "/pmsOperator/resetPmsOperatorPwd",
    method: "get",
    params: data
  })
}

/** 查询销售角色人员 **/
export function getSales() {
  return request({
    url: '/pmsOperator/listSale',
    method: 'post',
  })
}

/* 部门接口 */
export function getDepartmentList() {
  return request({
    url: '/pmsDepartment/listPmsDepartment',
    method: 'get',
  })
}

/** 查询未设置负责人的部门 */
export function getNoneLeaderDepartmentList() {
  return request({
    url: '/pmsDepartment/listWithoutLeader',
    method: 'get',
  })
}

/** 查询单个部门信息 */
export function getSingleDepartment(data) {
  return request({
    url: '/pmsDepartment/getPmsDepartmentById',
    method: 'get',
    params: data
  })
}

/** 新建部门 */
export function createDepartment(data) {
  return request({
    url: '/pmsDepartment/createDepartment',
    method: 'post',
    data
  })
}

/** 编辑部门 */
export function editDepartment(data) {
  return request({
    url: '/pmsDepartment/editDepartment',
    method: 'post',
    data
  })
}

/** 删除部门 */
export function deleteDepartment(data) {
  return request({
    url: '/pmsDepartment/deleteDepartment',
    method: 'get',
    params: data
  })
}

/** 为部门配置负责人 */
export function setDepartmentLeader(data) {
  return request({
    url: '/pmsDepartment/assignLeader',
    method: 'get',
    params: data
  })
}

/* 角色接口 */
export function getRoleList(data) {
  return request({
    url: '/pmsRole/listPmsRole',
    method: 'post',
    data
  })
}

export function getAllRoleList() {
  return request({
    url: '/pmsRole/listAllPmsRoles',
    method: 'get'
  })
}

export function deleteRole(data) {
  return request({
    url: `/pmsRole/deletePmsRole?id=${ data.id }`,
    method: 'post'
  })
}

export function assignPermission(data) {
  return request({
    url: `/pmsRole/assignPmsRoleFunction`,
    method: 'post',
    data
  })
}

export function addPmsRole(data) {
  return request({
    url: '/pmsRole/addPmsRole',
    method: 'post',
    data
  })
}

export function editPmsRole(data) {
  return request({
    url: '/pmsRole/editPmsRole',
    method: 'post',
    data
  })
}

export function listPmsRoleFunction(params) {
  return request({
    url: 'pmsRole/listPmsRoleFunction',
    method: 'get',
    params
  })
}

// MQ消息列表
export function getMQMessage(params) {
  return request({
    url: '/notify/listRmqMessageRecord',
    method: 'get',
    params
  })
}

// MQ补发
export function batchReissue(data) {
  return request({
    url: '/notify/batchReissueMessage',
    method: 'post',
    data,
  })
}

// 后台批量补发mq
export function batchReissueNew(data) {
  return request({
    url: '/notify/batchReissueMessageNew',
    method: 'post',
    data,
  })
}

// 操作日志
export function getOperateLog(data) {
  return request({
    url: '/pmsOperator/listOperateLogPage',
    method: 'post',
    data
  })
}

// 功能导入
export function importFunc(data) {
  return request({
    url: '/pmsFunction/import',
    method: 'post',
    data,
  })
}

// 功能导出
export function exportFunc() {
  return request({
    url: '/pmsFunction/export',
    method: 'get'
  })
}

// 功能删除
export function deleteFunc(data) {
  return request({
    url: '/pmsFunction/deletePmsFunction',
    method: 'post',
    data
  })
}

// 获取功能列表
export function getFuncList() {
  return request({
    url: "/pmsFunction/listAllPmsFunction",
    method: 'get'
  })
}

// 增加功能
export function addFunc(data) {
  return request({
    url: '/pmsFunction/addPmsFunction',
    method: 'post',
    data
  })
}

// 编辑
export function editFunc(data) {
  return request({
    url: '/pmsFunction/editPmsFunction',
    method: 'post',
    data
  })
}


// 黑名单列表
export function listBlackList(data) {
  return request({
    url: '/black/listPage',
    method: 'post',
    data
  })
}

export function addBlack(data) {
  return request({
    url: '/black/add',
    method: 'post',
    data
  })
}

export function updateBlack(data) {
  return request({
    url: '/black/update',
    method: 'post',
    data
  })
}

export function deleteBlack(id) {
  return request({
    url: `/black/delete/${ id }`,
    method: 'post'
  })
}
