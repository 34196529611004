
import dragable from './dragable'

const install = function(Vue) {
  Vue.directive('dragable', dragable)
}

if (window.Vue) {
  Vue.use(install); // eslint-disable-line
}

dragable.install = install
export default dragable
