<template>
  <!--导出dialog-->
  <el-dialog
    :visible.sync="visible"
    :before-close="close"
    title="导出"
    width="800px"
    append-to-body
  >
    <el-form
      ref="departmentForm"
      :model="exportForm"
      label-width="100px"
    >
      <el-form-item
        label="报表类型"
        prop="leaderId"
      >
        <el-radio-group
          v-model="exportForm.tableType"
          @change="changeTableType"
        >
          <el-radio label="100">标准报表</el-radio>
          <el-radio label="101">自定义报表</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="报表字段"
        prop="leaderId"
      >
        <template v-if="exportForm.tableType == 100">
          <span
            v-for="item in $dict(dict)"
            :key="item.code"
            style="padding: 20px"
          >{{item.desc}}</span>
        </template>
        <template v-if="exportForm.tableType == 101 && exportForm.fieldInfoList.length > 0">
          <span
            v-for="item in exportForm.fieldInfoList"
            :key="item.code"
            style="padding: 20px"
          >{{item.desc}}</span>
        </template>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        :loading="loading"
        type="primary"
        @click="submit"
      >确认</el-button>
      <el-button
        type="text"
        @click="close"
      >取消</el-button>
    </div>

    <el-dialog
      :visible.sync="innerVisible"
      title="配置报表字段"
      width="800px"
      append-to-body
    >
      <el-form label-width="100px">
        <el-form-item
          label="选择字段"
          prop="leaderId"
        >
          <el-checkbox-group v-model="fieldInfoListInner">
            <el-checkbox
              class="export-checkbox-item"
              border
              v-for="(item, index) in $dict(dict)"
              :key="item.code"
              :label="index"
            >{{item.desc}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          :loading="loading"
          type="primary"
          @click="innerConfirm"
        >确认</el-button>
        <el-button
          type="text"
          @click="innerVisible = false"
        >取消</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
  export default {
    props: {
      dict: {
        type: String,
        default: '',
      }
    },
    data() {
      return {
        loading: false,
        visible: false,
        innerVisible: false,
        exportForm: {
          tableType: '',
          fieldInfoList: []
        },

        fieldInfoListInner: []
      }
    },
    methods: {
      close() {
        this.exportForm.fieldInfoList = [];
        this.exportForm.tableType = '';
        this.fieldInfoListInner = [];
        this.visible = false;
      },
      changeTableType(val) {
        this.exportForm.fieldInfoList = [];
        this.fieldInfoListInner = [];
        if(val == '101') {
          this.innerVisible = true;
        }
      },
      submit() {
        // 标准报表，传所有字段，自定义报表，将下标转换成数据
        if(this.exportForm.tableType == '100') {
          this.exportForm.fieldInfoList = this.$dict(this.dict);
        }
        this.$emit('confirm', this.exportForm)
        this.close();
      },
      innerConfirm() {
        this.innerVisible = false;
        for (let item of this.fieldInfoListInner) {
          this.exportForm.fieldInfoList.push(this.$dict(this.dict)[item]);
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .export-checkbox-item {
    margin-left: 0 !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }
</style>
