import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/reset.css';
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css
import App from './App'
import store from './store'
import router from './router'

// 过滤器
import filters from '@/filter/filter.js';

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
import '@/icons' // icon
import '@/permission' // permission control
import '@/utils/validate.js'
import format from '@/utils/format.js'
import VPermission from '@/directive/permission'
import VPreview from '@/directive/preview'
import VToggle from '@/directive/toggle'
import VClipboard from '@/directive/clipboard'
import VDragable from '@/directive/el-table'
import VWidth from '@/directive/width'

import datePicker from '@/components/DatePicker/datePicker.vue';
import scrollPanel from '@/components/ScrollPanel'
import warningBlock from '@/components/WarningBlock'
import addressSelect from '@/components/AddressSelect'

import mixin from '@/mixin'

Vue.component('date-picker', datePicker);
Vue.component('scroll-panel', scrollPanel);
Vue.component('warning-block', warningBlock);
Vue.component('address-select', addressSelect);

//install v-permission
VPermission.install(Vue)

// install v-preview
Vue.use(VPreview)
// install v-toggle
Vue.use(VToggle)
// install v-clipboard
Vue.use(VClipboard)
// install v-dragable
Vue.use(VDragable)
Vue.use(VWidth)

// set ElementUI lang to EN
Vue.use(ElementUI)

Vue.mixin(mixin)

Vue.config.productionTip = false

export default new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
Vue.prototype.$format = format;
Vue.prototype.$dict = (dataName) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    return dictionary[dataName]
  } else {
    return []
  }
};


Vue.prototype.$dictFlag = (dataName, dataFlag) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    for (let item of dictionary[dataName]) {
      if (item.flag === String(dataFlag)) {
        return item;
      }
    }
  }
  return {}
};

Vue.prototype.$dictCode = (dataName, dataCode) => {
  let dictionary = store.getters.userData && store.getters.userData.dictionary;
  if (dictionary && dictionary[dataName]) {
    for (let item of dictionary[dataName]) {
      if (item.code === String(dataCode)) {
        return item;
      }
    }
  }
  return {}
}


window.__app_version = APP_VERSION


import bugfree from '@/utils/bugfree'

window.onload = () => {
  setTimeout(() => {
    bugfree()
  })
}

