<template>
  <div class="merchant-info-container">
    <el-form
      label-width="170px"
      ref="form"
      :model="form"
      :disabled="disabled"
    >
      <p class="subTitle">基本信息</p>
      <div
        class="clearfix"
      >
        <el-form-item
          label="商户编号："
          class="inline-form-item">
          <el-input
            v-model="form.mchNo"
            disabled></el-input>
        </el-form-item>
        <el-form-item
          label="商户名称："
          class="inline-form-item">
          <el-input v-model="form.mchName"></el-input>
        </el-form-item>
        <el-form-item
          label="销售："
          class="inline-form-item">
          <el-select
            v-if="!onlyShow"
            v-model="form.salerId"
            clearable
            disabled
          >
            <el-option
              v-for="item in staffList"
              :key="item.id"
              :value="item.id"
              :label="item.realName"
            ></el-option>
          </el-select>
          <el-tag
            type="info"
            v-else>
            {{ form.salerName }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="商户类型："
          class="inline-form-item">
          <el-tag type="info">
            {{ $dictCode('MerchantTypeEnum', form.merchantType).desc }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="品牌名称："
          class="inline-form-item">
          <el-input
            clearable
            v-model="form.branchName"></el-input>
        </el-form-item>
        <el-form-item
          label="商户状态："
          class="inline-form-item">
          <el-tag type="info">
            {{ $dictCode('MchStatusEnum', form.mchStatus).desc }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="合伙人编号："
          class="inline-form-item"
          v-if="isMerchant">
          <el-tag type="info">
            {{ form.agentNo }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="合伙人名称："
          class="inline-form-item"
          v-if="isMerchant">
          <el-tag type="info">
            {{ form.agentName }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="资质状态："
          class="inline-form-item">
          <el-tag type="info">
            {{ $dictCode('AuthStatusEnum', form.authStatus).desc }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="邮箱："
          class="inline-form-item">
          <el-input
            clearable
            v-model="form.contactEmail"></el-input>
        </el-form-item>
        <el-form-item
          label="补充说明："
          class="inline-form-item">
          <el-input
            type="textarea"
            v-model="form.remark"></el-input>
        </el-form-item>
        <div
          class="clearfix"
          v-if="form.merchantType === 101">
          <el-form-item
            class="inline-form-item"
            label="方形LOGO：">
            <file-upload
              :url.sync="form.platformLogoUrl"
              :max="1">
              <template v-slot:tip-append>最多 1 张，单个图片不超过 1MB。
                <div>建议尺寸：200*200px</div>
              </template>
            </file-upload>
          </el-form-item>
          <el-form-item
            class="inline-form-item"
            label="横版LOGO：">
            <file-upload
              :url.sync="form.loginLogoUrl"
              :max="1">
              <template v-slot:tip-append>最多 1 张，单个图片不超过 1MB。
                <div>建议尺寸：200*60px</div>
              </template>
            </file-upload>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-form
      disabled
      :model="systemInfo"
      label-width="170px"
    >
      <p class="subTitle">系统信息</p>
      <div
        class="clearfix"
      >
        <el-form-item
          label="创建人："
          class="inline-form-item">
          <el-input
            v-model="systemInfo.founder"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="创建时间："
          class="inline-form-item">
          <el-input
            v-model="systemInfo.createDate"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="成交状态："
          class="inline-form-item">
          <el-tag type="info">
            {{ systemInfo.status == 0 ? '未成交' : systemInfo.status == 1 ? '成交' : '' }}
          </el-tag>
        </el-form-item>
        <el-form-item
          label="最后修改人："
          class="inline-form-item">
          <el-input
            v-model="systemInfo.lastModifyOperator"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="最后跟进时间："
          class="inline-form-item">
          <el-input
            v-model="systemInfo.lastModifyDate"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload/index.vue";

export default {
  name: 'base-info',
  components: { FileUpload },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    systemInfo: {
      type: Object,
      default: () => ({})
    },
    staffList: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onlyShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: {}, // 组件内部表单备份
    }
  },
  computed: {
    isMerchant() {
      return this.form.mchNo ? this.form.mchNo.startsWith('M') : false
    }
  },
  watch: {
    info: {
      handler(val) {
        for (let p in val) {
          this.$set(this.form, p, val[p])
        }
      }
    }
  },
  methods: {
    init() {
      for (let p in this.info) {
        this.$set(this.form, p, this.info[p])
      }
    }
  }
}
</script>

<style
  scoped
  lang="scss">
.el-tag {
  vertical-align: middle;
}
</style>
