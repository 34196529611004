<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(<svg-icon icon-class={icon}/>)
    }

    if (title) {
      vnodes.push(<span slot='title' class={title.length > 8 ? 'long' : ''}>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style lang="scss" scoped>
  .long {
    transition: all .15s linear;

    &:hover {
      transform: translate(-28px, -50%) !important;
    }
  }
</style>
