import {buildMenu, buildRoute, constantRoutes} from '@/router'

const permission = {
  state: {
    routes: JSON.parse(sessionStorage.getItem('routes')) || [],
    menus: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.routes = constantRoutes.concat(routes)
      sessionStorage.setItem('routes', JSON.stringify(state.routes))
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus;
    }
  },
  actions: {
    GenerateRoutesAndMenus({commit}, {functions}) {
      return new Promise(resolve => {
        let accessedMenus = buildMenu(functions)
        let accessedRoutes = buildRoute(accessedMenus)
        commit('SET_ROUTES', accessedRoutes)
        commit('SET_MENUS', accessedMenus)
        resolve({accessedRoutes, accessedMenus})
      })
    },
    ResetRoutesAndMenus({commit}) {
      return new Promise(resolve => {
        commit('SET_ROUTES', [])
        commit('SET_MENUS', [])
        resolve({accessedRoutes: [], accessedMenus: []})
      })
    }

  }
}

export default permission
