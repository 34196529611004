<template>
  <div class="merchant-info-container">
    <div class="record-list">
      <div
        class="record-item"
        v-for="(item, index) in form"
        :key="index"
      >
        <div class="record-time">
          {{ item.createTime }}
        </div>
        <div class="record-info">
          <div class="record-info-operator">
            {{ item.operateName }}
          </div>
          <template
            v-for="(act, key) in item.action"
          >
            <div class="record-info-detail" :key="key">
              <el-tag :type="getTagType(act.operate)">
                {{ $dictCode('OperationEnum', act.operate).desc }}
              </el-tag>
              {{ act.objectName }}
              <span v-if="act.originalValue || act.targetValue">：</span>
              <parse-value
                :ob-key="item.objectKey"
                :value="act.originalValue"
              ></parse-value>
              <i class="el-icon-right" v-if="act.originalValue && act.targetValue"></i>
              <parse-value
                :ob-key="item.objectKey"
                :value="act.targetValue"
              ></parse-value>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import parseValue from '@/components/ParseValue'
  export default {
    name: 'record-info',
    components: {
      parseValue,
    },
    props: {
      info: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        form: [],
      }
    },
    watch: {
      info: {
        immediate: true,
        handler(val) {
          this.form = [];
          let start = '';
          for (let p in val) {
            const action = {
              objectName: val[p]['objectName'],
              originalValue: val[p]['originalValue'],
              targetValue: val[p]['targetValue'],
              operate: val[p]['operate'],
            };
            if (val[p].createTime !== start) {
              this.form.push({
                ...val[p],
                action: [
                  action
                ]
              });
              start = val[p]['createTime'];
            } else {
              this.form[this.form.length - 1].action.push(action);
            }
          }
        }
      }
    },
    methods: {
      getTagType(type) {
        return type == 0 ? 'success' : (type == 1 ? 'danger' : '')
      }
    },
  }
</script>

<style scoped lang="scss">
  .record-list {
    .record-item {
      position: relative;
      margin-left: 200px;
      padding-bottom: 16px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 0;
        transform: translateX(-120%);
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background: #ddd;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        bottom: -3px;
        left: 0;
        transform: translateX(-400%);
        width: 2px;
        background: #ddd;
      }
      &:last-child::after {
        background: transparent;
      }
    }

    .record-time {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-130%);
    }
    .record-info {
      margin-left: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid #ddd;
    }
    .record-info-detail {
      margin-top: 8px;
    }

  }
</style>
