<template>
  <div class="merchant-info-container">
    <div
      class="clearfix"
      style="padding: 0 16px;"
    >
      <span
        class="float-l"
        style="line-height: 40px;"
      >共 {{ form1.length + form2.length }} 条</span>
      <div class="float-r">
        <el-button
          type="text"
          @click="goToQuote"
        >添加
        </el-button>
      </div>
    </div>
    <template v-if="form1.length">
      <p class="subTitle">仕加云产品报价单</p>
      <el-table
        :data="form1"
        :span-method="spanMethod"
      >
        <el-table-column label="产品">
          <el-table-column label="产品名称">
            <template v-slot="{row}">
              {{ row.productNo }} <br>
              {{ row.productName }}
            </template>
          </el-table-column>
          <el-table-column label="供应商">
            <template v-slot="{row}">
              {{ row.mainstayMchNo }} <br>
              {{ row.mainstayMchName }}
            </template>
          </el-table-column>
          <el-table-column label="岗位类别">
            <template v-slot="{row}">
              <el-tooltip
                v-for="(item, index) in row.positionNameList"
                :key="index"
                :content="item">
                <el-tag type="info">
                  {{ item }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="发票类目"
            prop="invoiceCategoryList">
            <template v-slot="{row}">
              <el-tooltip
                v-for="(item, index) in row.invoiceCategoryList"
                :key="index"
                :content="item.invoiceCategoryName"
              >
                <el-tag type="info">
                  {{ item.invoiceCategoryName }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template v-slot:header>
            报价
            <el-tooltip content="如有阶梯计费、单笔金额等特殊计费需求，可使用「编辑计费」功能">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
          <el-table-column label="公式类型">
            <template v-slot="{row}">
              {{ $dictCode('FormulaEnum', row.quoteData.formulaType).desc }}
            </template>
          </el-table-column>
          <el-table-column label="固定金额">
            <template v-slot="{row}">
              <div v-if="row.quoteData.formulaType == 1 || row.quoteData.formulaType == 2">
                {{ row.quoteData.fixedFee }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="比例">
            <template v-slot="{row}">
              <div v-if="row.quoteData.formulaType == 0 || row.quoteData.formulaType == 2">
                {{ row.quoteData.rate }} %
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="特殊计费规则">
          <template v-slot="{row}">
            <div
              v-for="(item, index) in row.ruleParam"
              :key="index"
            >
              {{ $dictCode('ProductFeeSpecialRuleTypeEnum', item.specialRuleType).desc }}
              {{ $dictCode('CompareTypeEnum', item.compareType).desc }}
              {{ item.value }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template v-slot="{row}">
            <el-tag :type="row.status == 100 ? '' : 'info'">
              {{ $dictCode('QuoteStatusEnum', row.status).desc }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="operate"
          v-if="!isPop"
        >
          <template v-slot="{row}">
            <el-button
              type="text"
              @click="editQuote(row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              v-if="row.status == 100"
              @click="deleteQuote(row)"
              class="red-btn"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="form2.length">
      <p class="subTitle">创客汇产品报价单</p>
      <el-table :data="form2">
        <el-table-column label="产品">
          <el-table-column label="产品名称">
            <template v-slot="{row}">
              {{ row.productNo }} <br>
              {{ row.productName }}
            </template>
          </el-table-column>
          <el-table-column label="供应商">
            <template v-slot="{row}">
              {{ row.mainstayMchNo }} <br>
              {{ row.mainstayMchName }}
            </template>
          </el-table-column>
          <el-table-column label="岗位类别">
            <template v-slot="{row}">
              <el-tooltip
                v-for="(item, index) in row.positionNameList"
                :key="index"
                :content="item"
              >
                <el-tag type="info">
                  {{ item }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="发票类目"
            prop="invoiceCategoryList">
            <template v-slot="{row}">
              <el-tooltip
                v-for="(item, index) in row.invoiceCategoryList"
                :key="index"
                :content="item.invoiceCategoryName"
              >
                <el-tag type="info">
                  {{ item.invoiceCategoryName }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="个税">
          <el-table-column
            label="个税类型"
            prop="taxTypeDesc"
          >
            <template v-slot="{row, column}">
              {{ row.merchantCkhQuote[column.property] }}
            </template>
          </el-table-column>
          <el-table-column label="个税承担方">
            <template v-slot="{row}">
              {{ $dictCode('TaxPayerEnum', row.merchantCkhQuote.taxPayer).desc }}
            </template>
          </el-table-column>
          <el-table-column label="增值税税率 %">
            <template v-slot="{row}">
              {{ row.merchantCkhQuote.addedTaxRatePct }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="服务费比例 %"
          prop="serviceFeeRatePct"
        >
          <template v-slot="{row, column}">
            {{ row.merchantCkhQuote[column.property] }}
          </template>
        </el-table-column>
        <el-table-column
          label="服务费结算模式"
          prop="balancedMode"
        >
          <template v-slot="{row, column}">
            {{ $dictCode('BalancedEnum', row.merchantCkhQuote[column.property]).desc }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template v-slot="{row}">
            <el-tag :type="row.status == 100 ? '' : 'info'">
              {{ $dictCode('QuoteStatusEnum', row.status).desc }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="operate"
          v-if="!isPop"
        >
          <template v-slot="{row}">
            <el-button
              type="text"
              @click="editQuote(row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              v-if="row.status == 100"
              @click="deleteQuote(row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </div>
</template>

<script>
import { toPromise } from '@/utils'

export default {
  props: {
    info: {
      type: Array,
      default: () => [],
    },
    isPop: {
      type: Boolean,
      default: false,
    },
    baseInfo: {
      type: Object
    },
    closePop: Function,
  },
  data() {
    return {
      form1: [], // zxh报价单
      form2: [], // ckh报价单
      editRow: {},
    }
  },
  watch: {
    info: {
      immediate: true,
      handler(val) {
        this.preHandle();
      }
    }
  },
  methods: {
    preHandle() {
      this.form1 = [];
      this.form2 = [];
      this.info.forEach(item => {
        if (item.productNo == 'ZXH') {
          const length = item.quoteRateList ? item.quoteRateList.length : 0;
          if (item.quoteRateList) {
            item.quoteRateList.forEach((quote, index) => {
              quote.ruleParam = JSON.parse(quote.ruleParam);
              this.form1.push({
                ...item,
                quoteData: quote,
                rowspan: index == 0 ? length : null
              })
            })
          }
        } else {
          this.form2.push(item)
        }
      })
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      let ret = {};
      if (columnIndex == 0 || column.property == 'operate') {
        // 合并第一列
        if (row.rowspan !== null) {
          ret = {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else {
          ret = {
            rowspan: 0,
            colspan: 0,
          }
        }
        return ret;
      }
    },
    async goToQuote() {
      if (this.baseInfo.authStatus != 100) {
        const [err] = await toPromise(this.$confirm('此商户未完成主体信息认证，请完成认证后重试', '无法添加', {
          type: 'warning',
          confirmButtonText: '去认证',
          cancelButtonText: '稍后'
        }))
        if (err) return;
        sessionStorage.setItem('merchantInfo', JSON.stringify(this.baseInfo))
        this.$router.push({
          path: '/merchant/merchantAuth',
          query: {
            mchNo: this.baseInfo.mchNo
          }
        })
      } else {
        if (this.isPop) {
          this.closePop()
        }
        this.$emit('route-change', {
          path: '/quote/mchQuoteForm'
        })
      }
    },
    editQuote(row) {
      let quoteInfo = JSON.parse(JSON.stringify(row));
      delete quoteInfo.quoteData;
      this.$emit('route-change', {
        path: '/quote/mchQuoteForm',
        query: {
          actionType: 'EDIT'
        }
      }, {
        quoteInfo
      })
    },
    deleteQuote(row) {
      row = JSON.parse(JSON.stringify(row));
      delete row.rowspan;
      this.editRow = row;
      this.$emit('del-quote')
    },
    getFormData() {
      delete this.editRow.quoteData;
      return { ...this.editRow, quoteId: this.editRow.id };
    }
  },
}
</script>

<style
  scoped
  lang="scss"
>
</style>
