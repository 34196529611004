/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * 将单位为秒的时长格式化
 * @param {number} second 秒数
 */
export function formatDuration(second) {
  if (second === 0) return "0s";
  if (!second) return "";
  let cur = second;
  let res = "";
  let bases = [60, 60, 24];
  let units = ["s", "mins", "h"];
  for (let i = 0; i < bases.length && cur != 0; ++i) {
    let mod = cur % bases[i]
    if (mod !== 0) {
      res = mod + units[i] + " " + res;
    }
    cur = (cur - mod) / bases[i];
  }
  if (cur != 0) {
    res = cur + "d" + " " + res;
  }
  return res;
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

/**
 * 扁平数组转树结构
 * @param list
 * @param topParentId  最顶级父节点id编号
 * @returns {[]}
 */
export function convert(list, topParentId = 0) {
  const res = [];
  const map = list.reduce((res, v) => (res[v.id] = v, res), {})
  for (const item of list) {
    if (item.parentId === topParentId) {
      res.push(item)
      continue
    }
    if (item.parentId in map) {
      const parent = map[item.parentId]
      parent.children = parent.children || []
      parent.children.push(item)
    }
  }
  return res
}

export function getCodeValue(obj, code, val, targetCode) {
  for (let item of obj) {
    if (item[code] == val) {
      return item[targetCode];
    }
  }
  return '';
}


// 多重数组拍平
export function flatten(list, result = []) {
  list.forEach(i => {
    result.push(i)
    if (i.children) {
      flatten(i.children, result)
    }
  })
}


// 防抖
export function debounce(func, time) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      func.apply(this, arguments)
    }, time);
  }
}

// 节流
export function throttle(func, time) {
  let timer = null;
  return function () {
    if (!timer) {
      func.apply(this, arguments);
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
      }, time);
    }
  }
}

/**
 * 自动计算下一个子节点number，用于功能列表的新增子项
 * @param {string} pNum 父节点number
 * @param {string} lastNum 最近一个子节点的number
 * @returns {string}
 */
export function autoNext(pNum, lastNum) {
  if (pNum === undefined) {
    return ''
  }
  if (lastNum === undefined || lastNum === false) {
    return pNum + '01';
  }
  const parentReg = new RegExp(`^(?:${pNum})(\\d.)$`, 'g');
  let lastNumber = parentReg.exec(lastNum)
  let newNumber;
  lastNumber = lastNumber ? lastNumber[1] : (/\d{2}$/.exec(lastNum))[0];
  newNumber = Number(lastNumber) + 1;
  newNumber = (newNumber > 9 ? newNumber : '0' + newNumber);
  newNumber = pNum + newNumber;
  return newNumber;
}


export function downLoad(url) {
  let a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

// 深拷贝
// 包含数组和对象的情况
export function deepClone(target, src) {
  for (let p in src) {
    if (Array.isArray(src[p])) {
      target[p] = [];
      deepClone(target[p], src[p])
    } else if (typeof src[p] == 'object') {
      if (src[p] === null) {
        target[p] = src[p]
      } else {
        target[p] = Object.create(null);
        deepClone(target[p], src[p])
      }
    } else {
      target[p] = src[p]
    }
  }
}

// null 或 undefined 处理
export function clearVoid(obj) {
  for (let p in obj) {
    if (obj[p] === null || obj[p] === undefined) {
      delete obj[p]
    }
  }
}

export function getValType(val) {
  const fullType = Object.prototype.toString.call(val);
  return fullType.slice(8, fullType.length - 1);
}

// 对比两个值
export function isEqual(oldVal, newVal) {
  if (oldVal == newVal) return true; // 数字 String 和Number 相等
  let oldType = getValType(oldVal);
  let newType = getValType(newVal);
  if (oldType !== newType) {
    return false
  } else {
    if (oldType === 'String' || oldType === 'Number') {
      return false
    } else {
      if (oldType === 'Array') {
        let length = Math.max(oldVal.length, newVal.length);
        let result = true;
        for (let i = 0; i < length; i++) {
          result = isEqual(oldVal[i], newVal[i])
          if (!result) break;
        }
        return result;
      } else if (oldType === 'Object') {
        let oldKeys = Object.keys(oldVal);
        let newKeys = Object.keys(newVal);
        let keys = oldKeys.length > newKeys.length ? oldKeys : newKeys;
        let result = true;
        for (let i = 0; i < keys.length; i++) {
          result = isEqual(oldVal[keys[i]], newVal[keys[i]]);
          if (!result) break;
        }
        return result;
      }
    }
  }
}

// 全角转半角
export function toCDB(str) {
  var tmp = "";
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp
}


// queryToString
export function queryToString(query) {
  let str = '';
  for (let p in query) {
    if (query[p] !== undefined && query[p] !== null) {
      str += `${p}=${query[p]}&`
    }
  }
  return str.slice(0, -1)
}

// toPromise
// let [err, result] = await toPromise(promise)
export function toPromise(promise) {
  return new Promise((resolve, reject) => {
    promise
      .then(res => {
        resolve([null, res])
      })
      .catch(error => {
        if (error === false) {
          error = new Error('Promise Handle Error')
        }
        resolve([error, null])
      })
  })
}
