export const pathNameMap = {
  'config:dictionary:view': 'DataDictionaryList',
  'timer:schedule:view': 'ScheduleJobList',
  'config:globallock:view': 'GlobalLockList',

  /* 商户 */
  'sale:merchant:view': 'MerchantManage',
  'merchantManagement:agreement:view': 'AgreementManage',
  'employer:operator:view': 'EmpOperatorList',
  'employer:staff:view': 'EmpStaffList',
  'employer:function:view': 'EmpFunctionList',
  'employer:role:view': 'EmpPresetRoleList',

  /* 供应商 */
  'supplier:operator:view': 'SupOperatorList',
  'supplier:staff:view': 'SupStaffList',
  'supplier:function:view': 'SupFunctionList',
  'supplier:role:view': 'SupPresetRoleList',

  /* 合伙人 */
  'agent:list:view': 'AgentManage',
  'agent:operator:view': 'AgentOperatorList',
  'agent:staff:view': 'AgentStaffList',
  'agent:function:view': 'AgentFunctionList',
  'agent:statistic:view': 'AgentStatistic',
  'agent:role:view': 'AgentPresetRoleList',

  /* 系统管理 */
  'pms:operator:view': 'PmsStaffManagement',
  'pms:department:view': 'PmsDepartmentManagement',
  'pms:role:view': 'PmsRoleManagement',
  'pms:function:view': 'PmsFunctionManagement',
  'pms:operateLog:view': 'PmsOperateLogList',
  'pms:notify:mq:view': 'PmsMqNotify',
  'pms:process:view': 'PmsProcessManage',

  /*待办*/
  'waitingHandle:myProcess:view': 'PmsFlowManage',

  /* 业务 */
  'businessManage:invoiceCategory:view': 'PmsInvoiceCategoryList',
  'businessManage:workCategory:view': 'PmsWorkCategoryList',
  'businessManage:industryType:view': 'PmsIndustryTypeList',
  'business:agreement:view': 'PmsAgreementTemplate',
  'fee:product:list': 'PmsProductManagement',
  'fee:vendor:list': 'PmsMainstayManagement',
  'fee:vendorFeeManager:list': 'PmsMainstayCost',
  'fee:mchProdManager:list': 'PmsMerchantProductManagement',
  'fee:mchFeeManager:list': 'PmsMerchantProductCostManagement',
  'business:levy:view': 'PmsLevyTable',
  'business:payChannel:view': 'PmsPayChannel',
  'mainstay:payAccount:view': 'PmsMainstayPayAccount',
  'merchant:payAccount:view': 'PmsMerchantPayAccount',
  'channel:list:view': 'PmsChannelRecord',
  'fee:agentProduct:list': 'PmsAgentProduct',
  'fee:agentFeeManager:list': 'PmsAgentCost',

  /* 销售 */
  'sales:salesCostRule:view': 'PmsSaleCostManagement',

  /* 风控 */
  'riskcontrol:rule': 'PmsRiskControl',
  'riskcontrol:pending:view': 'PmsRiskHang',
  'riskcontrol:resolve:view': 'PmsRiskResolve',

  /* 交易中心 */
  'trade:order:roster:view': 'PmsTradeRoster',
  'trade:order:detail:view': 'PmsTradeDetail',
  'trade:order:record:view': 'PmsTradeRecord',
  'trade:order:invoice:view': 'PmsTradeInvoice',
  "trade:order:receipt": 'PmsTradeReceipt',

  'account:charge:list': 'PmsChargeRecord',
  'account:withdraw:list': 'PmsWithdrawRecord',

  /* 计费订单 */
  'fee:merchantFeeOrder:list': 'PmsMerchantFeeOrder',
  'fee:vendorFeeOrder:list': 'PmsVendorFeeOrder',
  'fee:salesFeeOrder:list': 'PmsSalesFeeOrder',
  'fee:agentFeeOrder:list': 'PmsAgentOrder',

  /* 财务 */
  'account:invoice:view': 'PmsAccountInvoiceList',
  'account:invoice:processPending:view': 'PmsAccountInvoiceProcessPendingList',
  'account:invoice:processDetail:view': 'PmsAccountInvoiceProcessDetailList',
  'account:invoice:result:view': 'PmsAccountInvoiceProcessResultList',

  /* 开票 */
  'invoice:view': 'PmsIssueRecord',

  /* 结算 */
  'agent:settle:view': 'PmsAgentSettle',

  /* 电子签约 */
  'sign:signRecord:view': 'PmsSignInfo',
}
