<template>
  <div
    :class="classObj"
    class="app-wrapper">
    <div
      v-if="device==='mobile'&&sidebarCollapse"
      class="drawer-bg"
      @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div
      class="main-container"
      :style="sidebarStyle">
      <div
        class="fixed-header"
        :style="navbarStyle"
      >
        <navbar />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapGetters } from 'vuex'
import { sidebarWidth, submenuWidth } from "@/settings";

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapGetters(['sidebarCollapse']),
    sidebarCollapse() {
      return this.$store.state.app.sidebarCollapse
    },
    device() {
      return this.$store.state.app.device
    },
    classObj() {
      return {
        hideSidebar: !this.sidebarCollapse,
        openSidebar: this.sidebarCollapse,
        withoutAnimation: false,
        mobile: this.device === 'mobile'
      }
    },
    sidebarStyle() {
      return { marginLeft: `${sidebarWidth + (!this.sidebarCollapse ? 0 : submenuWidth)}px` }
    },
    navbarStyle() {
      return {
        left: `${sidebarWidth + (!this.sidebarCollapse ? 0 : submenuWidth)}px`
      }
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('toggleSideBar', false)
    }
  }
}
</script>

<style
  lang="scss"
  scoped>
@import "~@/styles/variables.scss";

.app-wrapper {
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .sidebar-container {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: auto;
    z-index: 1001;
  }

  .main-container {
    flex: 1 0 980px;
    width: 0;
    margin-top: 50px;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  transition: left 0.28s;
}
</style>
