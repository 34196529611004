<template>
  <el-drawer
    :visible="visible"
    :before-close="handleClose"
    v-bind="$attrs"
    ref="drawer"
  >
    <slot></slot>
  </el-drawer>

</template>

<script>
  export default {
    props: {
      visible: Boolean
    },
    computed: {
      drawer() {
        return this.$refs.drawer;
      }
    },
    methods: {
      handleClose(done) {
        this.$emit('close');
        done();
      },
      close() {
        this.drawer.closeDrawer()
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
