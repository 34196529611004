import store from '@/store'

const {body} = document
const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
  watch: {
    $route: {
      async handler(val) {
        if (this.device === 'mobile' && this.sidebarCollapse) {
          store.dispatch('toggleSideBar', false)
        } else if (this.device === 'desktop') {
          if (val.path == '/index' || val.path == '/' || !this.$store.getters.rightMenu) {
            store.dispatch('toggleSideBar', false)
          } else {
            store.dispatch('toggleSideBar', true)
          }
        }
        await this.$nextTick();
        let menus = this.$store.getters.menus;
        let pagePath = '/' + val.path.split('/')[1];
        let currentPageObj = menus.find(item => {
          if (item.path == pagePath) {
            return item;
          }
        });
        if (currentPageObj) {
          this.$store.dispatch('setRightMenu', currentPageObj.children || []);
        }
      },
      immediate: true
    }
  },
  data() {
    return {};
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      store.dispatch('toggleDevice', 'mobile')
      store.dispatch('toggleSideBar', false)
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        store.dispatch('toggleDevice', isMobile ? 'mobile' : 'desktop')

        if (isMobile) {
          store.dispatch('toggleSideBar', false)
        }
      }
    }
  }
}
