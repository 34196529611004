<template>
  <div class="self-date-picker">
    <template v-if="picker == 'range'">
      <el-date-picker
        v-model="timeRangeSelf"
        :type="type"
        :clearable="clearable"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :value-format="valueFormat"
        :default-time="defaultTime"
        :unlink-panels="true"
        :picker-options="useOption && timeOption"
        @change="changeTime"
      >
      </el-date-picker>
    </template>
    <template v-else-if="picker == 'separate'">
      <el-date-picker
        v-model="timeRangeSelf[0]"
        placeholder="选择开始日期"
        :value-format="valueFormat"
        :type="type"
        :disabled="startDisable"
        :picker-options="useOption && timeOption"
        @change="handleDateChange($event, 0)"
      ></el-date-picker>
      至
      <el-date-picker
        v-model="timeRangeSelf[1]"
        :disabled="endDisable"
        :picker-options="useOption && timeOption"
        :type="type"
        :value-format="valueFormat"
        placeholder="选择结束日期"
        @change="handleDateChange($event, 1)"
      ></el-date-picker>
    </template>
    <el-radio-group
      class="date-picker-btn-group"
      v-if="isShowFastTime"
      v-model="fastTimeSelf"
      @change="selectFastTime"
    >
      <el-radio
        type="text"
        label="today"
        v-if="fastTimeArr.indexOf('today') > -1"
      >今
      </el-radio
      >
      <el-radio
        type="text"
        label="yesterday"
        v-if="fastTimeArr.indexOf('yesterday') > -1"
      >昨
      </el-radio
      >
      <el-radio
        type="text"
        label="sevenDay"
        v-if="fastTimeArr.indexOf('sevenDay') > -1"
      >近7天
      </el-radio
      >
      <el-radio
        type="text"
        label="thirtyDay"
        v-if="fastTimeArr.indexOf('thirtyDay') > -1"
      >近30日
      </el-radio
      >
      <el-radio
        type="text"
        label="month"
        v-if="fastTimeArr.indexOf('month') > -1"
      >本月
      </el-radio
      >
      <el-radio
        type="text"
        label="lastMonth"
        v-if="fastTimeArr.indexOf('lastMonth') > -1"
      >上月
      </el-radio
      >
      <el-radio
        type="text"
        label="year"
        v-if="fastTimeArr.indexOf('year') > -1"
      >{{ new Date().getFullYear() }}年
      </el-radio
      >
      <el-radio
        type="text"
        label="yearDay"
        v-if="fastTimeArr.indexOf('yearDay') > -1"
      >近一年
      </el-radio
      >
    </el-radio-group>
    <div
      class="picker-slot-append"
      v-if="$slots.append">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DatePicker",
  model: {
    prop: "timeRange",
    event: "change",
  },
  props: {
    type: {
      type: String,
      default: "datetimerange",
    },
    isShowFastTime: {
      type: Boolean,
      default: true,
    },
    timeRange: {
      // 时间范围，优先级最低
      type: Array,
      default: () => [],
    },
    fastTime: {
      // 快捷选择时间，优先级最高
      type: String,
      default: "",
    },
    fastTimeArr: {
      // 快捷选择时间列表，优先级中等
      type: Array,
      default: () => [
        "today",
        "yesterday",
        "sevenDay",
        "thirtyDay",
        "month",
        "lastMonth",
      ],
    },
    timeDisable: {
      type: Number,
      default: 0,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    picker: {
      type: String,
      default: "range",
    },
    startDisable: {
      type: Boolean,
      default: false,
    },
    endDisable: {
      type: Boolean,
      default: false,
    },
    useOption: {
      type: Boolean,
      default: true,
    },
    startTime: {
      type: String,
      default: "",
    },
    endTime: {
      type: String,
      default: "",
    },
    autoFix: {
      type: Boolean, // 月份选择器是否补充日期时间
      default: true,
    },
    dayjsFormat: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fastTimeSelf: this.fastTime,
      timeRangeSelf: this.timeRange,
    };
  },
  computed: {
    timeOption({ timeDisable }) {
      return {
        disabledDate(date) {
          return date > dayjs().endOf("day").add(timeDisable, "day");
        },
      };
    },
    dayjsTimeFormat() {
      if (this.dayjsFormat) return this.dayjsFormat
      const map = {
        daterange: "YYYY-MM-DD",
        date: "YYYY-MM-DD",
        datetimerange: "YYYY-MM-DD hh:mm:ss",
        datetime: "YYYY-MM-DD hh:mm:ss",
        monthrange: "YYYY-MM",
        month: "YYYY-MM",
      };
      return map[this.type];
    },
    valueFormat() {
      const map = {
        daterange: "yyyy-MM-dd",
        date: "yyyy-MM-dd",
        datetimerange: "yyyy-MM-dd HH:mm:ss",
        datetime: "yyyy-MM-dd HH:mm:ss",
        monthrange: "yyyy-MM",
        month: "yyyy-MM",
      };
      return map[this.type];
    },
    defaultTime() {
      return /time/i.test(this.type) ? ["00:00:00", "23:59:59"] : ["", ""];
    },
  },
  mounted() {
    this.selectFastTime();
  },
  methods: {
    selectFastTime() {
      switch (this.fastTimeSelf) {
        case "today":
          this.timeRangeSelf = [
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
          ];
          break;
        case "yesterday":
          this.timeRangeSelf = [
            dayjs().add(-1, "day").format(this.dayjsTimeFormat),
            dayjs().add(-1, "day").format(this.dayjsTimeFormat),
          ];
          break;
        case "sevenDay":
          this.timeRangeSelf = [
            dayjs().add(-6, "day").format(this.dayjsTimeFormat),
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
          ];
          break;
        case "month":
          this.timeRangeSelf = [
            dayjs().startOf("month").format(this.dayjsTimeFormat),
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
          ];
          break;
        case "thirtyDay":
          this.timeRangeSelf = [
            dayjs().add(-1, "month").format(this.dayjsTimeFormat),
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
          ];
          break;
        case "lastMonth":
          this.timeRangeSelf = [
            dayjs()
              .add(-1, "month")
              .startOf("month")
              .format(this.dayjsTimeFormat),
            dayjs()
              .add(-1, "month")
              .endOf("month")
              .format(this.dayjsTimeFormat),
          ];
          break;
        case "year":
          this.timeRangeSelf = [
            dayjs().startOf("year").format(this.dayjsTimeFormat),
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
          ];
          break;
        case "yearDay":
          this.timeRangeSelf = [
            dayjs().add(-1, "year").format(this.dayjsTimeFormat),
            dayjs().add(0, "day").format(this.dayjsTimeFormat),
          ];
          break;
        default:
          if (this.timeRange) {
            this.timeRangeSelf = this.timeRange;
          } else {
            // DO NONE
          }
          if (this.startTime) {
            this.timeRangeSelf.splice(0, 1, this.startTime);
          }
          if (this.endTime) {
            this.timeRangeSelf.splice(1, 1, this.endTime);
          }
      }
      if (
        this.timeDisable !== 0 &&
        this.fastTimeSelf &&
        this.fastTimeSelf !== "yesterday"
      ) {
        // 月份选择器开始月不用减
        if (this.fastTimeSelf !== "month" && this.fastTimeSelf !== "lastMonth") {
          this.timeRangeSelf[0] = dayjs(this.timeRangeSelf[0])
            .add(this.timeDisable, "day")
            .format(this.dayjsTimeFormat);
          this.timeRangeSelf[1] = dayjs(this.timeRangeSelf[1])
            .add(this.timeDisable, "day")
            .format(this.dayjsTimeFormat);
        }
      }
      // 时间控件需要重置时分秒
      if (
        (this.type == "datetimerange" || this.type == "datetime") &&
        this.timeRangeSelf.length > 0
      ) {
        this.timeRangeSelf[0] = this.timeRangeSelf[0]
          ? this.timeRangeSelf[0].split(" ")[0] + " 00:00:00"
          : "";
        this.timeRangeSelf[1] = this.timeRangeSelf[1]
          ? this.timeRangeSelf[1].split(" ")[0] + " 23:59:59"
          : "";
      }
      this.$emit("update:startTime", this.timeRangeSelf[0]);
      this.$emit("update:endTime", this.timeRangeSelf[1]);
      this.$emit("change", this.timeRangeSelf);
    },
    changeTime(val, isChooseFastTime) {
      if (!isChooseFastTime) {
        this.fastTimeSelf = "";
      }
      if (val) {
        val.forEach((item, index) => {
          item && this.timeRangeSelf.splice(index, 1, item || "");
        });
      } else {
        this.timeRangeSelf = [];
      }
      // 月份控件
      if (this.type.includes("month")) {
        this.timeRangeSelf[0] &&
        this.timeRangeSelf.splice(
          0,
          1,
          this.formatMonthDate(this.timeRangeSelf[0], 0)
        );
        this.timeRangeSelf[1] &&
        this.timeRangeSelf.splice(
          1,
          1,
          this.formatMonthDate(this.timeRangeSelf[1], 1)
        );
      }
      this.$emit("update:startTime", this.timeRangeSelf[0]);
      this.$emit("update:endTime", this.timeRangeSelf[1]);
      this.$emit("change", this.timeRangeSelf); // 父组件改变某一时间值，不触发el-date-picker的change事件，参见trade/order/detail.vue
    },
    resetTime() {
      this.fastTimeSelf = this.fastTime || this.fastTimeArr[0];
      this.selectFastTime();
    },
    clearTime() {
      this.timeRangeSelf = [];
      this.fastTimeSelf = "";
      this.$emit("change", this.timeRangeSelf);
    },
    handleDateChange(val, index) {
      if (val && this.fastTimeSelf) {
        this.fastTimeSelf = ''
      }
      if (this.type.includes("time") && this.autoFix) {
        if (index == 1) {
          val = val ? val.split(" ")[0] + " 23:59:59" : "";
        }
        if (index == 0) {
          val = val ? val.split(" ")[0] + " 00:00:00" : "";
        }
      }
      if (this.type.includes("month")) {
        val = this.formatMonthDate(val, index);
      }
      this.timeRangeSelf.splice(index, 1, val || "");
      let event = "update:" + (index == 0 ? "start" : "end") + "Time";
      this.$emit(event, this.timeRangeSelf[index]);
      this.$emit("change", this.timeRangeSelf);
    },
    formatMonthDate(date, type) {
      // 月份选择器，自动添加日期时间
      if (date) {
        const format = this.autoFix ? "YYYY-MM-DD HH:mm:ss" : this.dayjsTimeFormat
        type == 0
          ? (date = dayjs(date)
            .startOf("month")
            .startOf("date")
            .format(format))
          : (date = dayjs(date)
            .endOf("month")
            .endOf("date")
            .format(format))
      }
      return date;
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
.self-date-picker {
  display: inline-block;

  .date-picker-btn-group {
    ::v-deep .el-radio__input {
      display: none;
    }
  }

  ::v-deep .el-range-separator {
    width: 10%;
  }

  .picker-slot-append {
    display: inline-block;
  }
}

.el-radio-group {
  margin: 0 8px;

  .el-radio {
    margin: 0 8px;
    color: $fontNotActive;
  }

  ::v-deep .el-radio__label {
    padding: 4px;
    border: 1px solid;
    border-radius: 4px;

    &:hover {
      color: $mainColor;
    }
  }
}

@media screen and (max-width: 1500px) {
  .self-date-picker .date-picker-btn-group .el-button {
    padding: 0px 7px;
  }
}
</style>
