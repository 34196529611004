import { getToken } from '@/utils/loginToken';
import { queryToString } from '../utils';

export default {
  computed: {
    fileUrl() {
      switch (process.env.VUE_APP_FLAG) {
        case 'dev':
          return this.$dict('FileSystemUrl')[0].desc;
        case 'test':
          return 'https://static.hjzx.tech/';
        case 'production':
          return 'https://static.hjzxh.com/';
        default:
          return '';
      }
    },
    isAdmin() {
      return this.$store.state.user.userData ? this.$store.state.user.userData.type == 1 : false;
    },
    userID() {
      // 当前登录用户ID
      return this.$store.state.user.userData ? this.$store.state.user.userData.id : '';
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_API
    },
    uploadHeader() {
      return {
        'X-Token': getToken()
      }
    },
    oemUrl() {
      return process.env.VUE_APP_OEM_URL
    }
  },
  methods: {
    hadPermission(permission) {
      return this.$store.state.user.userData.permissionFlags.has(permission);
    },
    getPubStatusTagType(status) {
      switch (Number(status)) {
        case 100:
          return 'success';
        case 101:
        default:
          return 'danger'
      }
    },
    handleRateInput(obj, rateStr, value, min, max, decLen) {
      let len, int, decimal
      if (typeof min == 'object') {
        let options = { ...min }
        min = options.min
        max = options.max
        len = options.length || 13
        decLen = options.decLen || 2
      }
      decLen = decLen || 2
      let val = String(value)
      val = val.replace(/[^(\d.)]/g, ''); // 去除非数字和小数点
      val = val.replace(/(\.)(\d*)\./, '$1$2'); // 去除多余小数点
      let valid = true;
      if (min !== undefined) {
        valid = (val >= min)
      }
      if (max !== undefined) {
        valid = (val <= max)
      }
      if (len) {
        val = val.slice(0, len)
      }
      if (/\./.test(val)) {
        // 有小数点
        ([int, decimal] = val.split('.'))
        decimal = decimal.slice(0, decLen)
        val = `${ int }.${ decimal }`
      }
      if (valid) {
        // obj[rateStr] = val;
        this.$set(obj, rateStr, val)
      }
    },
    handleNumInput(obj, rateStr, value, min, max) {
      let val = String(value);
      val = val.replace(/[^\d]/g, '');
      let valid = true;
      if (min !== undefined) {
        valid = (val >= min)
      }
      if (max !== undefined) {
        valid = (val <= max)
      }
      if (valid) {
        obj[rateStr] = val;
      }
    },
    renderTime(time) {
      if (!time) return '';
      const arr = time.split(' ');
      return `<span>${ arr[0] }</span><br/><span>${ arr[1] }</span>`
    },
    isPdf(url) {
      return /\.pdf$/i.test(url);
    },
    isImg(url) {
      const typeList = ['.png', '.jpg', '.jpeg', '.bmp', '.gif'];
      const reg = new RegExp(`(${typeList.join('|')})$`, 'i');
      return reg.test(url);
    },
    validateUploadFile(options) {
      let self = this;
      return function (file) {
        let isTrueType = false;
        let isLimitSize = true;
        if (options.img) {
          isTrueType = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif'].includes(file.type);
        }
        if (options.pdf) {
          isTrueType = isTrueType || ['application/pdf'].includes(file.type);
        }
        if (options.word) {
          isTrueType = isTrueType || /(\.doc|\.docx)$/i.test(file.name)
        }
        if (options.addition && Array.isArray(options.addition)) {
          let reg = new RegExp('(' + options.addition.join('|') + ')$', 'i');
          isTrueType = isTrueType || reg.test(file.name)
        }
        if (options.size) {
          isLimitSize = file.size / 1024 / 1024 < options.size
        }

        if (!isLimitSize) {
          self.$message.error(`上传文件大小不能超过 ${ options.size } MB!`);
        }

        if (!isTrueType) {
          let type = [];
          if (options.pdf) {
            type.push('pdf')
          }
          if (options.word) {
            type.push('doc、docx')
          }
          if (options.img) {
            type.push('bmp、png、jpeg、jpg或gif')
          }
          if (options.addition) {
            type.push(options.addition.join('、'))
          }
          type = type.join('、');
          self.$message.error(`上传文件只能是 ${ type } 格式!`);
        }
        return isLimitSize && isTrueType
      }
    },
    downloadFile(url, name = '下载文件') {
      // const a = document.createElement('a');
      // a.download = name;
      // a.href= url;
      // a.target = '_blank';
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      // 利用文件流下载，绕过预览
      let ext = url.match(/\.(\w+)$/i)[1]
      const vm = this
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.send();
      xhr.onload = function () {
        if (this.status === 200 || this.status === 304) {
          // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
          if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(this.response, name);
            return;
          }
          const url = URL.createObjectURL(this.response);
          vm.createDownloadLink(url, `${ name }.${ ext }`)
          URL.revokeObjectURL(url);
        }
      };
    },
    downloadByLink(url, fileName = '') {
      // 导出文件下载方法
      const mime = url.match(/\.(\w+)/i)[0]
      let a = document.createElement('a');
      a.download = fileName + mime;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    createDownloadLink(url, downloadName) {
      const eleLink = document.createElement("a");
      eleLink.href = url;
      eleLink.download = downloadName;
      eleLink.style.display = "none";
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    openNewBrowserTab({ path, query }) {
      if (process.env.NODE_ENV === 'development') {
        return this.$router.push({
          path,
          query
        })
      }
      if (typeof arguments[0] === 'string') {
        return window.open(arguments[0])
      }
      let url = `${ process.env.VUE_APP_URL }#${ path }`;
      if (query && typeof query === 'object') {
        const queryString = queryToString(query)
        url = `${ url }?${ queryString }`
      }
      window.open(url)
    },
    sleep(time = 1000) {
      return new Promise(resolve => {
        setTimeout(resolve, time)
      })
    },
  }
}
