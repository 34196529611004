var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchant-info-container"},[_c('div',{staticClass:"clearfix",staticStyle:{"padding":"0 16px"}},[_c('span',{staticClass:"float-l",staticStyle:{"line-height":"40px"}},[_vm._v("共 "+_vm._s(_vm.form1.length + _vm.form2.length)+" 条")]),_c('div',{staticClass:"float-r"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.goToQuote}},[_vm._v("添加 ")])],1)]),(_vm.form1.length)?[_c('p',{staticClass:"subTitle"},[_vm._v("仕加云产品报价单")]),_c('el-table',{attrs:{"data":_vm.form1,"span-method":_vm.spanMethod}},[_c('el-table-column',{attrs:{"label":"产品"}},[_c('el-table-column',{attrs:{"label":"产品名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productNo)+" "),_c('br'),_vm._v(" "+_vm._s(row.productName)+" ")]}}],null,false,2050437493)}),_c('el-table-column',{attrs:{"label":"供应商"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.mainstayMchNo)+" "),_c('br'),_vm._v(" "+_vm._s(row.mainstayMchName)+" ")]}}],null,false,2359385173)}),_c('el-table-column',{attrs:{"label":"岗位类别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.positionNameList),function(item,index){return _c('el-tooltip',{key:index,attrs:{"content":item}},[_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(item)+" ")])],1)})}}],null,false,2182062632)}),_c('el-table-column',{attrs:{"label":"发票类目","prop":"invoiceCategoryList"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.invoiceCategoryList),function(item,index){return _c('el-tooltip',{key:index,attrs:{"content":item.invoiceCategoryName}},[_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(item.invoiceCategoryName)+" ")])],1)})}}],null,false,266060439)})],1),_c('el-table-column',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" 报价 "),_c('el-tooltip',{attrs:{"content":"如有阶梯计费、单笔金额等特殊计费需求，可使用「编辑计费」功能"}},[_c('i',{staticClass:"el-icon-warning"})])]},proxy:true}],null,false,2328933771)},[_c('el-table-column',{attrs:{"label":"公式类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$dictCode('FormulaEnum', row.quoteData.formulaType).desc)+" ")]}}],null,false,1984051345)}),_c('el-table-column',{attrs:{"label":"固定金额"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.quoteData.formulaType == 1 || row.quoteData.formulaType == 2)?_c('div',[_vm._v(" "+_vm._s(row.quoteData.fixedFee)+" ")]):_vm._e()]}}],null,false,542613243)}),_c('el-table-column',{attrs:{"label":"比例"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.quoteData.formulaType == 0 || row.quoteData.formulaType == 2)?_c('div',[_vm._v(" "+_vm._s(row.quoteData.rate)+" % ")]):_vm._e()]}}],null,false,3836233357)})],1),_c('el-table-column',{attrs:{"label":"特殊计费规则"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.ruleParam),function(item,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm.$dictCode('ProductFeeSpecialRuleTypeEnum', item.specialRuleType).desc)+" "+_vm._s(_vm.$dictCode('CompareTypeEnum', item.compareType).desc)+" "+_vm._s(item.value)+" ")])})}}],null,false,2509845063)}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.status == 100 ? '' : 'info'}},[_vm._v(" "+_vm._s(_vm.$dictCode('QuoteStatusEnum', row.status).desc)+" ")])]}}],null,false,2465870312)}),(!_vm.isPop)?_c('el-table-column',{attrs:{"label":"操作","prop":"operate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editQuote(row)}}},[_vm._v(" 编辑 ")]),(row.status == 100)?_c('el-button',{staticClass:"red-btn",attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteQuote(row)}}},[_vm._v(" 删除 ")]):_vm._e()]}}],null,false,176853580)}):_vm._e()],1)]:_vm._e(),(_vm.form2.length)?[_c('p',{staticClass:"subTitle"},[_vm._v("创客汇产品报价单")]),_c('el-table',{attrs:{"data":_vm.form2}},[_c('el-table-column',{attrs:{"label":"产品"}},[_c('el-table-column',{attrs:{"label":"产品名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.productNo)+" "),_c('br'),_vm._v(" "+_vm._s(row.productName)+" ")]}}],null,false,2050437493)}),_c('el-table-column',{attrs:{"label":"供应商"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.mainstayMchNo)+" "),_c('br'),_vm._v(" "+_vm._s(row.mainstayMchName)+" ")]}}],null,false,2359385173)}),_c('el-table-column',{attrs:{"label":"岗位类别"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.positionNameList),function(item,index){return _c('el-tooltip',{key:index,attrs:{"content":item}},[_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(item)+" ")])],1)})}}],null,false,2182062632)}),_c('el-table-column',{attrs:{"label":"发票类目","prop":"invoiceCategoryList"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.invoiceCategoryList),function(item,index){return _c('el-tooltip',{key:index,attrs:{"content":item.invoiceCategoryName}},[_c('el-tag',{attrs:{"type":"info"}},[_vm._v(" "+_vm._s(item.invoiceCategoryName)+" ")])],1)})}}],null,false,266060439)})],1),_c('el-table-column',{attrs:{"label":"个税"}},[_c('el-table-column',{attrs:{"label":"个税类型","prop":"taxTypeDesc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(row.merchantCkhQuote[column.property])+" ")]}}],null,false,955073580)}),_c('el-table-column',{attrs:{"label":"个税承担方"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$dictCode('TaxPayerEnum', row.merchantCkhQuote.taxPayer).desc)+" ")]}}],null,false,1034784531)}),_c('el-table-column',{attrs:{"label":"增值税税率 %"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.merchantCkhQuote.addedTaxRatePct)+" ")]}}],null,false,1160252841)})],1),_c('el-table-column',{attrs:{"label":"服务费比例 %","prop":"serviceFeeRatePct"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(row.merchantCkhQuote[column.property])+" ")]}}],null,false,955073580)}),_c('el-table-column',{attrs:{"label":"服务费结算模式","prop":"balancedMode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
return [_vm._v(" "+_vm._s(_vm.$dictCode('BalancedEnum', row.merchantCkhQuote[column.property]).desc)+" ")]}}],null,false,3635481692)}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tag',{attrs:{"type":row.status == 100 ? '' : 'info'}},[_vm._v(" "+_vm._s(_vm.$dictCode('QuoteStatusEnum', row.status).desc)+" ")])]}}],null,false,2465870312)}),(!_vm.isPop)?_c('el-table-column',{attrs:{"label":"操作","prop":"operate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editQuote(row)}}},[_vm._v(" 编辑 ")]),(row.status == 100)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteQuote(row)}}},[_vm._v(" 删除 ")]):_vm._e()]}}],null,false,2716857706)}):_vm._e()],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }