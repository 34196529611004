import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '@/store'
import { getToken, removeToken } from '@/utils/loginToken'
import { resetRouter } from '../router'

let messageLock = true;
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    let token = getToken()
    if (token) {
      // ['X-Token'] is a custom headers key
      config.headers['X-Token'] = token
    }
    let tempData;
    if (config.data && !(config.data instanceof FormData)) {
      let { _keepVoidData = false } = config.data; // 是否保留空字符串
      tempData = { ...config.data };
      for (let p in tempData) {
        if ((tempData[p] === undefined || tempData[p] === '') && !_keepVoidData) {
          delete tempData[p]
        }
      }
      delete tempData._keepVoidData
    }
    if (tempData) {
      return Object.assign({}, { ...config }, { data: tempData })
    } else {
      return config
    }
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      if (messageLock) {
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }

      //token无效,需要重新登录
      if (res.code === 20003 && messageLock) {
        messageLock = false;
        MessageBox.confirm('Token已失效，需要重新登录', 'token失效', {
          confirmButtonText: '重新登录',
          type: 'warning',
          showCancelButton: false,
          showClose: false,
        }).then(() => {
          store.dispatch('setUserData', undefined).then(() => {
            removeToken();
            resetRouter();
            location.replace('./login.html');
          })
        }).finally(() => {
          messageLock = true;
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
