<template>
  <el-dialog
      style="z-index:9999"
      title="列表"
      width="65%"
      :visible.sync="isShow"
      :close-on-click-modal="false"
      :before-close="closeBankNumberInfoDialog"
      :destroy-on-close="true"
      :append-to-body="true"
  >
    <el-form
        :inline="true"
        :model="bankForm"
        ref="bankForm"
        label-position="right"
        class="demo-form-inline"
        size="medium"
        label-width="100px">
      <div class="formLine bank-line">
        <el-form-item label="联行号" class="ele-form-item" prop="bankChannelNo">
          <el-input v-model="bankForm.bankChannelNo" placeholder="精确查询" class="form202"></el-input>
        </el-form-item>
        <el-form-item label="支行名称" class="ele-form-item" prop="bankNameLike">
          <el-input v-model="bankForm.bankNameLike" placeholder="支持模糊查询" class="form202"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="medium" @click="search(true)">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
        :data="tableData"
        @row-click="singleElection"
    >
      <el-table-column label="选择" width="65">
        <template slot-scope="scope">
          <el-radio class="radio" v-model="templateSelection" :label="scope.$index">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column type="index" :index="getIndex" label="序号" width="80"></el-table-column>
      <el-table-column property="bankChannelNo" label="联行号" width="200"></el-table-column>
      <el-table-column property="bankName" label="支行名称"></el-table-column>
    </el-table>
    <div class="paginationBox">
      <div class="block">
        <el-pagination
            @size-change="pageSizeChange"
            @current-change="pageCurrentChange"
            background
            :current-page="bankForm.pageCurrent"
            :page-sizes="[10,50,100]"
            :page-size="bankForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeBankNumberInfoDialog">取 消</el-button>
      <el-button type="primary" @click="closeBankNumberInfoDialog('cirm')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { listRemitBankInfoPage } from '@/api/merchant'
import { getBankList } from '../../api/common'
export default {
  name: 'bankNumberInfo',
  props: {
    dialogShow: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    formDisable: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    actionMethod: {
      type: String,
      default: () => {
        return 'add'
      }
    }
  },
  data() {
    return {
      templateSelection: '',
      templateRadio: '',
      isShow: this.dialogShow,       // 添加弹窗显示
      total: 0,
      bankForm: {
        pageCurrent: 1,
        pageSize: 10,
        bankChannelNo: '',
        bankNameLike: ''
      },
      tableData: [],
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px'
    };
  },
  watch: {
    dialogShow(val) {
      this.isShow = val
    }
  },
  methods: {
    search(initFlag) {
      if (initFlag === true) {
        this.bankForm.pageCurrent = 1
      }
      this.templateSelection = '';
      this.templateRadio = '';
      getBankList(this.bankForm).then(res => {
        this.tableData = res.data.data
        this.total = res.data.totalRecord
      })
    },
    init() {
      this.bankForm = {
        pageCurrent: 1,
        pageSize: 10,
        bankChannelNo: '',
        bankNameLike: ''
      }
    },
    closeBankNumberInfoDialog (flag) {
      if (flag === 'cirm') {
        if (this.templateRadio) {
          this.isShow = false;
          this.$emit('closeDialog', this.templateRadio)
        } else {
          this.$message.error('请选择联行号！')
        }
      } else {
        this.isShow = false;
        this.$emit('closeDialog')
      }
      // this.clearValidate();
    },
    singleElection (row) {
      this.templateSelection = this.tableData.indexOf(row);
      // this.templateRadio = row.id;
      this.templateRadio = row;
    },
    // 表格 size 更改
    pageSizeChange(val) {
      this.bankForm.pageSize = val;
      this.bankForm.pageCurrent = 1;
      this.search();
    },
    // 表格 页数 更改
    pageCurrentChange(val) {
      this.bankForm.pageCurrent = val;
      this.search();
    },
    getIndex(index) {
      return (this.bankForm.pageCurrent - 1) * this.bankForm.pageSize + index + 1;
    },
  }
};
</script>

<style lang="scss" scoped>
  .bank-line {
    text-align: left;
  }
</style>
