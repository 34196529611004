<template>
  <div class="scroll-wrapper" :style="wrapperStyle">
    <div class="scroll-content" ref="content">
      <slot />
    </div>
    <div class="scroll-arrow left-arrow" :class="{active: leftScroll}" @click="moveLeft">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="scroll-arrow right-arrow" :class="{active: rightScroll}" @click="moveRight">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import {debounce} from '@/utils'
export default {
  props: {
    wrapperHeight: String,
    wrapperWidth: {
      type: String,
      default: '600px'
    },
    offset: { // 每次点击移动距离
      type: [String, Number],
      default: 200,
    },
    duration: { // 每次移动时长
      type: [String, Number],
      default: 50,
    }
  },
  data() {
    return {
      leftScroll: true,
      rightScroll: true,

      clickAble: true, // 防止连续点击触发

      timer: null,
    }
  },
  computed: {
    wrapperStyle() {
      return {
        height: this.wrapperHeight,
        width: this.wrapperWidth,
      }
    },
    content() {
      return this.$refs.content;
    }
  },
  mounted() {
    this.checkScrollAble();

    this.checkFunc = debounce(() => {
      this.checkScrollAble();
    }, 300)
    this.content.addEventListener('scroll', this.checkFunc);
  },
  beforeDestroy() {
    this.content.removeEventListener('scroll', this.checkFunc);
  },
  methods: {
    moveLeft() {
      if (!this.leftScroll || !this.clickAble) return;
      const step = this.offset / this.duration; // 每次移动距离
      const offset = Math.min(this.content.scrollLeft, this.offset);
      const target = this.content.scrollLeft - offset; // 目标距离
      this.moveAnimate(target, step);
    },
    moveRight() {
      if (!this.rightScroll || !this.clickAble) return;
      const step = this.offset / this.duration;
      const offset = Math.min(this.content.scrollWidth - this.content.scrollLeft - this.content.clientWidth, this.offset);
      const target = this.content.scrollLeft + offset; // 目标距离
      this.moveAnimate(target, step);
    },
    moveAnimate(target, step) {
      this.clickAble = false;
      const now = this.content.scrollLeft;
      if (Math.abs(now - target) > 3) {
        if (now < target) {
          this.content.scrollLeft += step;
          this.timer = setTimeout(() => {
            this.moveAnimate(target, step)
          }, 5);
        } else if (now > target) {
          this.content.scrollLeft -= step;
          this.timer = setTimeout(() => {
            this.moveAnimate(target, step)
          }, 5);
        }
      } else {
        this.content.scrollLeft = target;
        this.timer && clearTimeout(this.timer);
        // this.checkScrollAble();
      }
    },
    checkScrollAble() {
      if (this.content.scrollLeft == 0) {
        this.leftScroll = false;
        this.rightScroll = true;
      } else if (Math.abs(this.content.scrollWidth - (this.content.scrollLeft + this.content.clientWidth)) <= 3) {
        this.rightScroll = false;
        this.leftScroll = true;
      } else {
        this.leftScroll = this.rightScroll = true;
      }
      this.clickAble = true;
    },
    scrollTo(selector) {
      if (typeof selector == 'string') {
        // 传入对应的类名
        let node = this.content.querySelector(selector);
        if (!node) return;
        this.content.scrollLeft = node.offsetLeft;
        this.checkScrollAble();
      }
    },
  }
}
</script>

<style lang="scss" scoped>

  .scroll-wrapper {
    display: inline-block;
    position: relative;
    padding: 0 50px;
    overflow-y: hidden;

    .scroll-content {
      padding-bottom: 100px;
      overflow-x: scroll;
      white-space: nowrap;
    }

    .scroll-arrow {
      @include position-tb-center(absolute);
      border: 1px solid transparent;
      border-radius: 100%;
      font-size: 24px;
      &.active {
        cursor: pointer;
        @include boxShadow;
        border-radius: 100%;
      }
    }
    .left-arrow {
      left: 0;
    }
    .right-arrow {
      right: 0;
    }
  }

</style>