// 全局右侧弹窗
// type => 1. 商户信息 2. 合伙人

import { getSystemRecord } from '@/api/common';
import { getSales } from '@/api/system'
import { getBaseInfo, getCooperateInfo, getMainInfo, getAccountInfo, getBusinessInfo, getAgreementInfo, getQuoteInfo, getRecordInfo, getQuote } from '@/api/merchant';
import { getBaseInfo as getAgentBaseInfo, getMainInfo as getAgentMainInfo, getBankInfo as getAgentBankInfo, getCooperateInfo as getAgentCooperateInfo, } from '@/api/agent'
import { listAllIndustryType, listAllWorkCategory } from '@/api/business'
import { convert } from '@/utils'
import { getHandleList } from '@/api/flow'
import { getAllInvoiceCategory } from "@/api/invoice";

const state = {
  type: 0,
  visible: false,
  content: {},
  cacheList: [],
  loading: false,
  allInvoiceCate: [],
  mchNo: null,
};

const mutations = {
  SET_POP_TYPE: (state, type) => {
    state.type = type
  },
  SET_POP_CONTENT: (state, content) => {
    state.content = content;
  },
  TOGGLE_POP: (state, status) => {
    state.visible = status;
    if (!status) state.type = 0;
  },
  SET_CACHE_LIST: (state, list) => {
    state.cacheList = list
  },
  TOGGLE_LOADING: (state, status) => {
    state.loading = status;
  },
  SET_ALL_INVOICE: (state, list) => {
    state.allInvoiceCate = list
  },
  SET_MCH_NO: (state, mchNo) => {
    state.mchNo = mchNo
  },
  SET_HISTORY_FLOW: (state, info) => {
    state.content.historyInfo = info
  }
};

const actions = {
  async openMerchantPop({commit, rootState, rootGetters}, {mchNo, list}) {
    if (!mchNo) return null;
    commit('TOGGLE_POP', true);
    commit('TOGGLE_LOADING', true);
    commit('SET_MCH_NO', mchNo);

    const permissionMap = {
      'merchantEmployer:base:view': {
        api: getBaseInfo,
        result: 'baseInfo'
      },
      'merchantEmployer:coop:view': {
        api: getCooperateInfo,
        result: 'cooperateInfo'
      },
      'merchantEmployer:quote:view': {
        api: getQuoteInfo,
        result: 'quoteInfo'
      },
      'merchantEmployer:main:view': {
        api: getMainInfo,
        result: 'mainInfo'
      },
      'merchantEmployer:agreement:view': {
        api: getAgreementInfo,
        result: 'agreementInfo'
      },
      'merchantEmployer:account:view': {
        api: getAccountInfo,
        result: 'accountInfo'
      },
      'merchantEmployer:business:view': {
        api: getBusinessInfo,
        result: 'businessInfo'
      },
      'void': {
        api: getRecordInfo,
        result: 'recordInfo'
      }
    };
    let promiseList = [];
    let resultList = [];
    let content = {};

    const { data } = await getQuote({ mchNo });
    const tempMap = {};
    const mainstayList = [];
    data.forEach(item => {
      if (!(tempMap[item.mainstayMchNo])) {
        tempMap[item.mainstayMchNo] = item.mainstayMchName;
      }
    });
    for (let p in tempMap) {
      mainstayList.push({
        mainstayNo: p,
        mainstayName: tempMap[p]
      })
    }
    content.mainstayList = mainstayList

    for (let p in permissionMap) {
      if (p === 'void' || rootGetters.userData.permissionFlags.has(p)) {
        promiseList.push(permissionMap[p]['api']({
          mchNo: mchNo
        }));
        resultList.push(permissionMap[p]['result'])
      }
    }
    // 系统信息单独
    promiseList.push(getSystemRecord({
      mchNo: mchNo,
      type: mchNo.startsWith('M') ? 100 : 101,
    }));
    resultList.push('systemInfo');

    async function getHistoryInfo({ current = 1, size = 10 }) {
      const res = await getHandleList({
        employerNo: mchNo,
        pageCurrent: current,
        pageSize: size,
        workType: 101,
      });
      return res
    }

    if (rootGetters.userData.permissionFlags.has('merchantEmployer:workOrder:view')) {
      promiseList.push(getHistoryInfo({
        current: 1,
        size: 10,
      }));
      resultList.push('historyInfo');
    }

    const res = await Promise.all(promiseList);
    res.forEach((item, index) => {
      content[resultList[index]] = item.data || {};
    });

    const {data: industryList} = await listAllIndustryType();
    const industryListOptions = convert(industryList, 0);
    const {data: workCategoryList} = await listAllWorkCategory();
    const workCategoryOptions = convert(workCategoryList, 0);
    const {data: staffList} = await getSales();
    const { data: allInvoiceCate } = await getAllInvoiceCategory();
    commit('SET_POP_TYPE', 1);
    commit('SET_POP_CONTENT', {
      ...content,
      industryList,
      industryListOptions,
      workCategoryList,
      workCategoryOptions,
      staffList,
    });
    commit('SET_CACHE_LIST', list);
    commit('TOGGLE_LOADING', false);
    commit('SET_ALL_INVOICE', allInvoiceCate)

  },
  async openAgentPop({commit, rootState, rootGetters}, {agentNo}) {
    if (!agentNo) return null;
    commit('TOGGLE_POP', true);
    commit('TOGGLE_LOADING', true);
    let content = {};
    const permissionMap = {
      'agent:baseInfo:view': {
        api: getAgentBaseInfo,
        result: 'baseInfo'
      },
      'agent:mainInfo:view': {
        api: getAgentMainInfo,
        result: 'mainInfo'
      },
      'agent:bankInfo:view': {
        api: getAgentBankInfo,
        result: 'bankInfo'
      },
      'agent:cooperation:view': {
        api: getAgentCooperateInfo,
        result: 'cooperateInfo'
      },
    };
    let promiseList = [];
    let resultList = [];
    for (let p in permissionMap) {
      if (p === 'void' || rootGetters.userData.permissionFlags.has(p)) {
        promiseList.push(permissionMap[p]['api']({
          agentNo
        }));
        resultList.push(permissionMap[p]['result'])
      }
    }
    // 系统信息单独
    promiseList.push(getRecordInfo({
      mchNo: agentNo,
    }));
    resultList.push('recordInfo');

    promiseList.push(getSystemRecord({
      mchNo: agentNo,
      type: 103,
    }));
    resultList.push('systemInfo');

    const res = await Promise.all(promiseList);
    res.forEach((item, index) => {
      content[resultList[index]] = item.data || null;
    });
    const {data: staffList} = await getSales();

    commit('SET_POP_TYPE', 2);
    commit('SET_POP_CONTENT', {
      ...content,
      staffList
    });
    commit('TOGGLE_LOADING', false);
  },
  togglePop({commit}, status) {
    commit('TOGGLE_POP', status);
  },
  togglePopLoading({ commit }, status) {
    commit('TOGGLE_LOADING', status)
  },
  async getHistoryFlow({ commit, state }, { size, current }) {
    if (!state.mchNo) return;
    const { data } = await getHandleList({
      employerNo: state.mchNo,
      pageCurrent: current,
      pageSize: size,
      workType: 101,
    })
    commit('SET_HISTORY_FLOW', data);
  }
};

export default {
  state,
  mutations,
  actions
}
