<template>
  <section class="app-main">
    <router-view :key="key"/>
    <merchant-pop></merchant-pop>
  </section>
</template>

<script>
  import MerchantPop from '@/components/MerchantPop'
  export default {
    name: 'AppMain',
    components: {
      MerchantPop
    },
    computed: {
      key() {
        return this.$route.path
      },
    }
  }
</script>

<style scoped>
  .app-main {
    /*50 = navbar  */
    min-height: calc(100vh - 50px);
    width: 100%;
    position: relative;
    background: #F0F2F5;
    padding: 16px;
  }

  .fixed-header + .app-main {
    padding-top: 16px;
  }
</style>

<style lang="scss">
  // fix css style bug in open el-dialog
  .el-popup-parent--hidden {
    .fixed-header {
      padding-right: 15px;
    }
  }
</style>
