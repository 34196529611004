<template>
  <div v-loading="loading">
    <!-- 商户名称等信息 -->
    <div style="background: #fff; padding: 16px; margin: 0 16px;">
      <div style="font-size: 20px">
        <el-tag type="info">
          {{ $dictCode('MerchantTypeEnum', baseInfo.merchantType).desc }}
        </el-tag>
        <span
          class="merchant-arrow"
          @click="switchMerchant(-1)"
          v-if="showArrow"
        >
          <i class="el-icon-caret-left"></i>
        </span>
        <span class="merchant-base-name">{{ baseInfo.mchName }}</span>
        <span
          class="merchant-arrow"
          @click="switchMerchant(1)"
          v-if="showArrow"
        >
          <i class="el-icon-caret-right"></i>
        </span>
      </div>
      <!-- 操作栏 -->
      <div style="margin-top: 16px;">
        <el-button
          v-if="showEdit"
          @click="editInfo"
        >
          编辑
        </el-button>
        <el-button
          v-if="showSale"
          @click="changeSale"
          v-permission="'pms:merchantFlow:changeSaler'"
        >
          {{ isMerchant ? '更换销售/合伙人' : '更换销售' }}
        </el-button>
        <el-button
          v-if="showLeader"
          @click="changeLeader"
          v-permission="'pms:merchantFlow:changePrincipal'"
        >
          更换负责人
        </el-button>
        <slot name="btnMiddle"></slot>
        <el-button @click="onExport">
          导出
        </el-button>
        <el-button
          type="text"
          @click="getExportList"
        >
          查看已导出列表
        </el-button>
      </div>
    </div>

    <!--tabs-->
    <div class="tabs-container">
      <el-tabs
        type="card"
        v-model="activeName"
        :before-leave="beforeLeaveTab"
      >
        <el-tab-pane
          label="基本信息"
          name="baseInfo"
          v-if="canShowTab('merchantEmployer:base:view')"
        >
          <base-info
            ref="baseInfo"
            :info="baseInfo"
            :system-info="systemInfo"
            :preview-handle="onPreview"
            :staff-list="staffList"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
          ></base-info>
        </el-tab-pane>
        <el-tab-pane
          label="合作信息"
          name="cooperateInfo"
          v-if="canShowTab('merchantEmployer:coop:view', 'AGENT_MAININFO_EDIT')"
        >
          <cooperate-info
            ref="cooperateInfo"
            :info="cooperateInfo"
            :preview-handle="onPreview"
            :workCategoryList="workCategoryList"
            :workCategoryOptions="workCategoryOptions"
            :industryList="industryList"
            :industryListOptions="industryListOptions"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
            :all-invoice-cate="allInvoiceCate"
          ></cooperate-info>
        </el-tab-pane>
        <el-tab-pane
          label="产品报价单"
          name="quoteInfo"
          v-if="isMerchant && canShowTab('merchantEmployer:quote:view')"
        >
          <quote-info
            ref="quoteInfo"
            :info="quoteInfo"
            :only-show="$attrs.onlyShow"
            :close-pop="closePop"
            :is-pop="isPop"
            :base-info="baseInfo"
            @route-change="onRouteChange"
            @del-quote="onDelQuote"
          ></quote-info>
        </el-tab-pane>
        <el-tab-pane
          label="协议"
          name="agreementInfo"
          v-if="canShowTab('merchantEmployer:agreement:view')"
        >
          <agreement-info
            ref="agreementInfo"
            :info="agreementInfo"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
          ></agreement-info>
        </el-tab-pane>
        <el-tab-pane
          label="主体信息"
          name="mainInfo"
          v-if="canShowTab('merchantEmployer:main:view')"
        >
          <main-info
            ref="mainInfo"
            :info="mainInfo"
            :preview-handle="onPreview"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
          ></main-info>
        </el-tab-pane>
        <el-tab-pane
          label="经营信息"
          name="businessInfo"
          v-if="canShowTab('merchantEmployer:business:view')"
        >
          <business-info
            ref="businessInfo"
            :info="businessInfo"
            :preview-handle="onPreview"
            :disabled="formDisabled"
            :only-show="$attrs.onlyShow"
          ></business-info>
        </el-tab-pane>
        <el-tab-pane
          label="账户信息"
          name="accountInfo"
          v-if="canShowTab('merchantEmployer:account:view', 'MCH_ACCOIUNT_EDIT')"
        >
          <account-info
            ref="accountInfo"
            :mch-no="baseInfo.mchNo"
            :info="accountInfo"
            :disabled="formDisabled"
            :channel-list="channelList"
            :only-show="$attrs.onlyShow"
            :account-quote-info="accountQuoteInfo"
          ></account-info>
        </el-tab-pane>
        <el-tab-pane
          label="修改记录"
          name="recordInfo"
        >
          <record-info
            ref="recordInfo"
            :info="recordInfo"
          ></record-info>
        </el-tab-pane>
        <el-tab-pane
          label="历史交付物"
          name="historyInfo"
          v-if="hadPermission('merchantEmployer:workOrder:view')"
        >
          <history-flow
            ref="historyInfo"
            :info="historyInfo"
            :close-pop="closePop"
            :is-pop="isPop"
            @route-change="onRouteChange"
            @page-change="loadHistoryInfo"
          ></history-flow>
        </el-tab-pane>

      </el-tabs>
    </div>

    <!--切换tab的弹出框-->
    <tab-change-dialog
      :visible.sync="visible"
      @confirm="onConfirmTabChange"
    ></tab-change-dialog>
  </div>
</template>

<script>
import BaseInfo from './BaseInfo'
import CooperateInfo from "./CooperateInfo";
import QuoteInfo from "./QuoteInfo";
import MainInfo from './MainInfo'
import BusinessInfo from './BusinessInfo'
import AccountInfo from './AccountInfo'
import AgreementInfo from './AgreementInfo'
import TabChangeDialog from "./TabChangeDialog";
import RecordInfo from "./RecordInfo";
import HistoryFlow from "./HistoryFlow";
import { queryToString } from '@/utils/index'

export default {
  name: 'merchant-info-comp',
  components: {
    RecordInfo,
    TabChangeDialog,
    CooperateInfo,
    BaseInfo,
    QuoteInfo,
    MainInfo,
    BusinessInfo,
    AccountInfo,
    AgreementInfo,
    HistoryFlow,
  },
  props: {
    activeTab: {
      type: String,
      default: '',
    },
    disabled: { // 全盘禁用
      type: Boolean,
      default: false,
    },
    isPop: { // 是否为弹窗形式
      type: Boolean,
      default: false,
    },
    showSale: {
      type: Boolean,
      default: true,
    },
    showLeader: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    baseInfo: {
      type: Object,
      default: () => ({})
    },
    systemInfo: {
      type: Object,
      default: () => ({})
    },
    quoteInfo: {
      type: Array,
      default: () => []
    },
    recordInfo: {
      type: Array,
      default: () => []
    },
    mainInfo: {
      type: Object,
      default: () => ({})
    },
    cooperateInfo: {
      type: Object,
      default: () => ({})
    },
    businessInfo: {
      type: Object,
      default: () => ({})
    },
    accountInfo: {
      type: Object,
      default: () => ({})
    },
    accountQuoteInfo: {
      type: Array,
      default: () => ([])
    },
    agreementInfo: {
      type: Array,
      default: () => []
    },
    staffList: {
      type: Array,
      default: () => []
    },
    workCategoryList: Array, // 工作类目
    workCategoryOptions: [Array, Object], // 工作类目树
    industryList: Array, // 行业类别
    industryListOptions: [Array, Object],
    channelList: Array,
    delQuote: Function,
    allInvoiceCate: Array,
    historyInfo: Object,
    loadHistory: Function,
  },
  data() {
    return {
      activeName: this.activeTab || 'baseInfo',
      visible: false,
    }
  },
  computed: {
    formDisabled() {
      return this.disabled || this.isPop
    },
    isMerchant() {
      if (this.baseInfo.mchNo) {
        return this.baseInfo.mchNo.startsWith('M');
      } else {
        return false
      }
    }
  },
  mounted() {
    if (this.isPop) {
      // hack 弹窗首次挂载为渲染, 强制触发初始化
      this.init();
    }
  },
  methods: {
    init() {
      if (this.loading) {
        setTimeout(this.init, 100)
        return;
      }
      let infoList = this.$refs;
      for (let name in infoList) {
        if (infoList[name] && typeof infoList[name].init === 'function') {
          infoList[name].init();
        }
      }
    },
    canShowTab(permission, process = '') {
      let processId = this.$parent.processId;
      let processType = this.$parent.processType;
      if (!processId) {
        return this.hadPermission(permission)
      } else {
        return processType === process
      }
    },
    editInfo() {
      this.$emit('edit-click')
    },
    beforeLeaveTab(active, old) {
      if (this.formDisabled) {
        return true
      }
      this.nextActive = active; // 暂存点击的tab
      this.visible = true;
      return false
    },
    onExport() {
      this.$emit('export-info')
    },
    getExportList() {
      this.$emit('get-export')
    },
    onPreview(file) {
      let url = file.response ? (this.fileUrl + file.response.data) : file.url;
      if (this.isImg(url)) {
        this.$preview(url)
      } else {
        let suffix = url.split('.')[1];
        this.downloadFile(url, `补充信息文件.${ suffix }`);
      }
    },
    switchMerchant(step) {
      this.$emit('switch-mch', step)
    },
    changeSale() {
      this.$emit('sale-change')
    },
    changeLeader() {
      this.$emit('leader-change')
    },
    changeTab(active) {
      this.activeName = active;
    },
    async onConfirmTabChange() {
      const form = this.$refs[this.activeName].$refs.form;
      if (form) {
        const valid = await form.validate().catch(e => e);
        if (!valid) return this.$message.error('请补充必填信息');
      }
      this.$emit('tab-change', {
        old: this.activeName,
        active: this.nextActive
      });
    },
    onRouteChange({ path, query = {}, newTab = false }, info = {}) {
      const keys = Object.keys(info);
      keys.forEach(key => {
        sessionStorage.setItem(key, JSON.stringify(info[key]));
      });
      if (!newTab) {
        if (path === '/quote/mchQuoteForm') {
          query = {
            ...query,
            mchNo: this.baseInfo.mchNo,
            mchName: this.baseInfo.mchName,
          }
        }
        this.$router.push({
          path,
          query
        })
      } else {
        window.open(process.env.VUE_APP_URL + '#' + path + '?' + queryToString(query))
      }
    },
    onDelQuote() {
      if (this.delQuote && this.delQuote instanceof Function) {
        this.delQuote()
      }
    },
    closePop() {
      this.$emit('close-pop')
    },
    loadHistoryInfo(page) {
      this.loadHistory(page)
    }
  },
}
</script>

<style
  scoped
  lang="scss">
.merchant-arrow {
  cursor: pointer;
}

.tabs-container {
  background: #f0f2f5;
  padding: 16px;

  .el-tabs {
    background: #fff;

    ::v-deep {
      .inline-form-item {
        float: left;
        width: 50%;

        .el-input,
        .el-textarea {
          width: 90%;
        }
      }

      .block-form {
        .el-input {
          width: 300px;
        }
      }

      .el-textarea {
        width: 300px;
      }

      .big-input .el-input {
        width: 500px !important;
      }
    }
  }
}
</style>
