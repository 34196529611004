<template>
  <el-cascader
    v-model="addresslist"
    class="address-select"
    ref="cascader"
    :props="props"
    v-bind="$attrs"
    @change="handleChange">
  </el-cascader>
</template>

<script>
import { getProvinceList, getCityList, getTownList } from '@/api/common'

const multiPathProp = [
  ['provinceCode', 'provinceName'],
  ['cityCode', 'cityName'],
  ['townCode', 'townName'],
]
export default {
  model: {
    prop: 'address',
    event: 'change'
  },
  props: {
    address: {
      type: Array,
      default: () => []
    },
    defaultAddress: String,
    province: String,
    city: String,
    town: String,
    endLevel: {
      type: Number,
      default: 3, // 1=province, 2=city, 3=town 叶节点等级
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  data(vm) {
    return {
      addresslist: [],
      props: {
        lazy: true,
        multiple: vm.multiple,
        lazyLoad(node, resolve) {
          const { level } = node;
          let api = level == 0 ?
            getProvinceList : (level == 1 ? getCityList : level == 2 ? getTownList : null)
          let value = level == 0 ?
            'provinceNo' : (level == 1 ? 'cityNo' : 'townNo')
          let label = level == 0 ?
            'provinceName' : (level == 1 ? 'cityName' : 'townName')
          let params = level == 1 ?
            'provinceNo' : (level == 2 ? 'cityNo' : '')
          let requestParams = {};
          if (node.data && node.data.value) {
            requestParams[params] = node.data.value[0];
          }
          if (api && level < vm.endLevel) {
            api(requestParams).then(({ data }) => {
              let nodes = data.map(item => ({
                value: [item[value], item[label]],
                label: item[label],
                leaf: level === vm.endLevel - 1 || item[label] == '台湾省' || item[label] == '香港特别行政区' || item[label] == "澳门特别行政区",
              }));
              resolve(nodes);
            })
          }
        }
      },
    }
  },

  watch: {
    defaultAddress: {
      handler(val) {
        this.changeDefault(val);
      },
      immediate: true,
    }
  },

  methods: {
    handleChange(val) {
      this.$emit('change', val);
      if (this.multiple) {
        const result = []
        val.forEach((path, pathIndex) => {
          const pathRet = {}
          for (let i = 0; i < this.endLevel; i++) {
            const props = multiPathProp[i]
            props.forEach((prop, index) => {
              pathRet[prop] = path[i][index]
            })
          }
          result.push(pathRet)
        })
        this.$emit('multi-change', result)
      } else {
        const events = ['update:province', 'update:city', 'update:town'];
        for (let i = 0; i < events.length; i++) {
          let value = '';
          if (val[i]) {
            value = val[i][1];
          }
          this.$emit(events[i], value)
        }
      }
    },
    changeDefault(val) {
      if (!this.$refs.cascader) {
        this.timer = setTimeout(() => {
          this.changeDefault(val)
        }, 100);
        return;
      }
      this.timer && clearTimeout(this.timer);
      this.$refs.cascader.inputValue = val;
    }
  }
}
</script>

<style
  lang="scss"
  scoped>
.address-select {
  display: block;
  width: 450px;
}
</style>
