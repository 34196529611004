import request from '@/utils/request.js';

export function getMerchantList(data) {
  return request({
    url: '/merchant/listMerchantPage',
    method: 'post',
    data
  })
}

export function getAllMerchant(data) {
  return request({
    url: '/merchant/listAll',
    method: 'post',
    data,
  })
}

export function getAllSuppliers() {
  return request({
    url: '/nameManagement/getAllSuppliers',
    method: 'get'
  })
}

export function createMerchant(data) {
  return request({
    url: '/merchantEmployer/applyNewMerchant',
    method: 'post',
    data
  })
}

export function hardDelete(data) {
  return request({
    url: `/merchant/forceDelete/${ data }`,
    method: 'post',
  })
}

export function getMerchantInfo(params) {
  return request({
    url: '/merchantEmployer/getWorkOrderMerchant',
    method: 'get',
    params
  })
}

/* 获取用工企业信息 */
export function getEmployerInfo(data) {
  return request({
    url: '/merchant/getEmployerInfoVO',
    method: 'get',
    params: data
  })
}

// 多接口获取商户不同模块信息
export function getBaseInfo(params) {
  return request({
    url: '/merchantEmployer/getBaseInfo',
    method: 'get',
    params
  })
}

export function getCooperateInfo(params) {
  return request({
    url: '/merchantEmployer/getCooperateInfo',
    method: 'get',
    params
  })
}

export function getAccountInfo(params) {
  return request({
    url: '/merchantEmployer/getAccountInfo',
    method: 'get',
    params
  })
}

export function getMainInfo(params) {
  return request({
    url: '/merchantEmployer/getMainInfo',
    method: 'get',
    params
  })
}

export function getBusinessInfo(params) {
  return request({
    url: '/merchantEmployer/getBusinessInfo',
    method: 'get',
    params
  })
}

export function getAgreementInfo(params) {
  return request({
    url: '/merchantEmployer/getAgreementInfo',
    method: 'get',
    params
  })
}

export function getRecordInfo(data) {
  return request({
    url: '/merchantEmployer/record',
    method: 'post',
    data
  })
}

export function getQuoteInfo(params) {
  return request({
    url: '/merchantEmployer/getQuoteInfo',
    method: 'get',
    params
  })
}


// 编辑各模块信息
export function editBaseInfo(data) {
  return request({
    url: '/merchantEmployer/editBaseInfo',
    method: 'post',
    data
  })
}

export function editMainstayCooperateInfo(data) {
  return request({
    url: '/merchantEmployer/editMainstayCooperateInfo',
    method: 'post',
    data
  })
}

export function editMerchantCooperateInfo(data) {
  return request({
    url: '/merchantEmployer/editMerchantCooperateInfo',
    method: 'post',
    data
  })
}

export function editMainInfo(data) {
  return request({
    url: '/merchantEmployer/editMainInfo',
    method: 'post',
    data
  })
}

export function editBusinessInfo(data) {
  return request({
    url: '/merchantEmployer/editBusinessInfo',
    method: 'post',
    data
  })
}

export function addQuote(data) {
  return request({
    url: '/merchantFlow/addQuote',
    method: 'post',
    data,
  })
}

export function deleteQuote(data) {
  return request({
    url: '/merchantFlow/deleteQuote',
    method: 'post',
    data,
  })
}


/* 更新用工企业信息 */
// export function updateCooperate(data) {
//   return request({
//     url: '/merchantEmployer/updateCooperate',
//     method: 'post',
//     data
//   })
// }

/* 更新主体认证信息 */
// export function updateMerchantMain(data) {
//   return request({
//     url: '/merchantEmployer/updateMain',
//     method: 'post',
//     data
//   })
// }

/* 更新银行卡信息 */
// export function updateBankCardInfo(data) {
//   return request({
//     url: '/merchantEmployer/updateBankAccount',
//     method: 'post',
//     data
//   })
// }

export function createSupplier(data) {
  return request({
    url: '/merchantMainstay/create',
    method: 'post',
    data
  })
}

// 创建供应商签约账号
export function createSignInfo(data) {
  return request({
    url: '/merchantMainstay/createSignInfo',
    method: 'post',
    data,
  })
}

// 更新代征主体
// export function updateSupplier(data) {
//   return request({
//     url: '/merchantEmployer/updateMainstayCooperate',
//     method: 'post',
//     data,
//   })
// }

// 获取代征主体列表
export function getMainstayList() {
  return request({
    url: '/merchant/listActiveMainstay',
    method: 'get',
  })
}

// 获取签署人
export function getActiveMchList() {
  return request({
    url: '/merchant/listAllActiveMch',
    method: 'get',
  })
}

export function merchantAuth(data) {
  return request({
    url: '/merchantEmployer/mainAuth',
    method: 'post',
    data
  })
}

// 提交导出用工企业信息请求
export function exportMerchantInfo(data) {
  return request({
    url: '/merchantEmployer/exportEmployerInfo',
    method: 'post',
    data
  })
}

// 查询所有用工企业后台功能
export function listAllEmployerFunction() {
  return request({
    url: '/employerFunction/listAll',
    method: 'get'
  });
}

// 新增用工企业后台功能
export function createEmployerFunction(data) {
  return request({
    url: '/employerFunction/add',
    method: 'post',
    data
  })
}

// 修改用工企业后台功能
export function editEmployerFunction(data) {
  return request({
    url: '/employerFunction/edit',
    method: 'post',
    data
  })
}

// 删除用工企业后台功能
export function deleteEmployerFunction(data) {
  return request({
    url: '/employerFunction/delete',
    method: 'get',
    params: data
  })
}

// 分页查询操作员
export function listEmployerOperatorPage(data) {
  return request({
    url: '/employerOperator/listPage',
    method: 'post',
    data
  })
}

// 分页查询员工
export function listEmployerStaffPage(data) {
  return request({
    url: '/employerStaff/listPage',
    method: 'post',
    data
  })
}

// 更换负责人信息
export function changeLeader(data) {
  return request({
    url: '/merchantEmployer/changeLeader',
    method: 'post',
    data
  })
}

// 更新审批流程内(被驳回)的主体认证信息
export function mainAuthApprovalEdit(data) {
  return request({
    url: '/merchantEmployer/mainAuthApprovalEdit',
    method: 'post',
    data
  })
}


// 分页查询操作员(供应商)
export function listSupplierOperatorPage(data) {
  return request({
    url: '/supplierOperator/listPage',
    method: 'post',
    data
  })
}

// 分页查询员工（供应商）
export function listSupplierStaffPage(data) {
  return request({
    url: '/supplierStaff/listPage',
    method: 'post',
    data
  })
}


// 更新商户开票信息
export function updateInvoiceInfo(data) {
  return request({
    url: '/merchantEmployer/updateInvoiceInfo',
    method: 'post',
    data,
  })
}

// 获取商户开票类目
export function getInvoiceList(params) {
  return request({
    url: '/merchant/getInvoiceList',
    method: 'get',
    params
  })
}

// 更新商户整体信息
// export function updateAllMerchant(data) {
//   return request({
//     url: '/merchantEmployer/updateAllMerchantMessage',
//     method: 'post',
//     data
//   })
// }

// 根据商户编号查询代征主体列表
export function getMainstayListByMchNo(data) {
  return request({
    url: '/employerMainstayRelation/getRelationListByEmployerNo',
    method: 'post',
    data,
  })
}

// 获取商户预置角色
export function getPresetRole(data) {
  return request({
    url: '/pmsEmployerRole/listPage',
    method: 'post',
    data
  })
}

// 删除商户预置角色
export function deletePresetRole(data) {
  return request({
    url: '/pmsEmployerRole/delete',
    method: 'post',
    data,
  })
}

// 编辑商户预置角色
export function editPresetRole(data) {
  return request({
    url: '/pmsEmployerRole/edit',
    method: 'post',
    data
  })
}

// 创建商户预置角色
export function addPresetRole(data) {
  return request({
    url: '/pmsEmployerRole/add',
    method: 'post',
    data
  })
}

// 查询商户角色权限
export function getRoleFunction(params) {
  return request({
    url: '/pmsEmployerRole/listRoleFunction',
    method: 'get',
    params
  })
}

// 分配商户角色功能
export function assignPermission(data) {
  return request({
    url: '/pmsEmployerRole/assignFunction',
    method: 'post',
    data,
  })
}

// 功能导入
export function importFunc(data) {
  return request({
    url: '/employerFunction/import',
    method: 'post',
    data,
  })
}

// 功能导出
export function exportFunc() {
  return request({
    url: '/employerFunction/export',
    method: 'get'
  })
}

// 查询用工企业余额
export function getAmountInMainstay(data) {
  return request({
    url: '/employerAccountInfo/getAmountByEmployerNoAnyMainstayNo',
    method: 'post',
    data
  })
}

// 导出用工企业列表
export function exportMchList(data) {
  return request({
    url: '/merchantEmployer/exportEmployerList',
    method: 'post',
    data,
  })
}

// 报价单查询
export function getQuote(params) {
  return request({
    url: '/merchant/listQuoteByEmployerNo',
    method: 'get',
    params
  })
}

export function listActiveMerchant() {
  return request({
    url: '/merchant/listActiveMerchant',
    method: 'get'
  })
}

// 用工企业品牌
export function listBranchMerchant() {
  return request({
    url: '/merchant/listBranchMerchant',
    method: 'get'
  })
}

// 获取商户所有岗位信息
export function listPosition(params) {
  return request({
    url: '/merchant/listPosition',
    method: 'get',
    params
  })
}

export function activateMch({ mchNo }) {
  return request({
    url: `/merchant/forceActive/${ mchNo }`,
    method: 'post',
  })
}

// 查询余额
export function getAmountWithMerchantInfo(data) {
  return request({
    url: '/employerAccountInfo/getAmountWithMerchantInfo',
    method: 'post',
    data
  })
}

export function exportAmountWithMerchantInfo(data) {
  return request({
    url: '/employerAccountInfo/exportAmountWithMerchantInfo',
    method: 'post',
    data
  })
}

// 获取商户邮寄信息
export function getExpressInfo(params) {
  return request({
    url: '/merchantEmployer/getExpressInfo',
    method: 'get',
    params
  })
}

// 商户收支明细
export function listAcctDetail(data) {
  return request({
    url: '/employerAccountInfo/listAcctDetail',
    data,
    method: 'post'
  })
}

export function exportCkAcctDetail(data) {
  return request({
    url: '/employerAccountInfo/exportCkAcctDetail',
    data,
    method: 'post'
  })
}

export function generateCreateURL(data) {
  return request({
    url: '/merchant/generateCreateURL',
    data,
    method: 'post'
  })
}

// 获取行业类别和特殊行业资质
export function getAlipayMcc() {
  return request({
    url: '/alipayMcc/listAll',
    method: 'get'
  })
}


// 获取岗位信息
export function getAllPosition() {
  return request({
    url: '/workCategory/listAll',
    method: 'get'
  })
}

// 获取岗位信息
export function getEmployerPosition(params) {
  return request({
    url: '/merchant/listPosition',
    method: 'get',
    params
  })
}

export function ListRecommendCustoms(data) {
  return request({
    url: '/merchant/ListRecommendCustoms',
    data,
    method: 'post'
  })
}

export function updateRecommendCustomsStatus(data) {
  return request({
    url: '/merchant/updateRecommendCustomsStatus',
    data,
    method: 'post'
  })
}
